<template>
  <el-popover
    placement="bottom"
    style="width: 50vw"
    trigger="manual"
    v-model="visible"
  >
    <el-table
      class="undertake-company-table"
      max-height="400"
      border
      :data="listData"
      :span-method="spanMethod"
      @row-click="handleClick"
    >
      <el-table-column width="60" label="序号" prop="index"></el-table-column>
      <el-table-column width="140" label="三包厂家">
        <template #default="{ row }">
          <div>{{ row.undertakeName }}</div>
          <div>{{ row.undertakeNum }}</div>
          <!-- 编码:  -->
        </template>
      </el-table-column>
      <el-table-column width="120" label="开票主体">
        <template #default="{ row }">
          {{ row.invoiceName }}
        </template>
      </el-table-column>
      <!-- <el-table-column width="100" label="定损星级">
        <template #default="{ row }">
          {{ row.star }}
        </template>
      </el-table-column>
      <el-table-column width="140" label="本月产值保费比">
        <template #default="{ row }">
          {{ row.xxx }}
        </template>
      </el-table-column> -->
      <template slot="append">
        <div v-if="hasMore" class="load-more-btn" @click="getData(false, true)">
          加载更多数据
        </div>
      </template>
    </el-table>
    <el-input
      ref="keyWordInput"
      slot="reference"
      v-model="keyWord"
      size="small"
      placeholder="搜索厂家名称、编号"
      @input="getData(true)"
      @focus="visible = true"
      clearable
      @clear="handleClear"
    ></el-input>
  </el-popover>
</template>

<script>
import {
  getPakeManuList,
  searchUndertakeCompany,
  searchUndertakeCompanyWithInvoice,
} from '@/api/customer/sysmanent';
import lodash from 'lodash';
import { mapState } from 'vuex';
export default {
  name: 'selectUndertakeCompanyPopover',
  props: {
    searchKeyWord: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      //可见性
      visible: false,

      // 列表数据
      listData: [],

      // 搜索关键字
      keyWord: '',

      // 分页所在页数
      pageCurrent: 0,

      // 是否未加载完所有数据
      hasMore: false,

      // 是否正在加载
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.companyId,
    }),
    pagination() {
      return { size: 5, current: this.pageCurrent };
    },
  },
  created() {
    this.keyWord = this.searchKeyWord || '';
    this.getData();
  },
  mounted() {
    document.addEventListener('click', this.handleDocumentClick);
  },
  destroyed() {
    document?.removeEventListener('click', this.handleDocumentClick);
  },
  methods: {
    /**
     * 加载数据
     */
    getData(reset, loadMore) {
      this.isLoading = true;
      this.debounceGetData(reset, loadMore);
    },
    debounceGetData: lodash.debounce(function (reset, loadMore) {
      // 重置分页，清空已有数据
      if (reset) {
        this.pageCurrent = 1;
        // this.hasMore = true;
      } else {
        this.pageCurrent++;
      }
      searchUndertakeCompanyWithInvoice({
        companyId: this.companyId,
        undertakeName: this.keyWord,
        ...this.pagination,
      })
        .then((res) => {
          this.hasMore = this.pageCurrent < res.pages;
          if (reset) this.listData = [];
          this.handleList(res.records || []);
        })
        .finally(() => {
          this.isLoading = false;
          if (loadMore) this.$refs.keyWordInput.focus();
        });
    }, 5e2),
    handleList(data) {
      var arr = [];
      let rowIndex;
      if (this.listData?.length) {
        rowIndex = Math.max(...this.listData.map((el) => el.index || 0));
      } else {
        rowIndex = 0;
      }
      var cloneData = this.$lodash.cloneDeep(data);
      cloneData.forEach((item) => {
        if (item.invoices?.length) {
          item.rowspan = item.invoices.length;
          item.invoices.forEach((el, index) => {
            if (index === 0) {
              el.isParent = true;
              el.index = ++rowIndex;
              el.rowspan = item.invoices.length;
            } else {
              el.isChild = true;
            }
            arr.push(
              Object.assign(this.$lodash.cloneDeep(el), {
                id: item.id,
                undertakeName: item.undertakeName,
                undertakeNum: item.undertakeNum,
                invoiceName: el.invoiceCompanyName,
                undertakeCompanyId: el.id,
              })
            );
          });
        } else {
          item.index = ++rowIndex;
          arr.push(item);
        }
      });
      this.listData.push(...arr);
    },
    /**
     * 表格合并方法
     */
    spanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 1 || columnIndex == 0) {
        if (row.isParent) {
          return {
            rowspan: row.rowspan,
            colspan: 1,
          };
        } else if (row.isChild) {
          return [0, 0];
        } else {
          return [1, 1];
        }
      }
    },
    /**
     * 选中行
     */
    handleClick(val) {
      this.keyWord = val.undertakeName;
      this.visible = false;
      this.$emit('select', val);
    },
    /**
     * 清空
     */
    handleClear() {
      this.visible = false;
      this.$emit('clear');
    },
    handleDocumentClick(e) {
      if (!this.$el || this.$el.contains(e.target) || !e.target.className) {
        return;
      }
      this.visible = false;
    },
  },
};
</script>

<style>
.undertake-company-table .el-table__body-wrapper {
  overflow: auto;
}
</style>
<style lang="scss" scoped>
.load-more-btn {
  text-align: center;
  line-height: 50px;
  color: #3eb1e0;
  cursor: pointer;
  &:hover {
    color: #14a3db;
  }
}
</style>
