<template>
  <div class="bg">
    <div class="box filter">
      <el-form>
        <el-form-item label="统计周期">
          <el-date-picker
            v-model="form.statisticsPeriod"
            type="month"
            placeholder="请选择统计周期"
            :clearable="false"
            value-format="yyyy-MM"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="业务类型">
          <el-select
            v-model="form.billType"
            value-key="value"
            placeholder="请选择"
            filterable
            @change="typeChange"
          >
            <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <div class="el-form-item--small">
        <el-button size="small" icon="el-icon-camera-solid" @click="getScreen"
          >截图</el-button
        >

        <el-button
          class="btn_search"
          type="primary"
          size="small"
          @click="doSearch"
          >查询</el-button
        >
        <!-- <el-button class="btn_search" size="small" @click="resetForm"
          >置空</el-button
        > -->
      </div>
    </div>

    <div ref="printDom">
      <div class="box chart">
        <div class="left">
          <div v-if="storeList.length" class="first">
            <div class="company-name">
              {{ singleTypeRankList[0].companyName }}
            </div>
            <div class="value">
              毛利率：{{ singleTypeRankList[0].grossProfitRate }}%
            </div>
          </div>
          <div v-if="storeList.length" class="last">
            <div class="company-name">
              {{ singleTypeRankList.at(-1).companyName }}
            </div>
            <div class="value">
              毛利率：{{ singleTypeRankList.at(-1).grossProfitRate }}%
            </div>
          </div>
        </div>
        <div class="right">
          <div class="title">月度毛利率</div>
          <div ref="chart" style="width: 100%; height: 200px"></div>
        </div>
      </div>

      <div class="box list">
        <div class="top">
          <div class="title">门店毛利明细</div>
          <div v-show="!showAll" class="legend">
            <div class="icon"></div>
            <span>毛利率</span>
          </div>
        </div>
        <div class="content">
          <el-table
            v-if="showAll"
            border
            :data="storeList"
            show-summary
            :summary-method="getSummaries"
          >
            <el-table-column label="排名" width="70" align="center">
              <template #default="{ row }">
                <img
                  v-if="row.totalRanking === 1"
                  class="table-img"
                  src="@/assets/img/icon_win.png"
                />
                <img
                  v-else-if="row.totalRanking === storeList.length"
                  class="table-img"
                  src="@/assets/img/icon_lose.png"
                />
                <span v-else>{{ row.totalRanking }}</span>
              </template>
            </el-table-column>
            <el-table-column label="门店" prop="companyName" min-width="140">
              <template #default="{ row }">
                <span class="blue pointer" @click="navGrossProfitDetail(row)">{{
                  row.companyName
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="综合毛利"
              prop="totalGrossProfitRate"
              min-width="100"
              :formatter="addPercent"
            >
            </el-table-column>
            <el-table-column label="自费" align="center">
              <el-table-column
                label="毛利率"
                prop="selfPayGrossProfitRate"
                min-width="80"
                :formatter="addPercent"
              ></el-table-column>
              <el-table-column
                label="排名"
                prop="selfPayRanking"
                min-width="80"
              >
                <template #default="{ row }">
                  <img
                    v-if="row.selfPayRanking === 1"
                    class="table-img"
                    src="@/assets/img/icon_win.png"
                  />
                  <img
                    v-else-if="row.selfPayRanking === storeList.length"
                    class="table-img"
                    src="@/assets/img/icon_lose.png"
                  />
                  <span v-else>{{ row.selfPayRanking }}</span>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="包干" align="center">
              <el-table-column
                label="毛利率"
                prop="contractForGrossProfitRate"
                min-width="80"
                :formatter="addPercent"
              ></el-table-column>
              <el-table-column
                label="排名"
                prop="contractForRanking"
                min-width="80"
              >
                <template #default="{ row }">
                  <img
                    v-if="row.contractForRanking === 1"
                    class="table-img"
                    src="@/assets/img/icon_win.png"
                  />
                  <img
                    v-else-if="row.contractForRanking === storeList.length"
                    class="table-img"
                    src="@/assets/img/icon_lose.png"
                  />
                  <span v-else>{{ row.contractForRanking }}</span>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="理赔" align="center">
              <el-table-column
                label="毛利率"
                prop="claimSettlementGrossProfitRate"
                min-width="80"
                :formatter="addPercent"
              ></el-table-column>
              <el-table-column
                label="排名"
                prop="claimSettlementRanking"
                min-width="80"
              >
                <template #default="{ row }">
                  <img
                    v-if="row.claimSettlementRanking === 1"
                    class="table-img"
                    src="@/assets/img/icon_win.png"
                  />
                  <img
                    v-else-if="row.claimSettlementRanking === storeList.length"
                    class="table-img"
                    src="@/assets/img/icon_lose.png"
                  />
                  <span v-else>{{ row.claimSettlementRanking }}</span>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="索赔" align="center">
              <el-table-column
                label="毛利率"
                prop="claimForCompensationGrossProfitRate"
                min-width="80"
                :formatter="addPercent"
              ></el-table-column>
              <el-table-column
                label="排名"
                prop="claimForCompensationRanking"
                min-width="80"
              >
                <template #default="{ row }">
                  <img
                    v-if="row.claimForCompensationRanking === 1"
                    class="table-img"
                    src="@/assets/img/icon_win.png"
                  />
                  <img
                    v-else-if="
                      row.claimForCompensationRanking === storeList.length
                    "
                    class="table-img"
                    src="@/assets/img/icon_lose.png"
                  />
                  <span v-else>{{ row.claimForCompensationRanking }}</span>
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>
          <div v-else class="list">
            <div
              v-for="(item, index) in singleTypeRankList"
              :key="index"
              class="item"
            >
              <div class="index">
                <img
                  v-if="index === 0"
                  class="table-img"
                  src="@/assets/img/icon_win.png"
                />
                <img
                  v-else-if="index === singleTypeRankList.length - 1"
                  class="table-img"
                  src="@/assets/img/icon_lose.png"
                />
                <span v-else>{{ index + 1 }}</span>
              </div>
              <div class="company-name text-ellipsis">
                {{ item.companyName }}
              </div>
              <div class="progress-box">
                <div
                  class="progress"
                  :style="{
                    width: Math.max(item.grossProfitRate || 0, 0) + '%',
                  }"
                ></div>
              </div>
              <div class="value">{{ item.grossProfitRate || 0 }}%</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import html2canvas from 'html2canvas';

import {
  grossProfitRankingList,
  monthlyGrossMarginTrend,
  monthlyConsolidatedGrossMargin,
} from '@/api/dataBoard';

import { hasAuth } from '@/utils/permissions';

export default {
  name: 'grossMarginRankList',
  data() {
    return {
      // 表单
      form: {
        billType: -1,
      },

      // 业务类型
      typeList: [],

      // 门店列表
      storeList: [],

      // 展示全部业务类型
      showAll: true,

      // 查看单种业务
      singleTypeRankList: [],

      // 趋势数据
      trendData: [],

      // 合计数据
      totalData: {},

      serviceBrandChart: null,
    };
  },
  computed: {
    chartOption() {
      return {
        grid: {
          top: 20,
          left: 50,
          right: 20,
          bottom: 30,
          // containLabel: true,
        },
        xAxis: {
          type: 'category',
          // boundaryGap: false,
          axisLabel: {
            color: 'rgba(36, 37, 38, 1)',
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(0, 0, 0, .25)',
            },
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(0, 0, 0, .25)',
            },
          },
          data: [
            '1月',
            '2月',
            '3月',
            '4月',
            '5月',
            '6月',
            '7月',
            '8月',
            '9月',
            '10月',
            '11月',
            '12月',
          ],
        },
        yAxis: {
          type: 'value',
          nameTextStyle: {
            fontSize: 12,
            color: '#84878C',
          },
          axisLabel: {
            formatter: '{value}%',
            color: 'rgba(132, 135, 140, 1)',
          },
        },
        series: [
          {
            data: this.trendData,
            type: 'line',
            label: {
              show: true,
              position: 'top',
              formatter: '{c}%',
            },
            color: ' #5B8FF9',
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(91, 143, 249, 0.4)', // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(91, 143, 249, 0)', // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          },
        ],
      };
    },
  },
  created() {
    this.$set(this.form, 'statisticsPeriod', new Date().Format('yyyy-MM'));
  },
  activated() {
    this.onResize();
  },
  mounted() {
    // 校验权限
    if (hasAuth(569)) {
      this.typeList.push({ label: '综合毛利率', value: '' });
    }
    if (hasAuth(570)) {
      this.typeList.push({ label: '自费', value: 0 });
    }
    if (hasAuth(571)) {
      this.typeList.push({ label: '包干', value: 1 });
    }
    if (hasAuth(572)) {
      this.typeList.push({ label: '理赔', value: 2 });
    }
    if (hasAuth(573)) {
      this.typeList.push({ label: '索赔', value: 3 });
    }
    // 默认选中第一项
    if (this.typeList.length) {
      this.$set(this.form, 'billType', this.typeList[0].value);
    }

    this.doSearch();
  },
  methods: {
    /**
     * 业务类型变更
     */
    typeChange(val) {},
    /**
     * 查询
     */
    doSearch() {
      var year = new Date(this.form.statisticsPeriod).getFullYear();
      var month = new Date(this.form.statisticsPeriod).getMonth();
      var dateRange = {
        startTime: new Date(this.form.statisticsPeriod).Format('yyyy-MM-dd'),
        endTime: new Date(year, month + 1, 0).Format('yyyy-MM-dd'),
      };
      var temp = {
        statisticsPeriod: new Date(this.form.statisticsPeriod).Format(
          'yyyy/MM'
        ),
      };
      var body = Object.assign({}, this.form, temp, dateRange);
      grossProfitRankingList(body).then((res) => {
        this.storeList = res;
        this.handleSingleTypeRankList();
        this.showAll = ![0, 1, 2, 3].includes(this.form.billType);
      });
      monthlyGrossMarginTrend({
        statisticsYear: year,
        billType: this.form.billType,
      }).then((data) => {
        console.log(data);
        this.trendData = data.map((item) => item.grossProfitRate);
        this.drawChart();
      });
      if (this.form.billType === '') {
        monthlyConsolidatedGrossMargin(
          Object.assign(
            {
              statisticsPeriod: new Date(this.form.statisticsPeriod).Format(
                'yyyy/MM'
              ),
            },
            dateRange
          )
        ).then((resp) => {
          this.totalData = resp || {};
        });
      }
    },
    /**
     * 处理单种业务类型显示
     */
    handleSingleTypeRankList() {
      var rankKey, rateKey;
      switch (this.form.billType) {
        case 0:
          rankKey = 'selfPayRanking';
          rateKey = 'selfPayGrossProfitRate';
          break;
        case 1:
          rankKey = 'contractForRanking';
          rateKey = 'contractForGrossProfitRate';
          break;
        case 2:
          rankKey = 'claimSettlementRanking';
          rateKey = 'claimSettlementGrossProfitRate';
          break;
        case 3:
          rankKey = 'claimForCompensationRanking';
          rateKey = 'claimForCompensationGrossProfitRate';
          break;
        default:
          rankKey = 'totalRanking';
          rateKey = 'totalGrossProfitRate';
      }
      var list = this.storeList
        .map((el) => ({
          companyName: el.companyName,
          rank: el[rankKey],
          grossProfitRate: el[rateKey],
        }))
        .sort((a, b) => a.rank - b.rank);
      this.singleTypeRankList = list;
    },

    /**
     * 绘制图表
     */
    drawChart() {
      this.serviceBrandChart = echarts.init(this.$refs.chart);
      this.serviceBrandChart.setOption(this.chartOption);
      this.serviceBrandChart.resize();
      window.addEventListener('resize', this.onResize);
    },
    /**
     * 窗口大小变化重绘图表
     */
    onResize() {
      this.serviceBrandChart?.resize();
    },
    /**
     * 表格合计行
     */
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 1) {
          sums[index] = '综合';
          return;
        }
        if (index > 1) {
          switch (index) {
            case 2:
              sums[index] = this.totalData.totalGrossProfitRate + '%';
              break;
            case 3:
              sums[index] = this.totalData.selfPayGrossProfitRate + '%';
              break;
            case 4:
              sums[index] = '-';
              break;
            case 5:
              sums[index] = this.totalData.contractForGrossProfitRate + '%';
              break;
            case 6:
              sums[index] = '-';
              break;
            case 7:
              sums[index] = this.totalData.claimSettlementGrossProfitRate + '%';
              break;
            case 8:
              sums[index] = '-';
              break;
            case 9:
              sums[index] =
                this.totalData.claimForCompensationGrossProfitRate + '%';
              break;
            case 10:
              sums[index] = '-';
              break;
          }
        }
      });
      return sums;
    },
    /**
     * 表格内容格式化
     */
    addPercent(row, column, cellValue, index) {
      return cellValue + '%';
    },

    /**
     * 跳转
     */
    navGrossProfitDetail(row) {
      console.log(row);
      this.$router.push({
        name: 'marginDetail',
        params: {
          companyName: row.companyName,
          companyId: row.companyId,
          statisticsPeriod: this.form.statisticsPeriod,
        },
      });
    },
    /**
     * 截图
     */
    getScreen() {
      console.log(this.$refs);
      // 使用 html2canvas 进行截图
      html2canvas(this.$refs.printDom, {
        scale: 2,
      }).then((canvas) => {
        let url = canvas.toDataURL('image/png');
        let a = document.createElement('a');
        a.href = url;
        a.download = '门店毛利排行榜.png';
        a.click();
        URL.revokeObjectURL(url); // 释放 URL 对象所占用的内存
      });
    },
  },
  beforeDestroy() {
    window?.removeEventListener('resize', this.onResize);
  },
};
</script>

<style lang="scss" scoped>
.bg {
  width: 100%;
  // height: 100%;
  background: $gray;
  display: flex;
  flex-direction: column;
  .box {
    // width: 100%;
    background: #fff;
    border-radius: 4px;
    + .box {
      margin-top: 16px;
    }
  }

  .filter {
    display: flex;
    justify-content: space-between;
    padding: 16px 16px 24px 16px;
    form {
      display: flex;
      .el-form-item {
        display: flex;
        margin-bottom: 0;
        + .el-form-item {
          margin-left: 32px;
        }
      }
    }
  }

  .chart {
    height: 232px;
    display: flex;
    padding: 16px;
    box-sizing: border-box;

    .left {
      width: 245px;

      .first,
      .last {
        position: relative;
        height: 91px;
        color: #fff;
        font-family: YouSheBiaoTiHei;
        .company-name {
          position: absolute;
          top: 10px;
          left: 10px;
          font-size: 18px;
          line-height: 23px;
          letter-spacing: 1px;
        }
        .value {
          margin-top: 4px;
          position: absolute;
          top: 38px;
          left: 10px;
          font-size: 14px;
        }
      }
      .first {
        background: url(../../assets/img/img_icon_win.png);
      }
      .last {
        margin-top: 19px;
        background: url(../../assets/img/img_icon_lose.png);
      }
    }

    .right {
      margin-left: 32px;
      flex: 1;
      height: 100%;
      .title {
        line-height: 16px;
        color: #242526;
        font-weight: 500;
      }
    }
  }

  .list {
    // flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 600px;
    overflow: auto;
    .top {
      height: 52px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px;
      border-bottom: 1px solid rgba(220, 223, 230, 1);
      .title {
        color: #242526;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
      }
      .legend {
        display: flex;
        align-items: center;
        .icon {
          width: 17px;
          height: 9px;
          background: #1e86eb;
          border-radius: 0px 2px 2px 0px;
        }
        span {
          margin-left: 5px;
          font-size: 12px;
          font-weight: 400;
          color: #84878c;
        }
      }
    }
    .content {
      // flex: 1;
      padding: 24px 16px 16px 16px;
      overflow: hidden;
      .el-table {
        // min-height: 500px;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: auto;
        color: #242526;
        ::v-deep .el-table__header-wrapper {
          flex-shrink: 0;
        }
        ::v-deep .el-table__body-wrapper {
          flex: 1;
          overflow: auto;
        }
        ::v-deep .el-table__row {
          height: 54px;
        }
      }
      .list {
        .item {
          height: 40px;
          display: flex;
          align-items: center;
          color: #000;

          .index {
            width: 60px;
            text-align: center;
            font-size: 16px;
          }
          .company-name {
            width: 200px;

            font-size: 14px;
            font-weight: 400;
          }
          .progress-box {
            flex: 1;
            .progress {
              height: 10px;
              background: #1e96eb;
              border-radius: 0px 3px 3px 0px;
            }
          }
          .value {
            width: 50px;
            margin-left: 5px;
            text-align: right;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
    }
  }
  .table-img {
    width: 38px;
  }
}
</style>
