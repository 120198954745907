var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { ref: "baseContainer", attrs: { paddingTop: "" } },
    [
      [
        _c(
          "div",
          [
            _c(
              "el-form",
              {
                staticClass: "filter-form",
                attrs: {
                  model: _vm.form,
                  "label-width": "100px",
                  size: "small",
                },
              },
              [
                _c("div", { staticClass: "flex-x-between" }, [
                  _c(
                    "div",
                    { staticClass: "flex-warp flex-shrink0" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "车牌号" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入", clearable: "" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.doSearch.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.form.carNum,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "carNum", $$v)
                              },
                              expression: "form.carNum",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c("template", { slot: "label" }, [
                            _c(
                              "div",
                              [
                                _vm._v(" 操作门店 "),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "light",
                                      content:
                                        "接车单的操作门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)",
                                      placement: "bottom-start",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "iconfont grow-icon_doubt",
                                      staticStyle: { color: "#9a9a9a" },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("operateCompanyCascader", {
                            model: {
                              value: _vm.form.operateCompanyId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "operateCompanyId", $$v)
                              },
                              expression: "form.operateCompanyId",
                            },
                          }),
                        ],
                        2
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "进店日期" } },
                        [
                          _c("el-date-picker", {
                            staticClass: "w100",
                            attrs: {
                              type: "daterange",
                              "unlink-panels": "",
                              "range-separator": "至",
                              "start-placeholder": "起始时间",
                              "end-placeholder": "截止时间",
                              "value-format": "yyyy-MM-dd",
                            },
                            model: {
                              value: _vm.form.enterTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "enterTime", $$v)
                              },
                              expression: "form.enterTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "flex" }, [
                    _c("div", { staticClass: "el-form-item--small" }, [
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "btn_search",
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.doSearch },
                            },
                            [_vm._v("查询")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "btn_search",
                              attrs: { size: "small" },
                              on: { click: _vm.empty },
                            },
                            [_vm._v("置空")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "flex-warp" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "业务来源" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              filterable: "",
                              clearable: "",
                              remote: "",
                              placeholder: "搜索业务来源",
                              "remote-method": _vm.searchBillSource,
                              loading: _vm.searchBillSourceLoading,
                            },
                            on: {
                              change: _vm.selectBillSource,
                              clear: _vm.deleteBillSource,
                            },
                            model: {
                              value: _vm.form.billSourceTypeId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "billSourceTypeId", $$v)
                              },
                              expression: "form.billSourceTypeId",
                            },
                          },
                          _vm._l(_vm.billSourceList, function (item) {
                            return _c("el-option", {
                              key: item.billSourceTypeId,
                              attrs: {
                                label: item.name,
                                value: item.billSourceTypeId,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "接车单号" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入", clearable: "" },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.doSearch.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.form.billCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "billCode", $$v)
                            },
                            expression: "form.billCode",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "车单状态" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { clearable: "", placeholder: "请选择" },
                            model: {
                              value: _vm.form.billState,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "billState", $$v)
                              },
                              expression: "form.billState",
                            },
                          },
                          _vm._l(_vm.carBillState, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "施工时间" } },
                      [
                        _c("el-date-picker", {
                          staticClass: "w100",
                          attrs: {
                            type: "daterange",
                            "unlink-panels": "",
                            "range-separator": "至",
                            "start-placeholder": "起始时间",
                            "end-placeholder": "截止时间",
                            "value-format": "yyyy-MM-dd",
                          },
                          model: {
                            value: _vm.form.constructTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "constructTime", $$v)
                            },
                            expression: "form.constructTime",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "完工时间" } },
                      [
                        _c("el-date-picker", {
                          staticClass: "w100",
                          attrs: {
                            type: "daterange",
                            "unlink-panels": "",
                            "range-separator": "至",
                            "start-placeholder": "起始时间",
                            "end-placeholder": "截止时间",
                            "value-format": "yyyy-MM-dd",
                          },
                          model: {
                            value: _vm.form.completeTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "completeTime", $$v)
                            },
                            expression: "form.completeTime",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ],
      [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "justify-content": "space-between",
            },
          },
          [
            _c("div", { staticClass: "custom-tabs" }, [
              _c(
                "div",
                {
                  class: {
                    "custom-tab-item": true,
                    active: _vm.billType === "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.changeBillType("")
                    },
                  },
                },
                [_vm._v(" 全部 ")]
              ),
              _c(
                "div",
                {
                  class: {
                    "custom-tab-item": true,
                    active: _vm.billType === 0,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.changeBillType(0)
                    },
                  },
                },
                [_vm._v(" 自费 ")]
              ),
              _c(
                "div",
                {
                  class: {
                    "custom-tab-item": true,
                    active: _vm.billType === 1,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.changeBillType(1)
                    },
                  },
                },
                [_vm._v(" 包干 ")]
              ),
              _c(
                "div",
                {
                  class: {
                    "custom-tab-item": true,
                    active: _vm.billType === 2,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.changeBillType(2)
                    },
                  },
                },
                [_vm._v(" 理赔 ")]
              ),
              _c(
                "div",
                {
                  class: {
                    "custom-tab-item": true,
                    active: _vm.billType === 3,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.changeBillType(3)
                    },
                  },
                },
                [_vm._v(" 索赔 ")]
              ),
            ]),
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "auth",
                    rawName: "v-auth",
                    value: 610,
                    expression: "610",
                  },
                ],
                staticClass: "blue",
                attrs: { size: "small", type: "text" },
                on: {
                  click: function ($event) {
                    _vm.showExportDialog = true
                  },
                },
              },
              [
                _vm._v("导出列表"),
                _c("i", {
                  staticClass: "iconfont grow-icon_daochu el-icon--right",
                }),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "flex h100" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "fixedScrollBar",
                    rawName: "v-fixedScrollBar",
                    value: _vm.$refs.baseContainer,
                    expression: "$refs.baseContainer",
                  },
                ],
                staticClass: "custom-table",
                attrs: { data: _vm.tableData, border: "" },
              },
              [
                _c("el-table-column", {
                  attrs: { type: "index", label: "序号", width: "80" },
                }),
                _c("el-table-column", {
                  attrs: {
                    "min-width": "180",
                    label: "操作门店",
                    prop: "companyName",
                  },
                }),
                _c("el-table-column", {
                  attrs: { width: "100", label: "车牌号", prop: "carNum" },
                }),
                _c("el-table-column", {
                  attrs: {
                    "min-width": "170",
                    label: "接车单号/业务类型",
                    prop: "billCode",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c(
                            "span",
                            {
                              staticClass: "blue pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.goCarBillDetail(row.billId)
                                },
                              },
                            },
                            [_vm._v(_vm._s(row.billCode))]
                          ),
                          _c("div", [_vm._v(_vm._s(row.billTypeName))]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { width: "100", label: "进店时间", prop: "enterTime" },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "100",
                    label: "预交车时间",
                    prop: "planFinishTime",
                  },
                }),
                _c(
                  "el-table-column",
                  {
                    attrs: {
                      width: "100",
                      label: "交车超时",
                      prop: "finishOverTime",
                    },
                  },
                  [
                    _c(
                      "template",
                      { slot: "header" },
                      [
                        _c("span", [_vm._v("交车超时")]),
                        _c(
                          "el-tooltip",
                          { attrs: { content: "整单完工时间-预交车时间" } },
                          [
                            _c("i", {
                              staticClass: "iconfont grow-icon_doubt",
                              staticStyle: { color: "#9a9a9a" },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
                _c(
                  "el-table-column",
                  {
                    attrs: {
                      width: "100",
                      label: "施工超时",
                      prop: "constructOverTime",
                    },
                  },
                  [
                    _c(
                      "template",
                      { slot: "header" },
                      [
                        _c("span", [_vm._v("施工超时")]),
                        _c(
                          "el-tooltip",
                          { attrs: { content: "维修用时-车单服务时效之和" } },
                          [
                            _c("i", {
                              staticClass: "iconfont grow-icon_doubt",
                              staticStyle: { color: "#9a9a9a" },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
                _c("el-table-column", {
                  attrs: {
                    "min-width": "180",
                    label: "初诊与备注",
                    prop: "firstVisitRemark",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c(
                            "el-tooltip",
                            { attrs: { content: row.firstVisitRemark } },
                            [
                              _c(
                                "div",
                                { staticClass: "text-ellipsis-2-row" },
                                [
                                  _vm._v(
                                    " " + _vm._s(row.firstVisitRemark) + " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { width: "100", label: "车单状态" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c("div", [_vm._v(_vm._s(row.billState))]),
                          _c("div", { staticClass: "color626" }, [
                            _vm._v(_vm._s(row.dispatchState)),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "140",
                    label: "车单标签",
                    prop: "billLabels",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "100",
                    label: "确定故障时间",
                    prop: "diagnoseTime",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "100",
                    label: "维修技师",
                    prop: "technician",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "100",
                    label: "施工时间",
                    prop: "constructTime",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "100",
                    label: "完工时间",
                    prop: "finishTime",
                  },
                }),
                _c("el-table-column", {
                  attrs: { width: "110", label: "检车人", prop: "checker" },
                }),
                _c("el-table-column", {
                  attrs: {
                    "min-width": "140",
                    label: "服务项目",
                    prop: "billServiceName",
                  },
                }),
                _c(
                  "el-table-column",
                  {
                    attrs: {
                      width: "120",
                      label: "服务标准价",
                      prop: "servicePrice",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("toThousands")(row.servicePrice)
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  },
                  [
                    _c(
                      "template",
                      { slot: "header" },
                      [
                        _c("span", [_vm._v("服务标准价")]),
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content:
                                "当前服务项标准价，总部统一标准，见服务管理-服务标准价",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "iconfont grow-icon_doubt",
                              staticStyle: { color: "#9a9a9a" },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
                _c(
                  "el-table-column",
                  {
                    attrs: {
                      width: "120",
                      label: "配件报价",
                      prop: "goodPrice",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(_vm._f("toThousands")(row.goodPrice)) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  },
                  [
                    _c(
                      "template",
                      { slot: "header" },
                      [
                        _c("span", [_vm._v("配件报价")]),
                        _c(
                          "el-tooltip",
                          {
                            attrs: { content: "当前车单该服务项配件报价小计" },
                          },
                          [
                            _c("i", {
                              staticClass: "iconfont grow-icon_doubt",
                              staticStyle: { color: "#9a9a9a" },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
                _c("el-table-column", {
                  attrs: { width: "120", label: "配件成本", prop: "goodCost" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("toThousands")(row.goodCost)) +
                              " "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "120",
                    label: "配件出库明细",
                    prop: "goodsOutboundDetail",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _vm._v(" " + _vm._s(row.goodsOutboundDetail) + " "),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "120",
                    label: "标准毛利",
                    prop: "grossMarginPrice\t",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function () {
                        return [
                          _c("span", [_vm._v("标准毛利")]),
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: "=服务标准价+配件报价-配件成本",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "iconfont grow-icon_doubt",
                                staticStyle: { color: "#9a9a9a" },
                              }),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("toThousands")(row.grossMarginPrice)
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "100",
                    label: "服务用时",
                    prop: "serviceTime",
                  },
                }),
                _c(
                  "el-table-column",
                  {
                    attrs: {
                      width: "130",
                      label: "整单维修用时",
                      prop: "allTaketime",
                    },
                  },
                  [
                    _c(
                      "template",
                      { slot: "header" },
                      [
                        _c("span", [_vm._v("整单维修用时")]),
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content: "整单最后完工时间-整单最早开始施工时间",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "iconfont grow-icon_doubt",
                              staticStyle: { color: "#9a9a9a" },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("export-dialog", {
          attrs: {
            visibility: _vm.showExportDialog,
            total: _vm.total,
            pageOption: _vm.pageOption,
            filterForm: _vm.form,
            url: _vm.exportUrl,
          },
          on: {
            "update:visibility": function ($event) {
              _vm.showExportDialog = $event
            },
          },
        }),
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c("pagination", {
            attrs: { total: _vm.total },
            on: { pageChange: _vm.changePage },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }