var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { ref: "baseContainer", attrs: { paddingTop: "" } },
    [
      [
        _c(
          "div",
          [
            _c(
              "el-form",
              {
                staticClass: "filter-form",
                attrs: {
                  model: _vm.form,
                  "label-width": "100px",
                  size: "small",
                },
              },
              [
                _c("div", { staticClass: "flex-x-between" }, [
                  _c(
                    "div",
                    { staticClass: "flex-warp flex-shrink0" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "车牌号" } },
                        [
                          _c("el-input", {
                            attrs: { clearable: "", placeholder: "请输入" },
                            on: {
                              clear: function ($event) {
                                return _vm.carNumberInputClear()
                              },
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.doSearch.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.form.carNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "carNumber", $$v)
                              },
                              expression: "form.carNumber",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c("template", { slot: "label" }, [
                            _c(
                              "div",
                              [
                                _vm._v(" 操作门店 "),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content:
                                        "接车单的操作门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)",
                                      effect: "light",
                                      placement: "bottom-start",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "iconfont grow-icon_doubt",
                                      staticStyle: { color: "#9a9a9a" },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("operateCompanyCascader", {
                            attrs: {
                              operateCompanyUrl: _vm.form.operateCompanyUrl,
                            },
                            model: {
                              value: _vm.form.operateCompanyId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "operateCompanyId", $$v)
                              },
                              expression: "form.operateCompanyId",
                            },
                          }),
                        ],
                        2
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "接车进店日期" } },
                        [
                          _c("el-date-picker", {
                            staticClass: "w100",
                            attrs: {
                              "end-placeholder": "截止时间",
                              "range-separator": "至",
                              "start-placeholder": "起始时间",
                              type: "daterange",
                              "unlink-panels": "",
                              "value-format": "yyyy-MM-dd",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.dateRangeChange()
                              },
                            },
                            model: {
                              value: _vm.form.intoStoreTimes,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "intoStoreTimes", $$v)
                              },
                              expression: "form.intoStoreTimes",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "flex" }, [
                    _c("div", { staticClass: "el-form-item--small" }, [
                      _c(
                        "div",
                        {
                          staticClass: "el-form-item__label pointer",
                          on: {
                            click: function ($event) {
                              _vm.showMore = !_vm.showMore
                            },
                          },
                        },
                        [
                          _vm._v(" 更多筛选 "),
                          _c("i", {
                            class: {
                              primary: true,
                              "el-icon-arrow-right": !_vm.showMore,
                              "el-icon-arrow-down": _vm.showMore,
                            },
                          }),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "btn_search",
                              attrs: { size: "small", type: "primary" },
                              on: { click: _vm.doSearch },
                            },
                            [_vm._v("查询")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "btn_search",
                              attrs: { size: "small" },
                              on: { click: _vm.empty },
                            },
                            [_vm._v("置空")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "flex-warp" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "接车员" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              loading: _vm.searchAdminJobListUserLoading,
                              "remote-method": _vm.searchAdminJobListUser,
                              clearable: "",
                              filterable: "",
                              placeholder: "搜索姓名、手机号",
                              remote: "",
                            },
                            on: { change: _vm.selectAdminJobListUser },
                            model: {
                              value: _vm.form.pickUpUserId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "pickUpUserId", $$v)
                              },
                              expression: "form.pickUpUserId",
                            },
                          },
                          _vm._l(_vm.adminJobListUserList, function (item) {
                            return _c(
                              "el-option",
                              {
                                key: item.userId,
                                attrs: {
                                  label: item.realName,
                                  value: item.userId,
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      float: "left",
                                      color: "#8492a6",
                                      "font-size": "13px",
                                    },
                                  },
                                  [_vm._v(_vm._s(item.realName))]
                                ),
                                _c(
                                  "span",
                                  { staticStyle: { float: "right" } },
                                  [_vm._v(_vm._s(item.phone))]
                                ),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "接车单号" } },
                      [
                        _c("el-input", {
                          attrs: { clearable: "", placeholder: "请输入" },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.doSearch.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.form.billCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "billCode", $$v)
                            },
                            expression: "form.billCode",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "车单结账日期" } },
                      [
                        _c("el-date-picker", {
                          staticClass: "w100",
                          attrs: {
                            "end-placeholder": "截止时间",
                            "range-separator": "至",
                            "start-placeholder": "起始时间",
                            type: "daterange",
                            "unlink-panels": "",
                            "value-format": "yyyy-MM-dd",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.dateRangeChange()
                            },
                          },
                          model: {
                            value: _vm.form.checkStartTimes,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "checkStartTimes", $$v)
                            },
                            expression: "form.checkStartTimes",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "车架号" } },
                      [
                        _c("el-input", {
                          attrs: { clearable: "", placeholder: "请输入" },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.doSearch.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.form.uniqueId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "uniqueId", $$v)
                            },
                            expression: "form.uniqueId",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.showMore
                      ? [
                          _c(
                            "el-form-item",
                            { attrs: { label: "签约客户" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    loading: _vm.searchClientLoading,
                                    "remote-method": _vm.searchClient,
                                    clearable: "",
                                    filterable: "",
                                    placeholder: "请输入姓名",
                                    remote: "",
                                  },
                                  on: { change: _vm.selectClient },
                                  model: {
                                    value: _vm.form.clientId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "clientId", $$v)
                                    },
                                    expression: "form.clientId",
                                  },
                                },
                                _vm._l(_vm.customList, function (item) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: item.id,
                                      attrs: {
                                        label: item.clientName,
                                        value: item.id,
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            float: "left",
                                            color: "#8492a6",
                                            "font-size": "13px",
                                          },
                                        },
                                        [_vm._v(_vm._s(item.clientName))]
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "车型信息" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    loading: _vm.searchModelLoading,
                                    "remote-method": _vm.searchModel,
                                    clearable: "",
                                    filterable: "",
                                    placeholder: "搜索车型",
                                    remote: "",
                                  },
                                  model: {
                                    value: _vm.form.carModel,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "carModel", $$v)
                                    },
                                    expression: "form.carModel",
                                  },
                                },
                                _vm._l(_vm.carModelList, function (item) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: _vm.$lodash.get(item, "series.id"),
                                      attrs: {
                                        value: _vm.$lodash.get(
                                          item,
                                          "series.name",
                                          _vm.$lodash.get(
                                            item,
                                            "brand.name",
                                            _vm.$lodash.get(item, "logo.name")
                                          )
                                        ),
                                      },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.selectCarModel(item)
                                        },
                                      },
                                    },
                                    [
                                      _c("span", {
                                        domProps: {
                                          innerHTML: _vm._s(item.markedRedName),
                                        },
                                      }),
                                    ]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "客户提车日期" } },
                            [
                              _c("el-date-picker", {
                                staticClass: "w100",
                                attrs: {
                                  "end-placeholder": "截止时间",
                                  "range-separator": "至",
                                  "start-placeholder": "起始时间",
                                  type: "daterange",
                                  "unlink-panels": "",
                                  "value-format": "yyyy-MM-dd",
                                },
                                model: {
                                  value: _vm.form.takeStartTimes,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "takeStartTimes", $$v)
                                  },
                                  expression: "form.takeStartTimes",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "车间完工日期" } },
                            [
                              _c("el-date-picker", {
                                staticClass: "w100",
                                attrs: {
                                  "end-placeholder": "截止时间",
                                  "range-separator": "至",
                                  "start-placeholder": "起始时间",
                                  type: "daterange",
                                  "unlink-panels": "",
                                  "value-format": "yyyy-MM-dd",
                                },
                                model: {
                                  value: _vm.form.finishStartTimes,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "finishStartTimes", $$v)
                                  },
                                  expression: "form.finishStartTimes",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "业务来源" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    loading: _vm.searchBillSourceLoading,
                                    "remote-method": _vm.searchBillSource,
                                    clearable: "",
                                    filterable: "",
                                    placeholder: "搜索业务来源",
                                    remote: "",
                                  },
                                  on: {
                                    change: _vm.selectBillSource,
                                    clear: _vm.deleteBillSource,
                                  },
                                  model: {
                                    value: _vm.form.billSourceTypeId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "billSourceTypeId",
                                        $$v
                                      )
                                    },
                                    expression: "form.billSourceTypeId",
                                  },
                                },
                                _vm._l(_vm.billSourceList, function (item) {
                                  return _c("el-option", {
                                    key: item.billSourceTypeId,
                                    attrs: {
                                      label: item.name,
                                      value: item.billSourceTypeId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "施工班组" } },
                            [
                              _c("el-input", {
                                attrs: { clearable: "", placeholder: "请输入" },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.doSearch.apply(null, arguments)
                                  },
                                },
                                model: {
                                  value: _vm.form.filed1,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "filed1", $$v)
                                  },
                                  expression: "form.filed1",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "服务分类" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    filterable: "",
                                    placeholder: "请选择或搜索分类",
                                  },
                                  on: { change: _vm.selectService },
                                  model: {
                                    value: _vm.form.serviceFirstCategoryId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "serviceFirstCategoryId",
                                        $$v
                                      )
                                    },
                                    expression: "form.serviceFirstCategoryId",
                                  },
                                },
                                _vm._l(_vm.serviceClassList, function (item) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: item.categoryId,
                                      attrs: {
                                        label: item.name,
                                        value: item.categoryId,
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            float: "left",
                                            color: "#8492a6",
                                            "font-size": "13px",
                                          },
                                        },
                                        [_vm._v(_vm._s(item.name))]
                                      ),
                                      _c(
                                        "span",
                                        { staticStyle: { float: "right" } },
                                        [_vm._v(_vm._s(item.categoryId))]
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "服务项目" } },
                            [
                              _vm.billServices.length == 0
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "choice-sty service",
                                      on: {
                                        click: function ($event) {
                                          _vm.multipleSelectServicesDrawerVisibility = true
                                        },
                                      },
                                    },
                                    [
                                      _c("span", { staticClass: "pointer" }, [
                                        _vm._v("点击选择"),
                                      ]),
                                    ]
                                  )
                                : _c(
                                    "el-popover",
                                    {
                                      staticStyle: { "text-align": "center" },
                                      attrs: {
                                        placement: "bottom",
                                        trigger: "hover",
                                        width: "200",
                                      },
                                    },
                                    [
                                      _vm._l(
                                        _vm.billServices,
                                        function (item, index) {
                                          return _c("div", { key: index }, [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: { color: "#666" },
                                              },
                                              [
                                                _vm._v(
                                                  "服务名：" +
                                                    _vm._s(item.name) +
                                                    "   服务编码：" +
                                                    _vm._s(item.serviceItemCode)
                                                ),
                                              ]
                                            ),
                                          ])
                                        }
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "choice-sty service pointer",
                                          staticStyle: { position: "relative" },
                                          attrs: { slot: "reference" },
                                          slot: "reference",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  _vm.multipleSelectServicesDrawerVisibility = true
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "已选择(" +
                                                  _vm._s(
                                                    _vm.billServices.length
                                                  ) +
                                                  ")"
                                              ),
                                            ]
                                          ),
                                          _c("i", {
                                            staticClass:
                                              "iconfont grow-a-icon_tabbar_close icon-sty",
                                            staticStyle: { color: "#ff9200" },
                                            on: {
                                              click: function ($event) {
                                                _vm.billServices = []
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    2
                                  ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "商品项目" } },
                            [
                              _vm.billGoods.length == 0
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "choice-sty goods",
                                      on: {
                                        click: function ($event) {
                                          _vm.multipleSelectGoodsDrawerVisibility = true
                                        },
                                      },
                                    },
                                    [
                                      _c("span", { staticClass: "pointer" }, [
                                        _vm._v("点击选择"),
                                      ]),
                                    ]
                                  )
                                : _c(
                                    "el-popover",
                                    {
                                      staticStyle: { "text-align": "center" },
                                      attrs: {
                                        placement: "bottom",
                                        trigger: "hover",
                                        width: "200",
                                      },
                                    },
                                    [
                                      _vm._l(
                                        _vm.billGoods,
                                        function (item, index) {
                                          return _c("div", { key: index }, [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: { color: "#666" },
                                              },
                                              [
                                                _vm._v(
                                                  "商品名：" +
                                                    _vm._s(item.goodsName) +
                                                    "   商品编码：" +
                                                    _vm._s(item.goodsId)
                                                ),
                                              ]
                                            ),
                                          ])
                                        }
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "choice-sty goods",
                                          staticStyle: { position: "relative" },
                                          attrs: { slot: "reference" },
                                          slot: "reference",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "pointer",
                                              on: {
                                                click: function ($event) {
                                                  _vm.multipleSelectGoodsDrawerVisibility = true
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "已选择(" +
                                                  _vm._s(_vm.billGoods.length) +
                                                  ")"
                                              ),
                                            ]
                                          ),
                                          _c("i", {
                                            staticClass:
                                              "iconfont grow-a-icon_tabbar_close icon-sty",
                                            staticStyle: { color: "#55b78e" },
                                            on: {
                                              click: function ($event) {
                                                _vm.billGoods = []
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    2
                                  ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            [
                              _c("template", { slot: "label" }, [
                                _c(
                                  "div",
                                  [
                                    _vm._v(" 消费客户 "),
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "light",
                                          placement: "bottom-start",
                                        },
                                      },
                                      [
                                        _c("template", { slot: "content" }, [
                                          _c("p", [
                                            _vm._v(
                                              "自费/包干客户：输入客户名称"
                                            ),
                                          ]),
                                          _c("p", [
                                            _vm._v(
                                              "保险公司：输入公司名称、编码"
                                            ),
                                          ]),
                                          _c("p", [
                                            _vm._v(
                                              "三包厂家：输入厂家名称、编码"
                                            ),
                                          ]),
                                        ]),
                                        _c("i", {
                                          staticClass:
                                            "iconfont grow-icon_doubt",
                                          staticStyle: { color: "#9a9a9a" },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-input", {
                                attrs: { clearable: "", placeholder: "请输入" },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.doSearch.apply(null, arguments)
                                  },
                                },
                                model: {
                                  value: _vm.form.consumerMsg,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "consumerMsg", $$v)
                                  },
                                  expression: "form.consumerMsg",
                                },
                              }),
                            ],
                            2
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "索赔单号" } },
                            [
                              _c("el-input", {
                                attrs: { clearable: "", placeholder: "请输入" },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.doSearch.apply(null, arguments)
                                  },
                                },
                                model: {
                                  value: _vm.form.claimsNum,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "claimsNum", $$v)
                                  },
                                  expression: "form.claimsNum",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "事故责任" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    filterable: "",
                                    placeholder: "搜索事故责任",
                                  },
                                  model: {
                                    value: _vm.form.dutyType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "dutyType", $$v)
                                    },
                                    expression: "form.dutyType",
                                  },
                                },
                                _vm._l(_vm.dutyTypeList, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            [
                              _c("template", { slot: "label" }, [
                                _c(
                                  "div",
                                  { staticClass: "flex flex-x-center" },
                                  [
                                    _vm._v(" 开票主体 "),
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "light",
                                          placement: "bottom-start",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              color: "#666666",
                                              "font-size": "13px",
                                              "line-height": "20px",
                                              "text-indent": "15px",
                                            },
                                            attrs: { slot: "content" },
                                            slot: "content",
                                          },
                                          [
                                            _vm._v(
                                              " 开票主体，即为协议客户、保险公司和三包厂家的应收账款 "
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              " 的回款管理门店(保险公司的开票主体也是定损主体)。保险公司 "
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              " 管理的开票主体，可查已成为保险公司开票 主体的全部门店。 "
                                            ),
                                          ]
                                        ),
                                        _c("i", {
                                          staticClass:
                                            "iconfont grow-icon_doubt",
                                          staticStyle: { color: "#9a9a9a" },
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    loading: _vm.searchInvoiceLoading,
                                    "remote-method": _vm.searchInvoice,
                                    clearable: "",
                                    filterable: "",
                                    placeholder: "搜索开票主体名称",
                                    remote: "",
                                  },
                                  on: {
                                    change: _vm.selectInvoice,
                                    clear: _vm.deleteInvoice,
                                  },
                                  model: {
                                    value: _vm.form.invoiceName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "invoiceName", $$v)
                                    },
                                    expression: "form.invoiceName",
                                  },
                                },
                                _vm._l(_vm.invoiceList, function (item) {
                                  return _c("el-option", {
                                    key: item.companyId,
                                    attrs: {
                                      label: item.invoicingEntity,
                                      value: item.companyId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            2
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "车单标签" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    multiple: "",
                                    placeholder: "请选择车单标签",
                                  },
                                  model: {
                                    value: _vm.form.billLabel,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "billLabel", $$v)
                                    },
                                    expression: "form.billLabel",
                                  },
                                },
                                _vm._l(_vm.billLabelOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "报案号" } },
                            [
                              _c("el-input", {
                                attrs: { clearable: "", placeholder: "请输入" },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.doSearch.apply(null, arguments)
                                  },
                                },
                                model: {
                                  value: _vm.form.caseNum,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "caseNum", $$v)
                                  },
                                  expression: "form.caseNum",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "预交车时间" } },
                            [
                              _c("el-date-picker", {
                                staticClass: "w100",
                                attrs: {
                                  "end-placeholder": "截止时间",
                                  "range-separator": "至",
                                  "start-placeholder": "起始时间",
                                  type: "datetimerange",
                                  "unlink-panels": "",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.dateRangeChange()
                                  },
                                },
                                model: {
                                  value: _vm.form.acceptingTheCartTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "acceptingTheCartTime",
                                      $$v
                                    )
                                  },
                                  expression: "form.acceptingTheCartTime",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "取消时间" } },
                            [
                              _c("el-date-picker", {
                                staticClass: "w100",
                                attrs: {
                                  "end-placeholder": "截止时间",
                                  "range-separator": "至",
                                  "start-placeholder": "起始时间",
                                  type: "daterange",
                                  "unlink-panels": "",
                                  "value-format": "yyyy-MM-dd",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.dateRangeChange()
                                  },
                                },
                                model: {
                                  value: _vm.form.cancelTimes,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "cancelTimes", $$v)
                                  },
                                  expression: "form.cancelTimes",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "单据金额" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "" },
                                  model: {
                                    value: _vm.form.paid,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "paid", $$v)
                                    },
                                    expression: "form.paid",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { value: 2, label: "全部" },
                                  }),
                                  _c("el-option", {
                                    attrs: { value: 1, label: "大于0" },
                                  }),
                                  _c("el-option", {
                                    attrs: { value: 0, label: "等于0" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "检车状态" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择检车状态" },
                                  model: {
                                    value: _vm.form.detectionState,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "detectionState", $$v)
                                    },
                                    expression: "form.detectionState",
                                  },
                                },
                                [
                                  _c("el-option", { attrs: { label: "全部" } }),
                                  _c("el-option", {
                                    attrs: { value: 0, label: "否" },
                                  }),
                                  _c("el-option", {
                                    attrs: { value: 1, label: "是" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "报价状态" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择报价状态" },
                                  model: {
                                    value: _vm.form.quoteState,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "quoteState", $$v)
                                    },
                                    expression: "form.quoteState",
                                  },
                                },
                                [
                                  _c("el-option", { attrs: { label: "全部" } }),
                                  _c("el-option", {
                                    attrs: { value: 0, label: "否" },
                                  }),
                                  _c("el-option", {
                                    attrs: { value: 1, label: "是" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "报价审核状态" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择报价审核状态" },
                                  model: {
                                    value: _vm.form.quoteApproveState,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "quoteApproveState",
                                        $$v
                                      )
                                    },
                                    expression: "form.quoteApproveState",
                                  },
                                },
                                [
                                  _c("el-option", { attrs: { label: "全部" } }),
                                  _c("el-option", {
                                    attrs: { value: 0, label: "否" },
                                  }),
                                  _c("el-option", {
                                    attrs: { value: 1, label: "是" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "提交施工状态" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择提交施工状态" },
                                  model: {
                                    value: _vm.form.dispatchSubmitState,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "dispatchSubmitState",
                                        $$v
                                      )
                                    },
                                    expression: "form.dispatchSubmitState",
                                  },
                                },
                                [
                                  _c("el-option", { attrs: { label: "全部" } }),
                                  _c("el-option", {
                                    attrs: { value: 0, label: "否" },
                                  }),
                                  _c("el-option", {
                                    attrs: { value: 1, label: "是" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            ),
          ],
          1
        ),
      ],
      [
        _c("div", { staticClass: "btns flex-x-between" }, [
          _c("div", [
            _c("div", { staticClass: "custom-tabs nopadding" }, [
              _c(
                "div",
                {
                  class: {
                    "custom-tab-item": true,
                    active: _vm.status == -1,
                  },
                  on: {
                    click: function ($event) {
                      _vm.status = -1
                    },
                  },
                },
                [
                  _c("p", [
                    _vm._v(
                      "全部(" +
                        _vm._s(_vm.carBillPaidStatistic.carBillNum) +
                        ")"
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      "￥" +
                        _vm._s(
                          _vm._f("toThousands")(
                            _vm.carBillPaidStatistic.carBillPaidTotal
                          )
                        )
                    ),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  class: {
                    "custom-tab-item": true,
                    active: _vm.status == 0,
                  },
                  on: {
                    click: function ($event) {
                      _vm.status = 0
                    },
                  },
                },
                [
                  _c("p", [
                    _vm._v(
                      "自费(" +
                        _vm._s(_vm.carBillPaidStatistic.selfPayBillNum) +
                        ")"
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      "￥" +
                        _vm._s(
                          _vm._f("toThousands")(
                            _vm.carBillPaidStatistic.selfPayBillPaidTotal
                          )
                        )
                    ),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  class: {
                    "custom-tab-item": true,
                    active: _vm.status == 1,
                  },
                  on: {
                    click: function ($event) {
                      _vm.status = 1
                    },
                  },
                },
                [
                  _c("p", [
                    _vm._v(
                      "包干(" +
                        _vm._s(_vm.carBillPaidStatistic.contractForBillNum) +
                        ")"
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      "￥" +
                        _vm._s(
                          _vm._f("toThousands")(
                            _vm.carBillPaidStatistic.contractForBillPaidTotal
                          )
                        )
                    ),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  class: {
                    "custom-tab-item": true,
                    active: _vm.status == 2,
                  },
                  on: {
                    click: function ($event) {
                      _vm.status = 2
                    },
                  },
                },
                [
                  _c("p", [
                    _vm._v(
                      "理赔(" +
                        _vm._s(
                          _vm.carBillPaidStatistic.claimSettlementBillNum
                        ) +
                        ")"
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      "￥" +
                        _vm._s(
                          _vm._f("toThousands")(
                            _vm.carBillPaidStatistic
                              .claimSettlementBillPaidTotal
                          )
                        )
                    ),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  class: {
                    "custom-tab-item": true,
                    active: _vm.status == 3,
                  },
                  on: {
                    click: function ($event) {
                      _vm.status = 3
                    },
                  },
                },
                [
                  _c("p", [
                    _vm._v(
                      "索赔(" +
                        _vm._s(
                          _vm.carBillPaidStatistic.claimForCompensationBillNum
                        ) +
                        ")"
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      "￥" +
                        _vm._s(
                          _vm._f("toThousands")(
                            _vm.carBillPaidStatistic
                              .claimForCompensationBillPaidTotal
                          )
                        )
                    ),
                  ]),
                ]
              ),
            ]),
          ]),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "auth",
                      rawName: "v-auth",
                      value: 494,
                      expression: "494",
                    },
                  ],
                  staticClass: "blue",
                  attrs: { size: "small", type: "text" },
                  on: {
                    click: function ($event) {
                      _vm.showExportDialog = true
                    },
                  },
                },
                [
                  _vm._v(" 导出列表 "),
                  _c("i", {
                    staticClass: "iconfont grow-icon_daochu el-icon--right",
                  }),
                ]
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          {
            staticClass: "flex h100",
            staticStyle: { flex: "1", overflow: "auto" },
          },
          [
            _c(
              "div",
              { staticStyle: { flex: "1", overflow: "auto" } },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "fixedScrollBar",
                        rawName: "v-fixedScrollBar",
                        value: _vm.$refs.baseContainer,
                        expression: "$refs.baseContainer",
                      },
                    ],
                    staticClass: "custom-table",
                    attrs: {
                      data: _vm.carBillList,
                      "header-cell-style": _vm.headerCellStyle,
                      border: "",
                    },
                    on: {
                      "row-dblclick": function ($event) {
                        return _vm.goDetail($event.billId)
                      },
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { label: "序号", type: "index", width: "60" },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "操作门店",
                        prop: "operateCompanyName",
                        width: "100",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "el-tooltip",
                                { attrs: { content: row.operateCompanyName } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "text-ellipsis-2-row" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(row.operateCompanyName) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "车牌号",
                        prop: "carNumber",
                        width: "100",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { label: "车单号/业务类型", width: "170" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass: "blue pointer",
                                  on: {
                                    click: function ($event) {
                                      return _vm.goDetail(row.billId)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(row.billCode) + " ")]
                              ),
                              row.billType !== 0
                                ? _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("dict")(
                                            row.billType,
                                            "carBillType"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("dict")(
                                            row.isOthersSelfPayState,
                                            "isOthersSelfPayState"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "消费客户",
                        "show-overflow-tooltip": "",
                        width: "120",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              row.consumer
                                ? _c("div", [
                                    _c(
                                      "div",
                                      { staticClass: "text-ellipsis" },
                                      [
                                        _vm._v(
                                          " " + _vm._s(row.consumer.name) + " "
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "业务来源",
                        prop: "source",
                        width: "100",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "el-tooltip",
                                { attrs: { content: row.source } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "text-ellipsis-2-row" },
                                    [_vm._v(" " + _vm._s(row.source) + " ")]
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "施工类型",
                        prop: "difficultyDesc",
                        width: "100",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "进店时间",
                        prop: "dateCreated",
                        width: "100",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { label: "预交车时间", width: "100" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              row.undetermined
                                ? _c("div", [_vm._v("待定")])
                                : _c("div", [
                                    _c("p", [
                                      _vm._v(_vm._s(row.planFinishTime)),
                                    ]),
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(row.waitInStore ? "在店等" : "")
                                      ),
                                    ]),
                                  ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "接车员",
                        prop: "creator.userName",
                        width: "100",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "进店里程",
                        prop: "mileage",
                        width: "100",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "车型/车架号",
                        "show-overflow-tooltip": "",
                        width: "180",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              row.carModel
                                ? _c("div", { staticClass: "text-ellipsis" }, [
                                    _vm._v(" " + _vm._s(row.carModel) + " "),
                                  ])
                                : _vm._e(),
                              row.uniqueId
                                ? _c("div", { staticClass: "text-ellipsis" }, [
                                    _vm._v(" " + _vm._s(row.uniqueId) + " "),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "paid", width: "100" },
                      scopedSlots: _vm._u([
                        {
                          key: "header",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                { staticClass: "flex" },
                                [
                                  _c(
                                    "el-tooltip",
                                    { attrs: { content: "单据金额" } },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "text-ellipsis" },
                                        [_vm._v("单据金额")]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        effect: "light",
                                        placement: "bottom-start",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            color: "#666666",
                                            "font-size": "13px",
                                            "line-height": "16px",
                                            "text-indent": "15px",
                                          },
                                          attrs: { slot: "content" },
                                          slot: "content",
                                        },
                                        [
                                          _vm._v(
                                            " 包干业务车单的包干金额,结账当月显示为，查询时 "
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            " 点的预分摊金额,结账次月显示为实际分摊金额 "
                                          ),
                                        ]
                                      ),
                                      _c("i", {
                                        staticClass: "iconfont grow-icon_doubt",
                                        staticStyle: { "margin-left": "5px" },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              row.billType == 1 && row.billState === 1
                                ? _c("span", [
                                    !row.takeTime
                                      ? _c("div", { staticClass: "color666" }, [
                                          _c("p", [_vm._v(_vm._s(row.paid))]),
                                          _c("div", [
                                            _vm._v(" 包干: "),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "auth",
                                                    rawName: "v-auth",
                                                    value: {
                                                      code: 473,
                                                      handle: "dataMarking",
                                                    },
                                                    expression:
                                                      "{ code: 473, handle: 'dataMarking' }",
                                                  },
                                                ],
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("toThousands")(
                                                      row.contractAmount
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ])
                                      : _c("div", [
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "auth",
                                                  rawName: "v-auth",
                                                  value: {
                                                    code: 473,
                                                    handle: "dataMarking",
                                                  },
                                                  expression:
                                                    "{ code: 473, handle: 'dataMarking' }",
                                                },
                                              ],
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("toThousands")(
                                                    row.contractAmount
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                  ])
                                : _c("div", [
                                    _vm._v(
                                      _vm._s(_vm._f("toThousands")(row.paid))
                                    ),
                                  ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { label: "车单状态", width: "140" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c("div", [
                                _c("p", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("dict")(
                                          row.billState,
                                          "carBillState"
                                        )
                                      ) +
                                      " "
                                  ),
                                  row.billOtherState
                                    ? _c("span", { staticClass: "red" }, [
                                        _vm._v(
                                          "(" + _vm._s(row.billOtherState) + ")"
                                        ),
                                      ])
                                    : _vm._e(),
                                ]),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "flex color626",
                                  staticStyle: { "font-size": "12px" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("dict")(
                                          row.detectionState,
                                          "detectionState"
                                        )
                                      ) +
                                      " "
                                  ),
                                  row.dispatchState !== undefined
                                    ? _c("div", [
                                        _c("span", [_vm._v("/")]),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("dict")(
                                                row.dispatchState,
                                                "dispatchState"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    ![0, 1].includes(_vm.status)
                      ? _c("el-table-column", {
                          attrs: {
                            label: "报案号/索赔单号",
                            "show-overflow-tooltip": "",
                            width: "160",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    row.billType === 2
                                      ? _c(
                                          "div",
                                          { staticClass: "text-ellipsis" },
                                          [
                                            _vm._v(
                                              " " + _vm._s(row.caseNum) + " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    row.billType === 3
                                      ? _c(
                                          "div",
                                          { staticClass: "text-ellipsis" },
                                          [
                                            _vm._v(
                                              " " + _vm._s(row.claimsNum) + " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2048849918
                          ),
                        })
                      : _vm._e(),
                    _c("el-table-column", {
                      attrs: {
                        label: "双系统单号",
                        prop: "doubleSysNum",
                        "show-overflow-tooltip": "",
                        width: "160",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { label: "事故责任", width: "100" },
                      scopedSlots: _vm._u([
                        {
                          key: "header",
                          fn: function () {
                            return [
                              _c(
                                "el-tooltip",
                                { attrs: { content: "事故责任" } },
                                [
                                  _c("span", { staticClass: "text-ellipsis" }, [
                                    _vm._v("事故责任"),
                                  ]),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("dict")(row.dutyType, "dutyType")
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "完工时间",
                        prop: "taskBillFinishTime",
                        width: "100",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "提车时间",
                        prop: "takeTime",
                        width: "100",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "最后提交结账时间",
                        prop: "submitCheckDate",
                        width: "100",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "结账时间",
                        prop: "checkDate",
                        width: "100",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "结账收银员",
                        prop: "lastCheckoutPerson",
                        width: "100",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "签约客户",
                        "show-overflow-tooltip": "",
                        width: "120",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              row.owner
                                ? _c("div", [
                                    _c(
                                      "div",
                                      { staticClass: "text-ellipsis" },
                                      [_vm._v(_vm._s(row.owner.name))]
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "开票主体",
                        prop: "invoice",
                        width: "100",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "el-tooltip",
                                { attrs: { content: row.invoice } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "text-ellipsis-2-row" },
                                    [_vm._v(" " + _vm._s(row.invoice) + " ")]
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "服务大类",
                        "show-overflow-tooltip": "",
                        width: "100",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              row.serviceCategoryNames
                                ? _c("div", { staticClass: "text-ellipsis" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          row.serviceCategoryNames
                                            .map((i) => {
                                              return i
                                            })
                                            .join()
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "施工班组",
                        prop: "technicianNames",
                        width: "100",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "el-tooltip",
                                { attrs: { content: row.technicianNames } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "text-ellipsis-2-row" },
                                    [
                                      _vm._v(
                                        " " + _vm._s(row.technicianNames) + " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "车主要求",
                        prop: "remark",
                        width: "100",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "el-tooltip",
                                { attrs: { content: row.remark } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "text-ellipsis-2-row" },
                                    [_vm._v(" " + _vm._s(row.remark) + " ")]
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "初诊与备注",
                        prop: "firstVisitRemark",
                        width: "100",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "el-tooltip",
                                { attrs: { content: row.firstVisitRemark } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "text-ellipsis-2-row" },
                                    [
                                      _vm._v(
                                        " " + _vm._s(row.firstVisitRemark) + " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "companyRemark", width: "162" },
                      scopedSlots: _vm._u([
                        {
                          key: "header",
                          fn: function () {
                            return [
                              _c(
                                "el-tooltip",
                                { attrs: { content: "车单标签与备注" } },
                                [
                                  _c("span", { staticClass: "text-ellipsis" }, [
                                    _vm._v("车单标签与备注"),
                                  ]),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "div",
                                { staticClass: "tags" },
                                [
                                  row.isClaimUnderWarranty === 1
                                    ? _c(
                                        "el-tag",
                                        { attrs: { size: "small" } },
                                        [_vm._v("在保")]
                                      )
                                    : _vm._e(),
                                  row.isClaimUnderWarranty === 0
                                    ? _c(
                                        "el-tag",
                                        { attrs: { size: "small" } },
                                        [_vm._v("不在保")]
                                      )
                                    : _vm._e(),
                                  row.isClaimActivity === 1
                                    ? _c(
                                        "el-tag",
                                        { attrs: { size: "small" } },
                                        [_vm._v("有活动")]
                                      )
                                    : _vm._e(),
                                  row.isClaimActivity === 0
                                    ? _c(
                                        "el-tag",
                                        { attrs: { size: "small" } },
                                        [_vm._v("无活动")]
                                      )
                                    : _vm._e(),
                                  row.isReturnCar
                                    ? _c(
                                        "el-tag",
                                        { attrs: { size: "small" } },
                                        [_vm._v("退车整备")]
                                      )
                                    : _vm._e(),
                                  row.isRepair
                                    ? _c(
                                        "el-tag",
                                        { attrs: { size: "small" } },
                                        [_vm._v("返修")]
                                      )
                                    : _vm._e(),
                                  row.isSupplementaryOrder
                                    ? _c(
                                        "el-tag",
                                        { attrs: { size: "small" } },
                                        [_vm._v("补单")]
                                      )
                                    : _vm._e(),
                                  row.isClaimSettlToSelfPayment
                                    ? _c(
                                        "el-tag",
                                        { attrs: { size: "small" } },
                                        [_vm._v("理赔转自费")]
                                      )
                                    : _vm._e(),
                                  row.isClaimExternalService
                                    ? _c(
                                        "el-tag",
                                        { attrs: { size: "small" } },
                                        [_vm._v("外服")]
                                      )
                                    : _vm._e(),
                                  row.isClaimSettlFullLoss
                                    ? _c(
                                        "el-tag",
                                        { attrs: { size: "small" } },
                                        [_vm._v("全损")]
                                      )
                                    : _vm._e(),
                                  row.doubleSysInputState === 1
                                    ? _c(
                                        "el-tag",
                                        { attrs: { size: "small" } },
                                        [_vm._v("双系统待录单")]
                                      )
                                    : _vm._e(),
                                  row.doubleSysInputState === 2
                                    ? _c(
                                        "el-tag",
                                        { attrs: { size: "small" } },
                                        [_vm._v("双系统已录单")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "el-tooltip",
                                { attrs: { content: row.companyRemark } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "text-ellipsis-2-row",
                                      staticStyle: { "padding-left": "5px" },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(row.companyRemark) + " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "repairTracingRemark", width: "200" },
                      scopedSlots: _vm._u([
                        {
                          key: "header",
                          fn: function () {
                            return [
                              _c(
                                "el-tooltip",
                                { attrs: { content: "返修追溯备注" } },
                                [
                                  _c("span", { staticClass: "text-ellipsis" }, [
                                    _vm._v("返修追溯备注"),
                                  ]),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "el-tooltip",
                                { attrs: { content: row.repairTracingRemark } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "text-ellipsis-2-row" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(row.repairTracingRemark) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c(
                      "el-table-column",
                      {
                        attrs: { prop: "carStrandedUpdatedTime", width: "100" },
                      },
                      [
                        _c(
                          "template",
                          { slot: "header" },
                          [
                            _c(
                              "el-tooltip",
                              { attrs: { content: "滞留更新时间" } },
                              [
                                _c("span", { staticClass: "text-ellipsis" }, [
                                  _vm._v("滞留更新时间"),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                    _c("el-table-column", {
                      attrs: {
                        label: "滞留报告人",
                        prop: "reporterName",
                        width: "100",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "滞留|中断原因",
                        prop: "strandedReason",
                        width: "120",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "el-tooltip",
                                { attrs: { content: row.strandedReason } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "text-ellipsis-2-row" },
                                    [
                                      _vm._v(
                                        " " + _vm._s(row.strandedReason) + " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { width: "110" },
                      scopedSlots: _vm._u([
                        {
                          key: "header",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                { staticClass: "flex" },
                                [
                                  _c(
                                    "el-tooltip",
                                    { attrs: { content: "卡点解决时间" } },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "text-ellipsis" },
                                        [_vm._v("卡点解决时间")]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        effect: "light",
                                        placement: "bottom",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            color: "#666666",
                                            "font-size": "13px",
                                            "line-height": "16px",
                                          },
                                          attrs: { slot: "content" },
                                          slot: "content",
                                        },
                                        [_vm._v("当前阶段预计完成时间")]
                                      ),
                                      _c("i", {
                                        staticClass: "iconfont grow-icon_doubt",
                                        staticStyle: { "margin-left": "5px" },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _vm._v(
                                " " + _vm._s(row.planCompletionTime) + " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "是否有免赔",
                        prop: "exemptionPay2000Str",
                        width: "100",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "免赔金额",
                        prop: "exemptionPayMoney",
                        width: "100",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "免赔支付方",
                        prop: "settlementClientName",
                        width: "100",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "取消接待时间",
                        prop: "cancelDateTime",
                        width: "200",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "class-box", staticStyle: { overflow: "auto" } },
              [
                _c("p", { staticClass: "title" }, [
                  _vm._v("车单状态与统计（车单数）"),
                ]),
                _c("el-tree", {
                  ref: "my-tree",
                  staticClass: "custom-tree",
                  attrs: {
                    "current-node-key": _vm.nodeKey,
                    data: _vm.treeData,
                    "default-expanded-keys": _vm.defaultKeys,
                    props: _vm.treeProp,
                    "highlight-current": "",
                    "node-key": "id",
                  },
                  on: {
                    click: _vm.setCurrentKey,
                    "node-click": _vm.onNodeClick,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ data }) {
                        return [
                          _c("div", { staticClass: "tree-node" }, [
                            _c("span", [_vm._v(_vm._s(data.name))]),
                            _c("span", [
                              _vm._v("(" + _vm._s(data.count) + ")"),
                            ]),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ]
        ),
        _c("export-dialog", {
          attrs: {
            filterForm: _vm.form,
            pageOption: _vm.pageOption,
            total: _vm.total,
            url: _vm.exportUrl,
            visibility: _vm.showExportDialog,
          },
          on: {
            "update:visibility": function ($event) {
              _vm.showExportDialog = $event
            },
          },
        }),
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c("pagination", {
            attrs: { total: _vm.total },
            on: { pageChange: _vm.changePage },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.labelDialogVisible, width: "40%" },
          on: {
            "update:visible": function ($event) {
              _vm.labelDialogVisible = $event
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _c("div", { staticClass: "bill-dialog" }, [
              _vm._v(" 选择项目标签 "),
              _c("span", [_vm._v("（可双击直接单选，或单击勾选再确定选择）")]),
            ]),
          ]),
          _c("div", { staticClass: "billLabel" }, [
            _c(
              "div",
              { staticClass: "artisan-list" },
              _vm._l(_vm.labelList, function (label) {
                return _c(
                  "div",
                  {
                    key: label.id,
                    class: { label: true, active: label.checked },
                    on: {
                      click: function ($event) {
                        return _vm.handleArtisanChecked(label)
                      },
                      dblclick: function ($event) {
                        return _vm.confirmLabel(label)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(label.name) + " ")]
                )
              }),
              0
            ),
          ]),
          _c("div", [
            _c("span", [_vm._v("已选择：")]),
            _vm.labelType == 1
              ? _c("span", { staticClass: "primary" }, [
                  _vm._v(
                    _vm._s(
                      _vm.checkedLabels.map((item) => item.name).join("、")
                    )
                  ),
                ])
              : _c("span", { staticClass: "primary" }, [
                  _vm._v(
                    _vm._s(
                      _vm.singeCarTagesChecked
                        .map((item) => item.name)
                        .join("、")
                    )
                  ),
                ]),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.labelDialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-right": "60px" },
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.confirmLabel()
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm.multipleSelectGoodsDrawerVisibility
        ? _c("select-goods-car-bill", {
            attrs: {
              oldGoodsData: _vm.billGoods || [],
              visibility: _vm.multipleSelectGoodsDrawerVisibility,
            },
            on: {
              "update:visibility": function ($event) {
                _vm.multipleSelectGoodsDrawerVisibility = $event
              },
              select: _vm.selectBillList,
            },
          })
        : _vm._e(),
      _vm.multipleSelectServicesDrawerVisibility
        ? _c("select-services-car-bill", {
            attrs: {
              oldServicesData: _vm.billServices || [],
              visibility: _vm.multipleSelectServicesDrawerVisibility,
            },
            on: {
              "update:visibility": function ($event) {
                _vm.multipleSelectServicesDrawerVisibility = $event
              },
              select: _vm.selectServicesList,
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }