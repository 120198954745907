<template>
  <div class="wrapper">
    <search ref="searchRef" :type="'FollowUpDetails'" @toggleStatus="setTableHei" @submit="searchSubmit" />

    <div class="table-wrap">
      <div class="export-btn-wrap" ref="exportRef">
        <el-button v-auth="801" class="export-btn" plain @click="importIsShow=true">导入</el-button>
        <el-button v-auth="783" class="export-btn" type="primary" @click="showExportDialog=true">导出</el-button>
      </div>
      <el-table :data="tableData" class="new-table" :height="tableHei">
        <el-table-column align="center" fixed label="序号" type="index" width="60" />
        <el-table-column align="center" fixed label="操作门店" prop="companyName" show-overflow-tooltip width="150" />
        <el-table-column align="center" fixed label="车单号" prop="billCode" show-overflow-tooltip width="220">
          <template #default="{ row }">
            <el-link :underline="false" style="color: #3fa1e6" type="primary" @click="goCarOrderDetail(row.billId)">{{ row.billCode }}</el-link>
          </template>
        </el-table-column>
        <el-table-column align="center" fixed label="车牌号" prop="carNumber" show-overflow-tooltip width="150" />
        <el-table-column align="center" fixed label="客户名称" prop="clientName" show-overflow-tooltip width="150" />
        <el-table-column align="center" label="进店时间" prop="dateCreated" show-overflow-tooltip width="150" />
        <el-table-column align="center" label="结算时间" prop="checkDate" show-overflow-tooltip width="150" />
        <el-table-column align="center" label="挂账金额" prop="unclearedDebtFixed" show-overflow-tooltip width="120" />
        <el-table-column align="center" label="已收金额" prop="alreadyPaid" show-overflow-tooltip width="120" />
        <el-table-column align="center" label="业务来源" prop="billSourceTypeName" show-overflow-tooltip width="120" />
        <el-table-column align="center" label="保险公司" prop="insuranceCompanyName" show-overflow-tooltip width="120" />
        <el-table-column align="center" label="事故责任" prop="dutyType" show-overflow-tooltip width="120">
          <template #default="{row}">
            {{ row.dutyType==0? "全责":row.dutyType==1? "无责":row.dutyType==2? "主责":row.dutyType==3? "次责":row.dutyType==4? "同责":'--' }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="结账月份" prop="checkYearMonth" show-overflow-tooltip width="120" />
        <el-table-column align="center" label="账龄（天）" prop="accountAge" show-overflow-tooltip width="120" />
        <el-table-column align="center" label="账龄分类" prop="accountAgeCategoryDesc" show-overflow-tooltip width="120" />
        <el-table-column align="center" label="已回款未核销" prop="receivedUnWriteOffAmount" show-overflow-tooltip width="120" />
        <el-table-column align="center" label="应跟进余额" prop="followUpBalance" show-overflow-tooltip width="120" />
        <el-table-column align="center" label="免赔挂账车队" prop="pendingFleetName" show-overflow-tooltip width="120" />
        <el-table-column align="center" label="需理赔组跟进金额" prop="claimGroupFollowUpAmount" show-overflow-tooltip width="180" />
        <el-table-column align="center" label="新旧区分" prop="newOrOld" show-overflow-tooltip width="120" />
        <el-table-column align="center" label="历史坏账" prop="badDebtsAmount" show-overflow-tooltip width="120" />
        <el-table-column align="center" label="跟进部门" prop="followUpDeptTypeDesc" show-overflow-tooltip width="120" />
        <el-table-column align="center" label="填写人" prop="fillUserName" show-overflow-tooltip width="120" />
        <el-table-column v-auth="784" align="center" fixed="right" label="操作" width="100">
          <template #default="{ row }">
            <div class="operate-center">
              <el-link :underline="false" class="table-text" type="primary" @click="edit(row)">编辑</el-link>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-wrap">
        <div class="total-text">共{{ total }}条</div>
        <el-pagination
          :current-page="currentPage"
          :page-size="pageSize"
          :page-sizes="[20, 30, 50, 100]"
          :total="total"
          layout="->, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange" />
      </div>
    </div>

    <el-dialog :visible.sync="EditVisible" title="编辑" width="30%">
      <el-form :inline="true" :model="EditForm" class="form-wrap" label-width="110px">
        <el-form-item label="新旧区分:">
          <el-select v-model="EditForm.newOrOld" clearable placeholder="请选择">
            <el-option v-for="(item,index) in newOrOldData" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="历史坏账:">
          <el-input v-model="EditForm.badDebtsAmount" onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')" clearable placeholder="请输入" />
        </el-form-item>
        <el-form-item label="跟进部门:">
          <el-select v-model="EditForm.followUpDeptType" clearable placeholder="请选择跟进部门">
            <el-option v-for="(item,index) in followUpDeptTypeData" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="editSave">确定</el-button>
      </div>
    </el-dialog>

    <!-- 导入弹窗 -->
    <import-dialog
      v-if="importIsShow"
      :visibility.sync="importIsShow"
      :uploadFileApi="batchImportUrl"
      :getLastResultApi="batchImportResultUrl"
      :importFailDetail="batchImportFailUrl"
      :showTip="false"
      templateFileUrl="https://growxny-dev-center.oss-cn-guangzhou.aliyuncs.com/template/%E5%BA%94%E6%94%B6%E8%B4%A6%E6%AC%BE%E7%90%86%E8%B5%94-%E8%B7%9F%E8%BF%9B%E6%98%8E%E7%BB%86-%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF_20241111.xlsx"
      title="批量导入"
    />
    <!--  导出弹框  -->
    <export-dialog
      :filterForm="form"
      :pageOption="pageOption"
      :setWid="'30%'"
      :total="total"
      :url="exportUrl"
      :visibility.sync="showExportDialog"
    />
  </div>
</template>

<script>
import search from "@/components/search/index.vue";
import {
  getFollowUpDetailList,
  getFollowUpDetailEdit,
  getFollowUpDetailSelect,
  getFollowUpDetailUrl,
  batchImportFollowUpDetailApi,
  batchImportFollowDetailResultApi,
  batchImportFollowDetailFailApi,
} from "@/api/financial/financialOver";
import ExportDialog from "@/components/ExportDialog.vue";
import importDialog from "@/components/businessComponent/importDialog.vue";

export default {
  name: "FollowUpDetails",
  components: {
    search,
    ExportDialog,
    importDialog
  },
  data() {
    return {
      tableHei: "50vh",
      tableData: [],
      pageSize: 20,
      currentPage: 1,
      total: 30,
      showExportDialog: false,
      pageOption: {
        size: 15,
        current: 1,
      },
      form: {},
      exportUrl: getFollowUpDetailUrl,
      importIsShow: false,
      batchImportUrl: batchImportFollowUpDetailApi,
      batchImportResultUrl: batchImportFollowDetailResultApi,
      batchImportFailUrl: batchImportFollowDetailFailApi,
      EditVisible: false,
      EditForm: {},
      EditRow: "",
      newOrOldData: [],
      followUpDeptTypeData: [],

    };
  },
  created() {
    let companyId = sessionStorage.getItem("companyId");
    this.getListData({ checkTime: this.getTodayDate(), operateCompanyId: [companyId] });
    getFollowUpDetailSelect().then(res => {
      this.newOrOldData = res.oldOrNewEnumList;
      this.followUpDeptTypeData = res.followupDeptEnumList;
    });
  },
  mounted() {
    this.setTableHei();
  },
  methods: {
    /** 动态控制表格高度 **/
    setTableHei(){
      this.$nextTick(()=>{
        let windowsHei = document.documentElement.clientHeight;
        let searchHei = this.$refs.searchRef.$el.offsetHeight;
        let exportHei = this.$refs.exportRef.offsetHeight;
        this.tableHei = windowsHei - searchHei - exportHei - 160
      })
    },
    /** 跳转车单详情 **/
    goCarOrderDetail(id) {
      this.$router.push({
        name: "carBillDetail",
        query: { id, },
      });
    },
    /** 获取今天的日期 **/
    getTodayDate(){
      const date = new Date();
      const currentYear = date.getFullYear();
      const currentMonth = (date.getMonth() + 1)<10? `0${date.getMonth() + 1}`:date.getMonth() + 1;
      const today = date.getDate()<10? `0${date.getDate()}`:date.getDate();
      let timeStart = `${currentYear}-${currentMonth}-01`;
      let time = `${currentYear}-${currentMonth}-${today}`;
      return [timeStart, time]
    },
    /** 搜索数据返回值 **/
    searchSubmit(data) {
      console.log("search----data--->>>", data);
      this.pageSize = 20;
      this.currentPage = 1;
      this.form = data || {}
      this.getListData(data);
    },
    /** 获取列表数据 **/
    getListData(data) {
      let obj = {
        current: this.currentPage,
        size: this.pageSize,
        operateCompanyId: data?.operateCompanyId, //操作门店列表
        billCode: data?.billCode, // 接车单号
        clientId: "", // 客户ID
        clientName: data?.clientName,  // 客户名称
        plateNumber: data?.plateNumber,  // 车牌号
        insuranceCompanyId: data?.insuranceCompanyId,  // 保险公司ID
        sourceId: "",  // 业务来源ID
        sourceName: data?.sourceName,  // 业务来源名称
        dutyType: data?.dutyType,  // 事故责任
        intoStoreBeginDateTime: data?.intoStoreTime?.length > 0 ? `${data?.intoStoreTime[0]} 00:00:00` : "",  // 进店开始时间
        intoStoreEndDateTime: data?.intoStoreTime?.length > 0 ? `${data?.intoStoreTime[1]} 23:59:59` : "",  // 进店结束时间
        checkBeginDateTime: data?.checkTime?.length > 0 ? `${data?.checkTime[0]} 00:00:00` : "",  // 结账开始时间
        checkEndDateTime: data?.checkTime?.length > 0 ? `${data?.checkTime[1]} 23:59:59` : "",  // 结账结束时间
        newOrOld: data?.newOrOld,  // 新旧区分
        followUpDeptType: data?.followUpDeptType,  // 跟进部门
        badDebtsAmount: data?.badDebtsAmount,  // 历史坏账
        fillUserName: data?.fillUserName, // 填写人
      }
      this.tableData = []
      getFollowUpDetailList(obj).then(res=>{
        this.tableData = res.records
        this.total = Number(res.total)
      })
    },
    /** 编辑数据 **/
    edit(row) {
      console.log("edit----row---->>>", row);
      this.EditRow = row;
      this.EditVisible = true;
      this.$set(this.EditForm, "newOrOld", String(row.newOrOld || ""));
      this.$set(this.EditForm, "followUpDeptType", String(row.followUpDeptType || ""));
      this.$set(this.EditForm, "badDebtsAmount", row.badDebtsAmount);
    },
    /** 编辑保存  **/
    editSave() {
      let obj = {
        id: this.EditRow.id, // 跟进明细记录ID
        billId: this.EditRow.billId, // 车单ID
        remark: "", // 备注
        ...this.EditForm,
      };
      console.log("EditForm--->>>", obj);
      getFollowUpDetailEdit(obj).then(res => {
        this.EditVisible = false;
        this.$message.success("操作成功！");
        this.getListData(this.form);
      });
    },
    /** 切换一页显示多少数据 **/
    handleSizeChange(newSize) {
      console.log("newSize--->>>", newSize);
      this.pageSize = newSize;
      this.pageOption.size = newSize;
      this.getListData(this.form);
    },
    /** 切换页数 **/
    handleCurrentChange(newPage) {
      console.log("newPage--->>>", newPage);
      this.currentPage = newPage;
      this.getListData(this.form);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  /** 全局form-item/button/input样式 **/
  .el-form-item {
    display: flex;
  }

  :deep .el-input__inner {
    width: 100% !important;
  }

  .table-wrap {
    padding: 0 14px 14px;

    .export-btn-wrap {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 12px;
    }
  }

  .pagination-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;

    .total-text {
      font-size: 14px;
      color: #444;
    }
  }

  .form-wrap {
    margin-top: 10px;

    :deep .el-form-item__content {
      width: 75% !important;
    }

    :deep .el-select {
      width: 100% !important;
    }

    :deep .el-input {
      width: 100% !important;
    }
  }

  .dialog-footer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
