import http from "@/service/http";

const defaultListOption = {
  current: 1,
  size: 10,
};

export function batchDeleteApi(data = {}) {
  return http.post("/admin/car/factory/compensation/activity/delete", data);
}

export function preListApi() {
  return http.post("/admin/car/factory/compensation/activity/preList");
}

export function listApi(data) {
  return http.post("/admin/car/factory/compensation/activity/list", data);
}

export function batchImportByFileApi(file) {
  let formData = new FormData();
  formData.append("file", file);

  return http.request({
    method: "post",
    url: "/admin/car/factory/compensation/activity/import",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
    timeout: 18e5,
  });
}

export function batchImportByFileResultApi() {
  return http.post("/admin/car/factory/compensation/activity/importResult");
}

export const listExportUrl = "/admin/car/factory/compensation/activity/list/export";

export const batchImportByFileFailedExportUrl = "/admin/car/factory/compensation/activity/importFailMessage/export";

/**
 * 整备交付中心单据报表列表
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function getCarDeliverMsgList(data) {
  return http.post("/admin/preparation/delivery/center/reportList", data);
}

/**
 * 整备交付中心单据报表-列表导出
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export const getCarDeliverMsgUrl = "/admin/preparation/delivery/center/reportList/export";

/**
 * 整备交付中心列表查询
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function getDeliveryCenterList(data) {
  return http.post("/admin/preparation/delivery/center/preparationDeliveryCenterList", data);
}

/**
 * 整备组列表查询
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function getGroupList(data) {
  return http.post("/admin/preparation/delivery/center/preparationGroupList", data);
}
/**
 * 整备组列表新增
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function getGroupAdd(data) {
  return http.post("/admin/preparation/delivery/center/addPreparationGroup", data);
}
/**
 * 整备组列表编辑
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function getGroupEdit(data) {
  return http.post("/admin/preparation/delivery/center/updatePreparationGroup", data);
}
/**
 * 整备组列表删除
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function getGroupDel(data) {
  return http.post("/admin/preparation/delivery/center/deletePreparationGroup", data);
}

/**
 * 交付中心列表查询
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function getCenterList(data) {
  return http.post("/admin/preparation/delivery/center/deliveryCenterList", data);
}

/**
 * 交付中心列表新增
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function getCenterAdd(data) {
  return http.post("/admin/preparation/delivery/center/addDeliveryCenter", data);
}
/**
 * 交付中心列表编辑
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function getCenterEdit(data) {
  return http.post("/admin/preparation/delivery/center/updateDeliveryCenter", data);
}
/**
 * 交付中心列表删除
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function getCenterDel(data) {
  return http.post("/admin/preparation/delivery/center/deleteDeliveryCenter", data);
}