<template>
  <el-popover
    ref="popover"
    placement="bottom"
    style="width: 50vw"
    popper-class="custom-popover"
    trigger="manual"
    v-model="selectGoodsPopoverVisible"
    :open-delay="200"
    :offset="200"
  >
    <!-- :popper-options="{
      flipBehavior: ['bottom'],
    }" -->
    <div>
      <el-form label-width="100px" size="small">
        <el-row :gutter="20">
          <el-col :span="4" :offset="0">
            <el-form-item label="库存大于0">
              <el-checkbox
                style="margin-left: 15px"
                v-model="pageInStock"
                @change="changeInStock"
              ></el-checkbox>
            </el-form-item>
          </el-col>
          <el-col :span="6" :offset="0">
            <el-form-item label="商品品名">
              <el-select
                v-model="productIds"
                multiple
                filterable
                remote
                placeholder="请搜索，支持多选"
                :remote-method="searchProductList"
                :loading="searchProductListLoading"
                value-key="id"
                @change="getData"
              >
                <el-option
                  v-for="item in productList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" :offset="0">
            <el-form-item label="适用车型">
              <el-input
                v-model="keyWordOfModel"
                clearable
                @keyup.enter.native="getData(true)"
                @input="handleModelInput"
                size="small"
                placeholder="请输入适用车型"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6" :offset="0">
            <el-form-item label="原厂编码">
              <el-input
                v-model="goodsCode"
                placeholder="请输入原厂编码"
                clearable
                @input="getData(true)"
                @keyup.enter.native="getData(true)"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6" :offset="0">
            <el-form-item label="入库批次号">
              <el-input
                v-model="inventoryInAndOutBillCode"
                placeholder="请输入入库批次号"
                clearable
                @keyup.enter.native="getData(true)"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <el-table
      max-height="300px"
      v-loading="isLoading"
      :data="goodsList"
      @row-click="selectGoodsRow"
      show-overflow-tooltip
    >
      <el-table-column type="index" label="序号" width="60"></el-table-column>
      <el-table-column width="180" prop="matchCarType" label="适用车型">
        <template #default="{ row }">
          <el-tag
            v-if="row.isAgreePrice"
            type="info"
            size="mini"
            class="el-icon--left"
            >协议</el-tag
          >
          <span>{{ row.matchCarType }}</span>
        </template>
      </el-table-column>
      <el-table-column width="180" label="商品标准名" prop="standardName">
      </el-table-column>
      <el-table-column width="100" label="品质">
        <template #default="{ row }">
          <span>{{ row.goodsType | dict('manufactoryType') }}</span>
        </template>
      </el-table-column>
      <el-table-column
        width="140"
        label="自编码"
        prop="ownCode"
      ></el-table-column>
      <el-table-column
        width="140"
        label="原厂编码"
        prop="goodsCode"
      ></el-table-column>
      <el-table-column width="120" label="本店库存/单位">
        <template #default="{ row }">
          <span>{{ row.inventoryTotalNum }}</span>
          <span v-if="row.unit">({{ row.unit }})</span>
        </template>
      </el-table-column>
      <el-table-column width="120" label="他店库存/单位">
        <template #default="{ row }">
          <span>{{ row.otherInventoryTotalNum }}</span>
          <span v-if="row.unit && row.otherInventoryTotalNum"
            >({{ row.unit }})</span
          >
        </template>
      </el-table-column>
      <el-table-column width="120" label="售价">
        <template #default="{ row }">
          <span>{{ row.price | toThousands }}</span>
        </template>
      </el-table-column>
      <el-table-column
        min-width="120"
        label="商品备注"
        prop="remark"
        show-overflow-tooltip
      >
      </el-table-column>
    </el-table>
    <div style="height: 40px">
      <div v-if="hasMore" class="load-more-btn" @click="getData(false, true)">
        加载更多数据
      </div>
      <div v-else class="all">已全部加载</div>
    </div>
    <el-input
      ref="keyWordInput"
      slot="reference"
      v-model="keyWord"
      clearable
      size="small"
      placeholder="搜索商品名称"
      @input="getData(true)"
      @focus="handleInputFocus"
    ></el-input>
  </el-popover>
</template>

<script>
// 接口
import {
  searchGoodsForCreateCarBill,
  searchGoodsWithAgreementPriceForCreateCarBill,
} from '@/api/carBill';
import { getProductNameList } from '@/api/stock/goodsSetting';

import lodash from 'lodash';
import { mapState, mapMutations } from 'vuex';
export default {
  name: 'selectGoodsPopover',
  props: {
    carId: {
      type: [Number, String],
      default: '',
    },
    code: {
      type: String,
      default: '',
    },
    billType: [String, Number],
    clientAgreeId: [String, Number],
    /**
     * 服务对应品名
     */
    serviceProducts: {
      type: Array,
      default: () => [],
    },
    /**
     * 商品对应的服务ID
     */
    serviceItemId: {
      type: [String, Number],
      default: 0,
    },
    /**
     * 是否协议服务
     */
    isAgreePrice: {
      type: Boolean,
      default: false,
    },
    carModel: {
      type: String,
      default: '',
    },
    billCompanyId: {
      type: Number,
      default: 0,
    },
    isMatchThisModel: Number,
  },
  data() {
    return {
      // 商品ID goodsId;
      // 商品编码 goodsCode;
      // 商品名称 goodsName;
      // 售价 price;
      // 单位 unit;
      // 本店库存数量 inventoryTotalNum;
      // 适用车型 matchCarType;
      // 库存预警值 alarmNum;
      // 成本价 costPrice;
      // 4s参考价 referencePrice;
      // 一级分类 firstCategoryName;
      // 一级分类ID firstCategoryId;
      // 二级分类 secondCategoryName;
      // 二级分类ID secondCategoryId;
      // 别名 alias;

      //选择商品弹出层可见性
      selectGoodsPopoverVisible: false,

      // 商品列表数据
      goodsList: [],

      // 搜索关键字 - 商品名称
      keyWord: '',

      // 车型搜索关键字
      keyWordOfModel: '',

      // 分页所在页数
      pageCurrent: 0,

      // 是否未加载完所有数据
      hasMore: true,

      // 是否正在加载
      isLoading: false,

      // inventoryState: 0,
      // inventoryStateOptions: [
      //   {
      //     label: '全部库存',
      //     value: 0,
      //   },
      //   {
      //     label: '库存大于0',
      //     value: 1,
      //   },
      //   {
      //     label: '库存小于0',
      //     value: 2,
      //   },
      // ],
      debounce: {},

      productIds: [],

      productList: [],
      searchProductListLoading: false,

      // 编码
      goodsCode: '',
      // 库存大于0
      pageInStock: true,
      inventoryInAndOutBillCode: '',
      // 自编码
      ownCode: '',

      isFirstLoad: true,
    };
  },
  watch: {
    code(val) {
      // this.goodsCode = val;
      this.ownCode = val;
      this.getData(true);
    },
    focus(val) {
      console.log(val);
      this.getData(true);
    },
    inStock(val) {
      this.pageInStock = val;
    },
    clientAgreeId() {
      this.getData(true);
    },
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
      // 库存大于0
      inStock: (state) => state.user.inStock,
    }),
    pagination() {
      return { size: 5, current: this.pageCurrent };
    },
  },
  created() {
    this.keyWordOfModel = this.carModel;
    this.pageInStock = this.inStock;
    this.searchProductList();
    this.productIds = this.serviceProducts.map((el) => el.productId);
    console.log('serviceItemId', this.serviceItemId);
    this.getData();
  },
  mounted() {
    this.debounce = this.$lodash.debounce(this.handleDocumentScroll, 100, {
      leading: true,
    });
    setTimeout(() => {
      this.$refs.keyWordInput.focus();
    }, 1e2);
    document
      .getElementsByClassName('main')[0]
      .addEventListener('scroll', this.debounce);
    document.addEventListener('click', this.handleDocumentClick);
  },
  beforeDestroy() {
    document
      ?.getElementsByClassName('main')[0]
      ?.removeEventListener('scroll', this.debounce);
    document?.removeEventListener('click', this.handleDocumentClick);
  },
  methods: {
    ...mapMutations({
      globalChangeInStock: 'changeInStock',
    }),
    /**
     * 搜索品名列表
     */
    searchProductList(keyWord) {
      this.searchProductListLoading = true;
      getProductNameList(
        { name: keyWord || '', current: 1, size: 50 },
        { noShowWaiting: true }
      )
        .then((res) => {
          if (res.records?.length) {
            this.productList = this.$lodash.unionBy(
              this.serviceProducts
                .map((el) => ({
                  id: el.productId,
                  name: el.productName,
                }))
                .concat(res.records || []),
              'id'
            );
          } else {
            this.productList = res.records || [];
          }
        })
        .finally(() => {
          this.searchProductListLoading = false;
        });
    },
    /**
     * 更改 库存大于0
     */
    changeInStock(value) {
      this.globalChangeInStock({ value });
      this.getData(true);
    },
    /**
     * 输入框获取焦点
     */
    handleInputFocus() {
      this.selectGoodsPopoverVisible = true;
    },
    /**
     * 输入适用车型
     */
    handleModelInput(val) {
      this.$emit('update:carModel', val);
      this.getData(true);
    },
    /**
     * 加载数据
     */
    getData(reset, loadMore) {
      this.$nextTick().then(() => {
        this.$refs.popover.updatePopper();
      });
      this.isLoading = true;
      this.debounceGetData(reset, loadMore);
    },
    debounceGetData: lodash.debounce(function (reset, loadMore) {
      // 重置分页，清空已有数据
      if (reset) {
        this.pageCurrent = 1;

        this.hasMore = true;
      } else {
        this.pageCurrent++;
      }
      let body = {
        ...this.pagination,
        goodsName: this.keyWord,
        goodsCode: this.goodsCode,
        ownCode: this.ownCode,
        carId: this.carId,
        // isMatchThisModel: 0, //是否匹配本车型（0/1 否/是)
        companyId: this.companyId,
        // 库存状态
        inventoryState: this.pageInStock ? 1 : 0,
        inventoryInAndOutBillCode: this.inventoryInAndOutBillCode,
        // inventoryState: this.inventoryState,
        // 适用车型
        modelName: this.keyWordOfModel,
        // 品名
        productIds: this.productIds,
        serviceItemId: this.serviceItemId,
        isMatchThisModel: this.isMatchThisModel,
      };

      if (this.billType !== undefined) {
        body.billType = this.billType;
      }
      if (this.clientAgreeId) {
        body.clientAgreeId = this.clientAgreeId;
      }
      if (this.billCompanyId) {
        body.billCompanyId = this.billCompanyId;
      }

      searchGoodsWithAgreementPriceForCreateCarBill(body)
        .then((res) => {
          if (!this.isFirstLoad) this.selectServicePopoverVisible = true;
          this.isFirstLoad = false;
          if (reset) this.goodsList = [];
          console.log(res);
          this.goodsList.push(...res.records);
          this.hasMore = this.pageCurrent < res.pages;
          this.$nextTick(() => {
            // this.$refs.table.doLayout();
            this.$refs.popover.updatePopper();
          });
        })
        .finally(() => {
          this.isLoading = false;
          if (loadMore) this.$refs.keyWordInput.focus();
        });
    }, 5e2),
    /**
     * 选中行
     */
    selectGoodsRow(val) {
      console.log('val', val);
      // this.selectGoodsPopoverVisible = false;
      this.$emit('select', val);
    },
    /**
     * 显示
     */
    show() {
      this.selectGoodsPopoverVisible = true;
    },
    handleDocumentScroll(e) {
      this.$refs.popover.updatePopper();
    },
    handleDocumentClick(e) {
      if (
        !this.$el ||
        this.$el.contains(e.target) ||
        !e.target.className ||
        e.target.id === 'codeInput' ||
        e.target.className.includes('el-icon-circle-close')
      ) {
        return;
      }
      this.selectGoodsPopoverVisible = false;
    },
  },
};
</script>

<style>
.custom-popover {
  padding: 10px 10px 0 !important;
}
.custom-popover .el-table__cell {
  padding: 5px 0;
}
</style>
<style lang="scss" scoped>
.load-more-btn {
  height: 40px;
  text-align: center;
  line-height: 40px;
  color: #3eb1e0;
  cursor: pointer;
  &:hover {
    color: #14a3db;
  }
}
.all {
  height: 40px;
  text-align: center;
  line-height: 40px;
  color: #aaa;
}
</style>
