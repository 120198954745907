import http from '@/service/http';

/**
 * 滞留单据日报
 * @params data {id:number}
 */
export function carStrandedCountDailyList() {
  return http.post('/admin/car/stranded/bill/stat/list');
}

/**
 * 滞留单据日报-明细查询
 * @params data {id:number}
 */
export function carStrandedCountDailyPreDetailList(data) {
  return http.post('/admin/car/stranded/bill/stat/preDetailList', data);
}
/**
 * 滞留单据日报-明细查询
 * @params data {id:number}
 */
export function carStrandedCountDailyDetailList(data) {
  return http.post('/admin/car/stranded/bill/stat/detailList', data);
}

/**
 * 滞留单据日报-明细解决方案编辑
 * @params data {id:number}
 */
export function saveOrUpdateCarStrandedSolution(data) {
  return http.post(
    '/admin/car/stranded/bill/stat/saveOrUpdateCarStrandedSolution',
    data
  );
}

/**
 * 滞留单据日报导出
 */
export const carStrandedCountDailyListExportUrl =
  '/admin/car/stranded/bill/stat/list/export';

/**
 * 滞留单据日报导出
 */
export const carStrandedCountDailyDetailListExportUrl =
  '/admin/car/stranded/bill/stat/detailList/export';

/**
 * 滞留单据日报-明细解决方案-批量导入
 */
export function carStrandedDetailBatchImport(file) {
  let formData = new FormData();
  formData.append('file', file);

  return http.request({
    method: 'post',
    url: '/admin/car/stranded/bill/stat/import',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
    timeout: 18e5,
  });
}

/**
 * 滞留单据日报-明细解决方案-批量导入结果
 */
export function carStrandedDetailBatchImportResult() {
  return http.post('/admin/car/stranded/bill/stat/importResult');
}

/**
 * 滞留车单日报明细说明导入失败结果导出
 */
export const carStrandedDetailBatchImportFailResultExportUrl =
  '/admin/car/stranded/bill/stat/importResultFailed/export';

/**
 * 事故车进店台次(门店)日报
 * @params data
 */
export function wreckedCarEntryCountDailyList(data) {
  return http.post('/admin/car/wrecked/bill/stat/list', data);
}

/**
 * 事故车进店台次(门店)日报导出
 */
export const wreckedCarEntryCountDailyListExportUrl =
  '/admin/car/wrecked/bill/stat/list/export';

/**
 * 查询事故车进店台次(门店)配置
 */
export function wreckedCarEntryCountSettingsCompanyList(data) {
  return http.post('/admin/car/wrecked/bill/stat/settings/company/list', data);
}

/**
 * 事故车进店台次(门店)配置
 * @params data {id:number}
 */
export function wreckedCarEntryCountSettingsCompanyUpdate(data) {
  return http.post(
    '/admin/car/wrecked/bill/stat/settings/company/update',
    data,
    {
      onlyReturnData: false,
    }
  );
}


/**
 * 事故车进店台次(门店)日报
 * @params data
 */
export function wreckedCarEntryCountDailyClientList(data) {
  return http.post('/admin/car/wrecked/bill/client/stat/list', data);
}

/**
 * 事故车进店台次(门店)日报导出
 */
export const wreckedCarEntryCountDailyClientListExportUrl =
  '/admin/car/wrecked/bill/client/stat/list/export';

/**
 * 查询事故车进店台次(客户)配置
 */
export function wreckedCarEntryCountSettingsClientList(data) {
  return http.post(
    '/admin/car/wrecked/bill/client/stat/settings/list',
    data
  );
}
/**
 * 事故车进店台次(客户)配置列表导出
 */
export const wreckedCarEntryCountDailyClientSettingsListExportUrl =
  '/admin/car/wrecked/bill/client/stat/settings/list/export';

/**
 * 事故车进店台次(客户)配置更新
 * @params data {id:number}
 */
export function wreckedCarEntryCountSettingsClientUpdate(data) {
  return http.post(
    '/admin/car/wrecked/bill/client/stat/settings/update',
    data,
    {
      onlyReturnData: false,
    }
  );
}

/**
 * 事故车进店台次(客户)配置-批量导入
 */
export function wreckedCarEntryCountSettingsClientBatchImport(file) {
  let formData = new FormData();
  formData.append('file', file);

  return http.request({
    method: 'post',
    url: '/admin/car/wrecked/bill/client/stat/settings/import',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
    timeout: 18e5,
  });
}

/**
 * 事故车进店台次(客户)配置-批量导入结果
 */
export function wreckedCarEntryCountSettingsClientBatchImportResult() {
  return http.post('/admin/car/wrecked/bill/client/stat/settings/importResult');
}

/**
 * 事故车进店台次(客户)配置导入失败结果导出
 */
export const wreckedCarEntryCountSettingsClientBatchImportFailResultExportUrl =
  '/admin/car/wrecked/bill/client/stat/settings/importResultFailed/export';