import http from "@/service/http";

//搜索业务来源
export function searchBillSource(keyWord = "", companyId = "", size = 20, current = 1, config = { noShowWaiting: true }) {
  return http.post(
    "/admin/car/billSourceType/list",
    {
      name: keyWord,
      companyId: companyId,
      size,
      current,
    },
    config,
  );
}

//营业总表
export function getBusinessList(data) {
  return http.post("/admin/finance/collect", data);
}

//营业总表-柱状图的四种状态类型汇总
export function getFinanceCollectType(data) {
  return http.post("/admin/finance/collectType", data);
}

//营业总表-导出列表
export const exportBusinessCollectListUrl = "/admin/businessCollect/export";

//收支明细-现金流表
export function getBalanceList(data) {
  return http.post("/admin/finance/cashFlow", data);
}

//应收账款-应收账款明细
export function getDetailOnAccountList(data) {
  return http.post("/admin/finance/detailOnAccount", data);
}

//应收账款-应收账款明细-导出列表
export const exportDetailOnAccountListUrl = "/admin/detailOnAccount/export";

//应收账款-应收账款明细——批量导入核销(自费、包干、索赔)
export function batchImportWriteOff(file) {
  let formData = new FormData();
  formData.append("file", file);

  return http.request({
    method: "post",
    url: "/admin/finance/batchImportWriteOff",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
    timeout: 18e5,
  });
}

//应收账款-应收账款明细——批量导入核销(自费、包干、索赔)最近导入结果
export function batchImportWriteOffResult(data) {
  return http.post("/admin/finance/batchImportWriteOffResult", data);
}

//应收账款-应收账款明细-导出列表
export const batchImportWriteOffFailResultExportUrl = "/admin/finance/batchImportWriteOffFailResultExport";

//应收账款-销账收款记录
export function getRecordOnAccountList(data) {
  return http.post("/admin/finance/recordOnAccount", data);
}

//应收账款-销账收款记录-销账收款详情
export function getRecordDetailOnAccountList(data) {
  return http.post("/admin/finance/recordDetailOnAccount", data);
}

//应收账款-销账收款记录-销账收款详情-作废
export function getRecordDetailOnAccountCancellation(data) {
  return http.post("/admin/finance/recordDetailOnAccount/cancellation", data, {
    onlyReturnData: false,
  });
}

//应收账款-销账收款记录-导出列表
export const exportRecordOnAccountListUrl = "/admin/recordOnAccount/export";

//应收账款-包干分摊批次
export function getContractBatchOnAccountList(data) {
  return http.post("/admin/finance/contractBatchOnAccount", data);
}

//应收账款-包干分摊批次-包干分摊批次重跑
export function getContractBatchRun(data) {
  return http.post("/admin/contractBatch/reRun", data);
}

//应收账款-应收账款汇总-按开票主体统计
export function getFundsOnAccountList(data) {
  return http.post("/admin/finance/fundsOnAccount", data);
}

//应收账款-应收账款汇总-按开票主体统计-导出列表
export const exportFundsOnAccountListUrl = "/admin/fundsOnAccount/export";

//应收账款-应收账款汇总-按操作门店统计
export function getCompanyOnAccountList(data) {
  return http.post("/admin/finance/companyOnAccount", data);
}

//应收账款-应收账款汇总-按操作门店统计-导出列表
export const exportCompanyOnAccountListUrl = "/admin/companyOnAccount/export";

//营业对账-车单结账明细
export function getBillDetailOnAccountList(data) {
  return http.post("/admin/finance/billDetailOnAccount", data);
}

//营业总表-车单结账明细-导出列表
export const exportBusinessBillDetailOnAccountListUrl = "/admin/businessBillDetailOnAccount/export";

//营业对账-车单项目明细
export function getBillDetailOnProjectList(data) {
  return http.post("/admin/finance/billDetailOnProject", data);
}

//营业总表-车单项目明细-导出列表
export const exportBillDetailOnProjectListUrl = "/admin/billDetailOnProject/export";

//营业对账-车单实收明细
export function getBillDetailOnReceiptsList(data) {
  return http.post("/admin/finance/billDetailOnReceipts", data);
}

//营业总表-车单实收明细-导出列表
export const exportBillDetailOnReceiptsListUrl = "/admin/billDetailOnReceipts/export";

// 营业对账 - 项目毛利明细 - 列表接口
export function itemGrossProfitList(data = {}, config = {}) {
  return http.post("/admin/finance/marginDetailOnProject", data, config);
}

// 营业对账 - 项目毛利明细 - 合计数据
export function itemGrossProfitTotal(data = {}, config = {}) {
  return http.post("/admin/finance/marginTotalOnProjectStatistics", data, config);
}

// 营业对账 - 项目毛利明细 - 导出接口
export const exportItemGrossProfitListUrl = "/admin/marginDetailOnProject/export";

//筛选条件中接车员的接口
export function searchAdminJobListUsr(keyWord = "", companyId = "", size = 10, current = 1, config = { noShowWaiting: true }) {
  return http.post(
    "/admin/job/listUsr",
    {
      name: keyWord,
      companyId: companyId,
      size,
      current,
    },
    config,
  );
}

//应收账款-应收账款明细-收款-收款销账
export function getFinanceRepaymentOnAccount(data) {
  return http.post("/admin/finance/repaymentOnAccount", data, {
    onlyReturnData: false,
  });
}

//客户对账列表
export function getCustomerReconciliation(data) {
  return http.post("/admin/finance/customerReconciliation", data);
}

//客户对账列表导出
export const exportCustomerReconciliationUrl = "/admin/finance/customerReconciliation/export";

//客户对账统计-合计列的数据
export function getCustomerReconciliationStatistics(data) {
  return http.post("/admin/finance/customerReconciliation/statistics", data);
}

//对账批次列表
export function getReconciliationBatch(data) {
  return http.post("/admin/finance/reconciliationBatch", data);
}

//下载批次列表
export function getFinanceDownloadBatch(data) {
  return http.post("/admin/finance/downloadBatch", data);
}

//重跑上月对账数据
export function getReloadUltimoAccountCheckData(data) {
  return http.post("/reconciliation/finance/reloadUltimoAccountCheckData", data, {
    onlyReturnData: false,
  });
}

//下载对账数据
export const getDownloadAccountCheckData = "/reconciliation/finance/downloadAccountCheckData";
//包干分摊批次的导出列表
export const exportContractBatchUrl = "/admin/contractBatch/export";

/**
 * 理赔定损认领银行流水列表
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function getTransactionList(data) {
  return http.post("/admin/loss/finance/bank/transaction/list", data);
}

/**
 * 理赔定损认领银行流水列表
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function getPreLoadSaveOrUpdateDropListData(data) {
  return http.post("/admin/loss/finance/bank/transaction/preLoadSaveOrUpdateDropListData", data);
}

/**
 * 理赔定损认领银行流水列表导出
 */
export const transactionListExportUrl = "/admin/loss/finance/bank/transaction/list/export";

/**
 * 理赔定损认领银行流水保存
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function saveTransactionList(data) {
  return http.post("/admin/loss/finance/bank/transaction/save", data);
}

/**
 * 理赔定损认领银行流水更新
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function updateTransactionList(data) {
  return http.post("/admin/loss/finance/bank/transaction/update", data);
}

/**
 * 理赔定损认领银行流水删除
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function deleteTransaction(data) {
  return http.post("/admin/loss/finance/bank/transaction/delete", data);
}

/**
 * 理赔定损认领银行流水保存
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function detailTransactionList(data) {
  return http.post("/admin/loss/finance/bank/transaction/detail", data);
}

// 理赔定损认领银行流水数据导入
export function importTransactionList(file) {
  let formData = new FormData();
  formData.append("file", file);

  return http.request({
    method: "post",
    url: "/admin/loss/finance/bank/transaction/import",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
    timeout: 18e5,
  });
}

// 理赔定损认领银行流水数据导入结果
export function importTransactionListResult() {
  return http.post("/admin/loss/finance/bank/transaction/importResult");
}

// 理赔定损认领回款 BEGIN
/**
 * 理赔定损认领回款列表
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function transactionClaimList(data) {
  return http.post("/admin/loss/finance/bank/transaction/claim/list", data);
}

/**
 * 理赔定损认领回款-详情
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function transactionClaimDetail(data) {
  return http.post("/admin/loss/finance/bank/transaction/claim/detail", data);
}

/**
 * 理赔定损认领回款-保存或者更新
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function transactionClaimSaveOrUpdate(data) {
  return http.post("/admin/loss/finance/bank/transaction/claim/saveOrUpdate", data);
}

/**
 * 理赔定损认领回款-保存或者更新——查询银行流水
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function saveOrUpdateListTransaction(data) {
  return http.post("/admin/loss/finance/bank/transaction/claim/saveOrUpdate/bank/transaction", data);
}

/**
 * 理赔定损认领回款-核销
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function transactionClaimHandleWriteOff(data) {
  return http.post("/admin/loss/finance/bank/transaction/claim/handleWriteOff", data);
}

/**
 * 理赔定损认领回款-核销备注变更
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function updateWriteOffRemark(data) {
  return http.post("/admin/loss/finance/bank/transaction/claim/changeWriteOffRemark", data);
}

/**
 * 理赔定损认领回款-反核销
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function transactionClaimHandleReversalWriteOff(data) {
  return http.post("/admin/loss/finance/bank/transaction/claim/handleReversalWriteOff", data);
}

/**
 * 理赔定损认领回款-单独反核销某笔认领金额
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function transactionClaimHandleSingleReversalWriteOff(data) {
  return http.post("/admin/loss/finance/bank/transaction/claim/handleSingleReversalWriteOff", data);
}

export const transactionClaimListExportUrl = "/admin/loss/finance/bank/transaction/claim/list/export";
// 理赔定损认领回款 END

// 回款认领提单核销明细 BEGIN
/**
 * 理赔定损认领回款列表
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function transactionClaimWriteOffListDetailList(data) {
  return http.post("/admin/loss/finance/bank/transaction/claim/detail/writeOffList", data);
}

export const transactionClaimWriteOffListDetailListExportUrl = "/admin/loss/finance/bank/transaction/claim/detail/writeOffList/export";
// 回款认领提单核销明细 END

// 回款认领明细 BEGIN
/**
 * 理赔定损认领回款列表
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function transactionClaimDetailList(data) {
  return http.post("/admin/loss/finance/bank/transaction/claim/detail/list", data);
}

export const transactionClaimDetailListExportUrl = "/admin/loss/finance/bank/transaction/claim/detail/list/export";
// 回款认领明细 END

//  理赔定损认领银行流水失败的导入列表导出
export const importTransactionListMessageExportUrl = "/admin/loss/finance/bank/transaction/importFailMessage/export";

// 理赔定损认领银行流水提单 BEGIN
/**
 * 理赔定损认领银行流水提单列表
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function transactionSubmitTaskList(data) {
  return http.post("/admin/loss/finance/bank/transaction/task/list", data);
}

/**
 * 理赔定损认领银行流水提单-批量提单
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function transactionBatchSubmitTask(data) {
  return http.post("/admin/loss/finance/bank/transaction/task/batchSubmitTask", data);
}

/**
 * 理赔定损认领银行流水提单-批量提单
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function transactionCompleteSubmitTaskList(data) {
  return http.post("/admin/loss/finance/bank/transaction/task/complete/list", data);
}

/**
 * 理赔定损认领银行流水提单-批量完成提单
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function transactionBatchCompleteSubmitTask(data) {
  return http.post("/admin/loss/finance/bank/transaction/task/complete/batchCompleteSubmitTask", data);
}

/**
 * 理赔定损认领银行流水提单-批量完成提单
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function batchImportCompleteSubmitTask(config) {
  return http.request(config);
}

/**
 * 理赔定损认领银行流水提单-批量撤销提单
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function transactionBatchRevokeSubmitTask(data) {
  return http.post("/admin/loss/finance/bank/transaction/task/complete/batchRevokeSubmitTask", data);
}

/**
 * 理赔定损认领银行流水提单-更新提单备注
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function batchSubmitTaskCompleteUpdateRemark(data) {
  return http.post("/admin/loss/finance/bank/transaction/task/complete/updateRemark", data);
}

/**
 * 理赔定损认领银行流水提单-批量撤销提单
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function batchSubmitTaskUpdateRemark(data) {
  return http.post("/admin/loss/finance/bank/transaction/task/updateRemark", data);
}

export const transactionSubmitTaskListExportUrl = "/admin/loss/finance/bank/transaction/task/list/export";

export const transactionCompleteSubmitTaskListExportUrl = "/admin/loss/finance/bank/transaction/task/complete/list/export";

// 理赔定损认领银行流水提单 END

/**
 * 定损单免赔变动信息表-列表查询
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function getDeductibleChangesList(data) {
  return http.post("/admin/receivable/claim/loss/exemption/change/list", data);
}

/**
 * 定损单免赔变动信息表-列表导出
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export const getDeductibleChangesExportUrl = "/admin/receivable/claim/loss/exemption/change/list/export";

/**
 * 应收账款索赔跟进-下拉数据
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function getClaimDamageSelect(data) {
  return http.post("/admin/receivable/compensation/detail/followup/preList", data);
}
/**
 * 应收账款索赔跟进-列表查询
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function getClaimDamageList(data) {
  return http.post("/admin/receivable/compensation/detail/followup/list", data);
}

/**
 * 应收账款索赔跟进-更新
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function getClaimDamageEdit(data) {
  return http.post("/admin/receivable/compensation/detail/followup/update", data);
}

/**
 * 应收账款索赔跟进-列表导出
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export const getClaimDamageUrl = "/admin/receivable/compensation/detail/followup/list/export";

/**
 * 应收账款索赔跟进-批量导入
 * @param {Object} file 参数封装
 * @returns 响应数据
 */
export function batchImportClaimDamageUrl(file) {
  let formData = new FormData();
  formData.append("file", file);
  return http.request({
    method: "post",
    url: "/admin/receivable/compensation/detail/followup/import",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
    timeout: 18e5,
  });
}

/**
 * 应收账款索赔跟进-批量导入结果
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function batchImportClaimDamageResult(data) {
  return http.post("/admin/receivable/compensation/detail/followup/importResult", data);
}
/**
 * 应收账款索赔跟进-批量导入失败结果导出
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export const batchImportClaimDamageFailUrl = "/admin/receivable/compensation/detail/followup/importResultFailed/export";

/**
 * 应收账款l理赔跟进明细-列表
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function getFollowUpDetailList(data) {
  return http.post("/admin/receivable/claim/detail/followup/list", data);
}

/**
 * 应收账款l理赔跟进明细-编辑
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function getFollowUpDetailEdit(data) {
  return http.post("/admin/receivable/claim/detail/followup/update", data);
}

/**
 * 应收账款l理赔跟进明细-下拉数据
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function getFollowUpDetailSelect(data) {
  return http.post("/admin/receivable/claim/detail/followup/preList", data);
}

/**
 * 应收账款理赔跟进明细-列表导出
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export const getFollowUpDetailUrl = "/admin/receivable/claim/detail/followup/list/export";

/**
 * 应收账款理赔跟进明细-批量导入
 * @param {Object} file 参数封装
 * @returns 响应数据
 */
export function batchImportFollowUpDetailApi(file) {
  let formData = new FormData();
  formData.append("file", file);
  return http.request({
    method: "post",
    url: "/admin/receivable/claim/detail/followup/import",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
    timeout: 18e5,
  });
}

/**
 * 应收账款理赔跟进明细-批量导入结果
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function batchImportFollowDetailResultApi(data) {
  return http.post("/admin/receivable/claim/detail/followup/importResult", data);
}

/**
 * 应收账款理赔跟进-批量导入失败结果导出
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export const batchImportFollowDetailFailApi = "/admin/receivable/claim/detail/followup/importResultFailed/export";

/**
 * 应收账款会计跟进-列表
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function getAccountFollowList(data) {
  return http.post("/admin/receivable/claim/accountant/team/followup/list", data);
}

/**
 * 应收账款会计跟进-编辑
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function getAccountFollowEdit(data) {
  return http.post("/admin/receivable/claim/accountant/team/followup/update", data);
}

/**
 * 应收账款会计跟进-下拉数据
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function getAccountFollowSelect(data) {
  return http.post("/admin/receivable/claim/accountant/team/followup/preList", data);
}

/**
 * 应收账款理赔会计跟进-列表导出
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export const getAccountFollowUrl = "/admin/receivable/claim/accountant/team/followup/list/export";

/**
 * 应收账款会计跟进-批量导入
 * @param {Object} file 参数封装
 * @returns 响应数据
 */
export function batchImportAccountFollowApi(file) {
  let formData = new FormData();
  formData.append("file", file);
  return http.request({
    method: "post",
    url: "/admin/receivable/claim/accountant/team/followup/import",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
    timeout: 18e5,
  });
}

/**
 * 应收账款会计跟进-批量导入结果
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function batchImportAccountFollowResultApi(data) {
  return http.post("/admin/receivable/claim/accountant/team/followup/importResult", data);
}

/**
 * 应收账款会计跟进-批量导入失败结果导出
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export const batchImportAccountFollowFailApi = "/admin/receivable/claim/accountant/team/followup/importResultFailed/export";

/**
 * 应收账款理赔结算组跟进-列表
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function getSettlementList(data) {
  return http.post("/admin/receivable/claim/settlements/team/followup/list", data);
}

/**
 * 应收账款理赔结算组跟进-编辑
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function getSettlementEdit(data) {
  return http.post("/admin/receivable/claim/settlements/team/followup/update", data);
}

/**
 * 应收账款理赔结算组跟进-下拉数据
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function getSettlementSelect(data) {
  return http.post("/admin/receivable/claim/settlements/team/followup/preList", data);
}

/**
 * 应收账款理赔结算组跟进-列表导出
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export const getSettlementUrl = "/admin/receivable/claim/settlements/team/followup/list/export";

/**
 * 应收账款理赔结算组跟进-批量导入
 * @param {Object} file 参数封装
 * @returns 响应数据
 */
export function batchImportSettlementApi(file) {
  let formData = new FormData();
  formData.append("file", file);
  return http.request({
    method: "post",
    url: "/admin/receivable/claim/settlements/team/followup/import",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
    timeout: 18e5,
  });
}

/**
 * 应收账款理赔结算组跟进-批量导入结果
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function batchImportSettlementResultApi(data) {
  return http.post("/admin/receivable/claim/settlements/team/followup/importResult", data);
}

/**
 * 应收账款理赔结算组跟进-批量导入失败结果导出
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export const batchImportSettlementFailApi = "/admin/receivable/claim/settlements/team/followup/importResultFailed/export";


/**
 * 应收账款理赔组跟进-列表
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function getClaimsFollowList(data) {
  return http.post("/admin/receivable/claim/claim/team/followup/list", data, {
    noShowWaiting: false,
  });
}

/**
 * 应收账款理赔组跟进-编辑
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function getClaimsFollowEdit(data) {
  return http.post("/admin/receivable/claim/claim/team/followup/update", data);
}

/**
 * 应收账款理赔组跟进-下拉数据
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function getClaimsFollowSelect(data) {
  return http.post("/admin/receivable/claim/claim/team/followup/preList", data);
}

/**
 * 应收账款理赔组跟进-列表导出
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export const getClaimsFollowUrl = "/admin/receivable/claim/claim/team/followup/list/export";

/**
 * 应收账款理赔组跟进-批量导入
 * @param {Object} file 参数封装
 * @returns 响应数据
 */
export function batchImportClaimsFollowApi(file) {
  let formData = new FormData();
  formData.append("file", file);
  return http.request({
    method: "post",
    url: "/admin/receivable/claim/claim/team/followup/import",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
    timeout: 18e5,
  });
}

/**
 * 应收账款理赔组跟进-批量导入结果
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export function batchImportClaimsFollowResultApi(data) {
  return http.post("/admin/receivable/claim/claim/team/followup/importResult", data);
}

/**
 * 应收账款理赔组跟进-批量导入失败结果导出
 * @param {Object} data 参数封装
 * @returns 响应数据
 */
export const batchImportClaimsFollowFailApi = "/admin/receivable/claim/claim/team/followup/importResultFailed/export";
