var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { attrs: { defaultPadding: false } },
    [
      _c(
        "el-form",
        {
          staticClass: "filter-form",
          attrs: { model: _vm.form, "label-width": "100px", size: "small" },
        },
        [
          _c(
            "div",
            { staticClass: "flex-x-between", staticStyle: { width: "100%" } },
            [
              _c(
                "div",
                { staticClass: "flex flex-shrink0" },
                [
                  _vm.isCenterCompany
                    ? _c(
                        "el-form-item",
                        [
                          _c("template", { slot: "label" }, [
                            _c(
                              "div",
                              [
                                _vm._v(" 预约门店 "),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "light",
                                      content:
                                        "预约门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)",
                                      placement: "bottom-start",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "iconfont grow-icon_doubt",
                                      staticStyle: { color: "#9a9a9a" },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("operateCompanyCascader", {
                            attrs: {
                              operateCompanyUrl: _vm.form.operateCompanyUrl,
                            },
                            model: {
                              value: _vm.form.operateCompanyId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "operateCompanyId", $$v)
                              },
                              expression: "form.operateCompanyId",
                            },
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "车牌号" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入车牌号", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.getDataList.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.form.carNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "carNumber", $$v)
                          },
                          expression: "form.carNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "预约时间" } },
                    [
                      _c("el-date-picker", {
                        staticClass: "w100",
                        attrs: {
                          type: "daterange",
                          "unlink-panels": "",
                          "range-separator": "至",
                          "start-placeholder": "起始时间",
                          "end-placeholder": "截止时间",
                          "value-format": "yyyy-MM-dd",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.dateRangeChange()
                          },
                        },
                        model: {
                          value: _vm.form.bookingTimes,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "bookingTimes", $$v)
                          },
                          expression: "form.bookingTimes",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态", prop: "status" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            filterable: "",
                            placeholder: "请选择状态",
                          },
                          model: {
                            value: _vm.form.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "status", $$v)
                            },
                            expression: "form.status",
                          },
                        },
                        _vm._l(_vm.statusEnums, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "el-form-item--small" }, [
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "auth",
                            rawName: "v-auth",
                            value: 692,
                            expression: "692",
                          },
                        ],
                        staticClass: "btn_search",
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.getDataList },
                      },
                      [_vm._v("查询")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn_search",
                        attrs: { size: "small" },
                        on: { click: _vm.empty },
                      },
                      [_vm._v("置空")]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showMore,
                  expression: "showMore",
                },
              ],
              staticClass: "flex-warp",
            },
            [void 0],
            2
          ),
        ]
      ),
      _c("div", { staticClass: "btns flex-x-between" }, [
        _c("div"),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "auth",
                    rawName: "v-auth",
                    value: 693,
                    expression: "693",
                  },
                ],
                staticClass: "blue",
                attrs: { size: "small", type: "text" },
                on: { click: _vm.downloadAllRecords },
              },
              [
                _vm._v("导出列表"),
                _c("i", {
                  staticClass: "iconfont grow-icon_daochu el-icon--right",
                }),
              ]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-table",
        {
          staticClass: "custom-table",
          staticStyle: { height: "calc(63vh)" },
          attrs: { data: _vm.tableData, border: "" },
        },
        [
          _c("el-table-column", {
            attrs: { type: "index", label: "序号", width: "60" },
          }),
          _c("el-table-column", {
            attrs: { width: "180", label: "操作", prop: "operationAction" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    row.status === 0
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "auth",
                                rawName: "v-auth",
                                value: 694,
                                expression: "694",
                              },
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.completeBooking(row)
                              },
                            },
                          },
                          [_vm._v("预约开单")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "220", label: "预约门店", prop: "companyName" },
          }),
          _c("el-table-column", {
            attrs: { width: "180", label: "预约日期", prop: "bookingDate" },
          }),
          _c("el-table-column", {
            attrs: {
              width: "180",
              label: "预约时间段",
              prop: "bookedTimePhase",
            },
          }),
          _c("el-table-column", {
            attrs: { width: "180", label: "预约服务", prop: "serviceTypeDesc" },
          }),
          _c("el-table-column", {
            attrs: { width: "180", label: "车牌号", prop: "carNumber" },
          }),
          _c("el-table-column", {
            attrs: {
              width: "180",
              label: "车型",
              prop: "carModel",
              "class-name": "carModelPreLine",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: { width: "180", label: "车队", prop: "clientName" },
          }),
          _c("el-table-column", {
            attrs: { width: "180", label: "送修人", prop: "customerUserName" },
          }),
          _c("el-table-column", {
            attrs: { width: "180", label: "送修人电话", prop: "customerPhone" },
          }),
          _c("el-table-column", {
            attrs: { width: "180", label: "创建时间", prop: "dateCreated" },
          }),
          _c("el-table-column", {
            attrs: { width: "180", label: "车单号", prop: "billCode" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "blue pointer",
                        on: {
                          click: function ($event) {
                            return _vm.goDetail(row.billId || 0)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(row.billCode) + " ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "180", label: "送修人备注", prop: "repairRemark" },
          }),
          _c("el-table-column", {
            attrs: { width: "180", label: "服务备注", prop: "serviceRemark" },
          }),
          _c("el-table-column", {
            attrs: { width: "180", label: "渠道", prop: "channelCode" },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("pagination", {
            attrs: { total: _vm.total },
            on: { pageChange: _vm.changePage },
          }),
        ],
        1
      ),
      _vm.showCompleteBookingDialog
        ? _c("completeBookingDialog", {
            attrs: {
              visibility: _vm.showCompleteBookingDialog,
              id: _vm.completeBookingId,
            },
            on: {
              "update:visibility": function ($event) {
                _vm.showCompleteBookingDialog = $event
              },
              save: function ($event) {
                return _vm.getDataList(false)
              },
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }