import { MessageBox } from 'element-ui';
import axios from '@/service/axios';

export function checkVersion() {
  axios
    .get(
      location.origin +
        location.pathname +
        `version.json?t=${new Date().getTime()}`,
      {
        noHandleError: true,
        noShowWaiting: true,
      }
    )
    .then((res) => {
      console.log('版本对比：', process.env.VUE_APP_VERSION, res.data.version);
      if (res.data.version !== process.env.VUE_APP_VERSION) {
        MessageBox.alert('当前页面版本已更新，请刷新后使用', '提示', {
          type: 'error',
          showClose: false,
          confirmButtonText: '确定刷新',
        }).then(() => {
          const { origin, pathname, hash } = window.location;
          const url = `${origin}${pathname}?t=${new Date().getTime()}${hash}`;
          // window.location.href = url;
          window.location.replace(url);
          // window.location.reload(true);
        });
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

window.checkVersion = checkVersion;

export default function () {
  if (window.checkVersionTimer) {
    clearInterval(window.checkVersionTimer);
  }
  window.checkVersionTimer = setInterval(() => {
    checkVersion();
  }, 5 * 60 * 1000); // 5分钟检测一次
  // 首次加载触发一次
  checkVersion();
}
