var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { attrs: { paddingTop: "" } },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-width": "150px",
            size: "small",
          },
        },
        [
          !_vm.edit
            ? _c(
                "el-row",
                { staticStyle: { "margin-left": "-45px" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "选择已有客户", prop: "clientName" },
                        },
                        [
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "bottom",
                                width: "500",
                                trigger: "click",
                              },
                              model: {
                                value: _vm.visible,
                                callback: function ($$v) {
                                  _vm.visible = $$v
                                },
                                expression: "visible",
                              },
                            },
                            [
                              _c(
                                "el-table",
                                {
                                  staticClass: "customer-table",
                                  attrs: {
                                    data: _vm.clientList,
                                    "max-height": 400,
                                    border: "",
                                  },
                                  on: {
                                    "current-change": _vm.handleCurrentChange,
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      width: "100",
                                      property: "clientName",
                                      label: "客户名称",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      width: "100",
                                      property: "isAgreementClient",
                                      label: "协议客户",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ row }) {
                                            return [
                                              row.isAgreementClient == 1
                                                ? _c("span", [
                                                    _vm._v("协议客户"),
                                                    row.beContract
                                                      ? _c("span", [
                                                          _vm._v("（包干）"),
                                                        ])
                                                      : _vm._e(),
                                                  ])
                                                : _c("span"),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2854010971
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      width: "100",
                                      property: "companyFullName",
                                      label: "公司全称",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      "min-width": "70",
                                      property: "clientLevel",
                                      label: "等级",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.hasMore
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "load-more-btn",
                                      on: {
                                        click: function ($event) {
                                          return _vm.getCustomerData(
                                            false,
                                            true
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" 加载更多数据 ")]
                                  )
                                : _vm._e(),
                              _c("el-input", {
                                staticStyle: { width: "260px" },
                                attrs: {
                                  slot: "reference",
                                  size: "small",
                                  placeholder: "请输入客户名称搜索",
                                  clearable: "",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.getCustomerData(true)
                                  },
                                  clear: _vm.handleClear,
                                },
                                slot: "reference",
                                model: {
                                  value: _vm.form.clientName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "clientName", $$v)
                                  },
                                  expression: "form.clientName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "auth",
                                  rawName: "v-auth",
                                  value: 61,
                                  expression: "61",
                                },
                              ],
                              staticStyle: { margin: "0 15px" },
                            },
                            [_vm._v("或")]
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "auth",
                                  rawName: "v-auth",
                                  value: 61,
                                  expression: "61",
                                },
                              ],
                              staticClass: "orange text_operation",
                              on: {
                                click: function ($event) {
                                  _vm.showAddCusterDialog = true
                                },
                              },
                            },
                            [_vm._v("新增客户")]
                          ),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "margin-left": "30px",
                                color: "#999",
                              },
                            },
                            [_vm._v("选定并保存成功后不能修改，请谨慎选择")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-row",
            { staticStyle: { "margin-left": "-45px" } },
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.form.clientId,
                        expression: "form.clientId",
                      },
                    ],
                    staticClass: "client",
                  },
                  [
                    _c("div", { staticClass: "client-info" }, [
                      _c("div", [
                        _c("i", {
                          staticClass: "iconfont icon_grade",
                          class:
                            _vm.levelIconsOther[_vm.clientInfo.clientLevel],
                          attrs: { title: _vm.clientInfo.clientLevel || "" },
                        }),
                        _c("span", [_vm._v(_vm._s(_vm.clientInfo.clientName))]),
                        _vm.clientInfo.companyFullName
                          ? _c("span", [_vm._v("/")])
                          : _vm._e(),
                        _c("span", [
                          _vm._v(_vm._s(_vm.clientInfo.companyFullName)),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ],
            1
          ),
          _c("el-divider"),
          _c(
            "el-tabs",
            {
              staticStyle: { "margin-top": "30px" },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "协议基础信息", name: "first" } },
                [
                  _c(
                    "p",
                    {
                      staticClass: "orange",
                      staticStyle: {
                        margin: "12px 0px 12px 0px",
                        "font-size": "12px",
                      },
                    },
                    [
                      _vm._v(
                        " 注：协议编号、有效期、协议客户一但创建不可修改，请注意操作。 "
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticStyle: { "margin-left": "-68px" } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "协议编号",
                                    prop: "contractNumber",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      disabled: !!_vm.edit,
                                      placeholder: "请输入协议编号",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.form.contractNumber,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "contractNumber",
                                          $$v
                                        )
                                      },
                                      expression: "form.contractNumber",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "签约门店",
                                    prop: "signCompanyId",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        filterable: "",
                                        clearable: "",
                                        remote: "",
                                        placeholder: "请输入门店名称搜索",
                                        "remote-method": _vm.searchStore,
                                        loading: _vm.searchStoreLoading,
                                      },
                                      model: {
                                        value: _vm.form.signCompanyId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "signCompanyId",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression: "form.signCompanyId",
                                      },
                                    },
                                    _vm._l(_vm.storeList, function (item) {
                                      return _c("el-option", {
                                        key: item.companyId,
                                        attrs: {
                                          label: item.companyName,
                                          value: item.companyId,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "invoiceCompanyId" } },
                                [
                                  _c(
                                    "template",
                                    { slot: "label" },
                                    [
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticStyle: {
                                            "margin-right": "5px",
                                          },
                                          attrs: {
                                            effect: "light",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "rows-tip",
                                              attrs: { slot: "content" },
                                              slot: "content",
                                            },
                                            [
                                              _vm._v(
                                                " 客户协议的开票主体，即为协议客户的应收账款的回款管理门店 "
                                              ),
                                            ]
                                          ),
                                          _c("i", {
                                            staticClass:
                                              "iconfont grow-icon_doubt",
                                            staticStyle: { color: "#9a9a9a" },
                                          }),
                                        ]
                                      ),
                                      _vm._v(" 开票主体 "),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        filterable: "",
                                        clearable: "",
                                        remote: "",
                                        placeholder: "搜索开票主体名称",
                                        "remote-method": _vm.searchInvoice,
                                        loading: _vm.searchInvoiceLoading,
                                      },
                                      model: {
                                        value: _vm.form.invoiceCompanyId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "invoiceCompanyId",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression: "form.invoiceCompanyId",
                                      },
                                    },
                                    _vm._l(_vm.invoiceList, function (item) {
                                      return _c("el-option", {
                                        key: item.companyId,
                                        attrs: {
                                          label: item.invoicingEntity,
                                          value: item.companyId,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "回款账期",
                                    prop: "paymentPeriod",
                                  },
                                },
                                [
                                  _c(
                                    "template",
                                    { slot: "label" },
                                    [
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticStyle: {
                                            "margin-right": "5px",
                                          },
                                          attrs: {
                                            effect: "light",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "rows-tip",
                                              attrs: { slot: "content" },
                                              slot: "content",
                                            },
                                            [
                                              _vm._v(
                                                " 消费结账后第" +
                                                  _vm._s(
                                                    _vm.form.paymentPeriod
                                                  ) +
                                                  "个自然月月底前回款 "
                                              ),
                                            ]
                                          ),
                                          _c("i", {
                                            staticClass:
                                              "iconfont grow-icon_doubt",
                                            staticStyle: { color: "#9a9a9a" },
                                          }),
                                        ]
                                      ),
                                      _c("span", [_vm._v("回款账期")]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "number-input",
                                    {
                                      attrs: { decimalDigits: "2" },
                                      model: {
                                        value: _vm.form.paymentPeriod,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "paymentPeriod",
                                            $$v
                                          )
                                        },
                                        expression: "form.paymentPeriod",
                                      },
                                    },
                                    [
                                      _c("template", { slot: "append" }, [
                                        _vm._v("月 "),
                                      ]),
                                    ],
                                    2
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "生效日期",
                                    prop: "startDate",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      type: "date",
                                      placeholder: "选择日期",
                                      format: "yyyy-MM-dd",
                                      "value-format": "yyyy-MM-dd",
                                      disabled: !!_vm.edit,
                                    },
                                    on: { change: _vm.changeDate },
                                    model: {
                                      value: _vm.form.startDate,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "startDate", $$v)
                                      },
                                      expression: "form.startDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "有效期至", prop: "endDate" },
                                },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      type: "date",
                                      placeholder: "请选择截止日期",
                                      format: "yyyy-MM-dd",
                                      "value-format": "yyyy-MM-dd",
                                      "picker-options": _vm.endDateOption,
                                    },
                                    on: { change: _vm.changeDate },
                                    model: {
                                      value: _vm.form.endDate,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "endDate", $$v)
                                      },
                                      expression: "form.endDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "开户行", prop: "bank" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.form.bank,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "bank", $$v)
                                      },
                                      expression: "form.bank",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "银行账号", prop: "bankNum" },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.form.bankNum,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "bankNum", $$v)
                                      },
                                      expression: "form.bankNum",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "备注", prop: "remark" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      autosize: { minRows: 2, maxRows: 4 },
                                      placeholder: "请输入",
                                      maxlength: "100",
                                      "show-word-limit": "",
                                    },
                                    model: {
                                      value: _vm.form.remark,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "remark", $$v)
                                      },
                                      expression: "form.remark",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("p", { staticStyle: { margin: "20px 0 10px 0" } }, [
                    _vm._v("结算方式说明"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "settlement" },
                    [
                      _c(
                        "el-row",
                        { staticClass: "first_row" },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "自费", prop: "selfPaying" },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入",
                                      maxlength: "140",
                                      clearable: "",
                                    },
                                    model: {
                                      value:
                                        _vm.settlementDescription.selfPaying,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.settlementDescription,
                                          "selfPaying",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "settlementDescription.selfPaying",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "包干", prop: "contractFor" },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入",
                                      maxlength: "140",
                                      clearable: "",
                                    },
                                    model: {
                                      value:
                                        _vm.settlementDescription.contractFor,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.settlementDescription,
                                          "contractFor",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "settlementDescription.contractFor",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "事故返点",
                                    prop: "accidentReturn",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入",
                                      maxlength: "140",
                                      clearable: "",
                                    },
                                    model: {
                                      value:
                                        _vm.settlementDescription
                                          .accidentReturn,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.settlementDescription,
                                          "accidentReturn",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "settlementDescription.accidentReturn",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "保险理赔",
                                    prop: "insuranceClaims",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入",
                                      maxlength: "140",
                                      clearable: "",
                                    },
                                    model: {
                                      value:
                                        _vm.settlementDescription
                                          .insuranceClaims,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.settlementDescription,
                                          "insuranceClaims",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "settlementDescription.insuranceClaims",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "two_row" },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "年审",
                                    prop: "annualReview",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入",
                                      maxlength: "140",
                                      clearable: "",
                                    },
                                    model: {
                                      value:
                                        _vm.settlementDescription.annualReview,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.settlementDescription,
                                          "annualReview",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "settlementDescription.annualReview",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "保险手续费",
                                    prop: "insuranceHandlingFees",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入",
                                      maxlength: "140",
                                      clearable: "",
                                    },
                                    model: {
                                      value:
                                        _vm.settlementDescription
                                          .insuranceHandlingFees,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.settlementDescription,
                                          "insuranceHandlingFees",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "settlementDescription.insuranceHandlingFees",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("p", { staticStyle: { margin: "20px 0 10px 0" } }, [
                    _vm._v("自费挂账设置"),
                  ]),
                  _c("el-switch", {
                    model: {
                      value: _vm.form.beHang,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "beHang", $$v)
                      },
                      expression: "form.beHang",
                    },
                  }),
                  _c("span", { staticClass: "is-responsibility" }, [
                    _vm._v(_vm._s(_vm.form.beHang ? "允许挂账" : "不允许挂账")),
                  ]),
                  _c("p", { staticStyle: { margin: "20px 0 10px 0" } }, [
                    _vm._v("合同要求"),
                  ]),
                  _c("div", { staticClass: "container" }, [
                    _c("table", { staticClass: "border-table" }, [
                      _c("thead", [
                        _c("tr", [
                          _c("th", [_vm._v("业务类型")]),
                          _c("th", [_vm._v("自费")]),
                          _c("th", [_vm._v("退还车")]),
                          _c("th", [_vm._v("包干")]),
                          _c("th", [_vm._v("理赔")]),
                        ]),
                      ]),
                      _c("tbody", [
                        _c("tr", [
                          _c("td", [_vm._v("时效要求")]),
                          _c(
                            "td",
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  autosize: "",
                                  rows: 1,
                                  placeholder: "请输入",
                                  maxlength: "100",
                                },
                                model: {
                                  value: _vm.selfPaying.effectiveAsk,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.selfPaying,
                                      "effectiveAsk",
                                      $$v
                                    )
                                  },
                                  expression: "selfPaying.effectiveAsk",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  autosize: "",
                                  rows: 1,
                                  placeholder: "请输入",
                                  maxlength: "100",
                                },
                                model: {
                                  value: _vm.returnCar.effectiveAsk,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.returnCar, "effectiveAsk", $$v)
                                  },
                                  expression: "returnCar.effectiveAsk",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  autosize: "",
                                  rows: 1,
                                  placeholder: "请输入",
                                  maxlength: "100",
                                },
                                model: {
                                  value: _vm.contractFor.effectiveAsk,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.contractFor,
                                      "effectiveAsk",
                                      $$v
                                    )
                                  },
                                  expression: "contractFor.effectiveAsk",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  autosize: "",
                                  rows: 1,
                                  placeholder: "请输入",
                                  maxlength: "100",
                                },
                                model: {
                                  value: _vm.claimSettlement.effectiveAsk,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.claimSettlement,
                                      "effectiveAsk",
                                      $$v
                                    )
                                  },
                                  expression: "claimSettlement.effectiveAsk",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("质量要求")]),
                          _c(
                            "td",
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  autosize: "",
                                  rows: 1,
                                  placeholder: "请输入",
                                  maxlength: "100",
                                },
                                model: {
                                  value: _vm.selfPaying.qualityAsk,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.selfPaying, "qualityAsk", $$v)
                                  },
                                  expression: "selfPaying.qualityAsk",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  autosize: "",
                                  rows: 1,
                                  placeholder: "请输入",
                                  maxlength: "100",
                                },
                                model: {
                                  value: _vm.returnCar.qualityAsk,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.returnCar, "qualityAsk", $$v)
                                  },
                                  expression: "returnCar.qualityAsk",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  autosize: "",
                                  rows: 1,
                                  placeholder: "请输入",
                                  maxlength: "100",
                                },
                                model: {
                                  value: _vm.contractFor.qualityAsk,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.contractFor, "qualityAsk", $$v)
                                  },
                                  expression: "contractFor.qualityAsk",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  autosize: "",
                                  rows: 1,
                                  placeholder: "请输入",
                                  maxlength: "100",
                                },
                                model: {
                                  value: _vm.claimSettlement.qualityAsk,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.claimSettlement,
                                      "qualityAsk",
                                      $$v
                                    )
                                  },
                                  expression: "claimSettlement.qualityAsk",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-left": "-80px" },
                      attrs: { label: "协议图片" },
                    },
                    [
                      _c("p", { staticClass: "img-note" }, [
                        _vm._v(
                          " （最多可上传10张图片，建议每张图片大小不超过1M） "
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "car-imgs",
                          staticStyle: { "margin-left": "-80px" },
                        },
                        [
                          _c("upload-file-list", {
                            model: {
                              value: _vm.form.pics,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "pics", $$v)
                              },
                              expression: "form.pics",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "自费项目折扣", name: "second" } },
                [
                  _c("div", { staticClass: "flex note" }, [
                    _c("p", [
                      _vm._v("项目折扣说明：1、仅适用自费、包干业务报价；"),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "flex-x-between",
                      staticStyle: { "margin-bottom": "8px" },
                    },
                    [
                      _c("div"),
                      _vm.edit
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "small" },
                                  on: {
                                    click: function ($event) {
                                      _vm.serviceImportDialogVisibility = true
                                    },
                                  },
                                },
                                [_vm._v("导入服务价")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "small" },
                                  on: {
                                    click: function ($event) {
                                      _vm.goodsImportDialogVisibility = true
                                    },
                                  },
                                },
                                [_vm._v("导入商品价")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                  _vm.edit
                    ? _c("agreement-price-list", {
                        ref: "agreementPriceListDOM",
                        attrs: { id: _vm.$route.query.id, showTips: false },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "包干业务设置", name: "third" } },
                [
                  _c("div", { staticClass: "contract-set" }, [
                    _c("p", { staticClass: "undertake-title" }, [
                      _vm._v(
                        " 包干金额分摊规则说明（仅适用“固定金额”、“固定单价”模式）： "
                      ),
                    ]),
                    _c("div", { staticClass: "tip-block" }, [
                      _c("p", { staticClass: "tip-title" }, [
                        _vm._v("1、结账当月预分摊制："),
                      ]),
                      _c("p", { staticClass: "tip-line" }, [
                        _vm._v("M日预分摊金额 = 包干总额 ÷ 当月天数 × M"),
                      ]),
                      _c("p", { staticClass: "tip-line" }, [
                        _vm._v(
                          " 单笔分摊金额=单笔结算金额 ×（M日预分摊金额÷M日累计结算金额） "
                        ),
                      ]),
                      _c("p", { staticClass: "tip-line" }, [
                        _vm._v(
                          " 单项分摊金额=单项结算总额 ×（单笔分摊金额÷单笔结算金额） "
                        ),
                      ]),
                      _c("p", { staticClass: "tip-line" }, [
                        _vm._v(
                          " 注：仅M日累计结算金额大于M日预分摊金额时才进行预分摊 "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "tip-block" }, [
                      _c("p", { staticClass: "tip-title" }, [
                        _vm._v("2、结账次月开始正式分摊制："),
                      ]),
                      _c("p", { staticClass: "tip-line" }, [
                        _vm._v(
                          " 结账次月，自动跑批，形成正式分摊，本月分摊金额完结，次月结算的单据纳入下个月的包干分摊金额计算。 "
                        ),
                      ]),
                      _c("p", { staticClass: "tip-line" }, [
                        _vm._v("注：仅累计结算金额大于包干总额时才进行分摊"),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "flex-y-center",
                      staticStyle: { height: "32px", margin: "30px 0" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          !["overdue"].includes(_vm.form.status)
                            ? _c("el-switch", {
                                on: { change: _vm.beContractChange },
                                model: {
                                  value: _vm.form.beContract,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "beContract", $$v)
                                  },
                                  expression: "form.beContract",
                                },
                              })
                            : _vm._e(),
                          _c(
                            "p",
                            {
                              staticStyle: {
                                "margin-left": "15px",
                                color: "#999",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.form.beContract
                                      ? "已启用包干业务"
                                      : "未启用包干业务"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.form.beContract,
                          expression: "form.beContract",
                        },
                      ],
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: { input: _vm.changeUndertakeType },
                          model: {
                            value: _vm.form.undertakeType,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "undertakeType", $$v)
                            },
                            expression: "form.undertakeType",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              staticStyle: {
                                "font-size": "14px",
                                color: "#242526",
                              },
                              attrs: { label: 0 },
                            },
                            [_vm._v("固定金额")]
                          ),
                          _c(
                            "el-radio",
                            {
                              staticStyle: {
                                "font-size": "14px",
                                color: "#242526",
                              },
                              attrs: { label: 1 },
                            },
                            [_vm._v("固定单价")]
                          ),
                          _c(
                            "el-radio",
                            {
                              staticStyle: {
                                "font-size": "14px",
                                color: "#242526",
                              },
                              attrs: { label: 2 },
                            },
                            [_vm._v("单据金额比例")]
                          ),
                          _c(
                            "el-radio",
                            {
                              staticStyle: {
                                "font-size": "14px",
                                color: "#242526",
                              },
                              attrs: { label: 3 },
                            },
                            [_vm._v("理赔返点比例")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.form.beContract,
                          expression: "form.beContract",
                        },
                      ],
                      staticClass: "undertake-type",
                    },
                    [
                      _vm.form.undertakeType == 0
                        ? _c("div", [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#333",
                                  "font-size": "14px",
                                },
                              },
                              [_vm._v("包干金额每月固定，不限车辆台数")]
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.form.undertakeType == 0,
                                    expression: "form.undertakeType == 0",
                                  },
                                ],
                                staticClass: "flex-x-between",
                                staticStyle: { margin: "15px 0px" },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "input-line" },
                                  [
                                    _vm._v(" 首月包干金额： "),
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "请输入",
                                        size: "small",
                                        min: 0,
                                        oninput: "if(value<0)value=0",
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.filterNonDigit(
                                            $event,
                                            "firstMonthPayment"
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.form.firstMonthPayment,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "firstMonthPayment",
                                            $$v
                                          )
                                        },
                                        expression: "form.firstMonthPayment",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "input-line" },
                                  [
                                    _vm._v(" 中间月包干金额： "),
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "请输入",
                                        size: "small",
                                        min: 0,
                                        oninput: "if(value<0)value=0",
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.filterNonDigit(
                                            $event,
                                            "middMonthPayment"
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.form.middMonthPayment,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "middMonthPayment",
                                            $$v
                                          )
                                        },
                                        expression: "form.middMonthPayment",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "input-line" },
                                  [
                                    _vm._v(" 末月包干金额： "),
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "请输入",
                                        size: "small",
                                        min: 0,
                                        oninput: "if(value<0)value=0",
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.filterNonDigit(
                                            $event,
                                            "endMonthPayment"
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.form.endMonthPayment,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "endMonthPayment",
                                            $$v
                                          )
                                        },
                                        expression: "form.endMonthPayment",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm.form.undertakeType == 1
                        ? _c("div", [
                            _c("div", [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    color: "#333",
                                    "font-size": "14px",
                                  },
                                },
                                [
                                  _vm._v(
                                    "包干金额每月非固定，每月包干金额 = 每月包干车辆台数 x包干单价"
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticStyle: { "margin-top": "15px" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "input-line" },
                                    [
                                      _vm._v(" 包干单价(元)： "),
                                      _c("number-input", {
                                        attrs: {
                                          placeholder: "请输入",
                                          size: "small",
                                        },
                                        on: { input: _vm.inputMoney },
                                        model: {
                                          value: _vm.form.moneyAmout,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "moneyAmout",
                                              $$v
                                            )
                                          },
                                          expression: "form.moneyAmout",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "div",
                              { staticStyle: { "margin-top": "30px" } },
                              [
                                _c(
                                  "el-table",
                                  {
                                    key: _vm.refreshKey,
                                    attrs: {
                                      data: _vm.form.agreementUndertake,
                                      border: "",
                                    },
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: {
                                        type: "index",
                                        label: "序号",
                                        width: "100",
                                      },
                                    }),
                                    _c("el-table-column", {
                                      attrs: { label: "月份" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ row }) {
                                              return [_vm._v(_vm._s(row.date))]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1952822987
                                      ),
                                    }),
                                    _c("el-table-column", {
                                      attrs: { label: "包干车辆（台）" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ row }) {
                                              return [
                                                _c("number-input", {
                                                  attrs: {
                                                    placeholder: "请输入",
                                                    size: "small",
                                                    enabledDecimals: false,
                                                  },
                                                  on: {
                                                    input: (val) => {
                                                      _vm.changeCarNumber(
                                                        val,
                                                        row
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: row.carAmount,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        row,
                                                        "carAmount",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "row.carAmount",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1673230495
                                      ),
                                    }),
                                    _c("el-table-column", {
                                      attrs: { label: "包干单价" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ row }) {
                                              return [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("toThousands")(
                                                      row.moneyAmout
                                                    )
                                                  )
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3758519650
                                      ),
                                    }),
                                    _c("el-table-column", {
                                      attrs: { label: "包干金额" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ row }) {
                                              return [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("toThousands")(
                                                      _vm.$lodash.multiply(
                                                        row.moneyAmout,
                                                        row.carAmount
                                                      )
                                                    )
                                                  )
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        4050124332
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _vm.form.undertakeType == 2
                        ? _c("div", [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#333",
                                  "font-size": "14px",
                                },
                              },
                              [
                                _vm._v(
                                  "包干金额每月按单据金额百分比，每月包干金额=每月包干单据金额 x 比例"
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "input-line",
                                staticStyle: { "margin-top": "16px" },
                              },
                              [
                                _vm._v(" 比例（%）： "),
                                _c("number-input", {
                                  attrs: {
                                    placeholder: "请输入0-100的比例值",
                                    size: "small",
                                    enabledDecimals: false,
                                    max: 100,
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.contractRatio,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "contractRatio", $$v)
                                    },
                                    expression: "form.contractRatio",
                                  },
                                }),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _vm.form.undertakeType == 3
                        ? _c("div", [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#333",
                                  "font-size": "14px",
                                },
                              },
                              [
                                _vm._v(
                                  "包干金额 = 当月已结账理赔单据金额 * 比例"
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "input-line",
                                staticStyle: { "margin-top": "16px" },
                              },
                              [
                                _vm._v(" 比例（%）： "),
                                _c("number-input", {
                                  attrs: {
                                    placeholder: "请输入0-100的比例值",
                                    size: "small",
                                    enabledDecimals: false,
                                    max: 100,
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.returnPoint,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "returnPoint", $$v)
                                    },
                                    expression: "form.returnPoint",
                                  },
                                }),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "car-scope" }, [
                        _c("div", { staticClass: "car-scope-title" }, [
                          _vm._v("车辆范围"),
                        ]),
                        _c("p", { staticClass: "car-scope-text" }, [
                          _vm._v(
                            " 请选择该客户需要进行包干业务的车辆，在范围内的车辆才允许包干开单 "
                          ),
                        ]),
                        _c(
                          "div",
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.form.vehicleRange,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "vehicleRange", $$v)
                                  },
                                  expression: "form.vehicleRange",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 0 } }, [
                                  _vm._v("全部车辆"),
                                ]),
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("部分车辆"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.form.vehicleRange == 1
                          ? _c("div", [
                              _c(
                                "div",
                                { staticStyle: { margin: "30px 0px 10px" } },
                                [
                                  _c("span", [_vm._v("导入模式：")]),
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value: _vm.opType,
                                        callback: function ($$v) {
                                          _vm.opType = $$v
                                        },
                                        expression: "opType",
                                      },
                                    },
                                    [
                                      _c("el-radio", { attrs: { label: 0 } }, [
                                        _vm._v("追加"),
                                      ]),
                                      _c("el-radio", { attrs: { label: 1 } }, [
                                        _vm._v("覆盖"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "car-scope-text" },
                                [
                                  _c("span", [_vm._v("导入文件：")]),
                                  !_vm.carScopeImportFile
                                    ? [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "primary",
                                              size: "small",
                                            },
                                            on: { click: _vm.chooseFile },
                                          },
                                          [_vm._v("选择文件")]
                                        ),
                                      ]
                                    : [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.carScopeImportFile.name)
                                          ),
                                        ]),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "blue pointer el-icon--right",
                                            on: { click: _vm.chooseFile },
                                          },
                                          [_vm._v("更换")]
                                        ),
                                        _c(
                                          "p",
                                          {
                                            staticStyle: {
                                              "margin-top": "8px",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "orange" },
                                              [
                                                _vm._v(
                                                  "注意：仅按照最新上传的表修改车辆包干范围，请上传全部需要包干的车辆"
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                  _c(
                                    "p",
                                    { staticStyle: { "margin-top": "16px" } },
                                    [
                                      _vm._v(" 仅支持xlsx格式文件，点击 "),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "blue pointer",
                                          staticStyle: {
                                            "font-size": "14px",
                                            "text-decoration": "none",
                                          },
                                          attrs: {
                                            href: "https://growxny-dev-center.oss-cn-guangzhou.aliyuncs.com/template/20230922/%E5%8C%85%E5%B9%B2%E8%BD%A6%E8%BE%86%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx",
                                            download: "",
                                          },
                                        },
                                        [
                                          _vm._v("下载导入模板 "),
                                          _c("i", {
                                            staticClass:
                                              "iconfont grow-icon_download",
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                2
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  ),
                ]
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "协议适用门店", name: "fourth" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "flex",
                      staticStyle: { "font-size": "14px" },
                    },
                    [
                      _c("p", { staticStyle: { color: "#666" } }, [
                        _vm._v("适用门店说明"),
                      ]),
                      _c(
                        "p",
                        {
                          staticStyle: { "margin-left": "15px", color: "#999" },
                        },
                        [
                          _vm._v(
                            " 分业务设置适用门店后，协议客户的车辆只能去到适用门店进行对应业务的接待开单。 "
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "flex item-block" },
                    [
                      _c("h6", [_vm._v("自费业务:")]),
                      _c(
                        "div",
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 0 },
                              model: {
                                value: _vm.form.hasSelfPaying,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "hasSelfPaying", $$v)
                                },
                                expression: "form.hasSelfPaying",
                              },
                            },
                            [
                              _c("span", { staticStyle: { color: "#333" } }, [
                                _vm._v("无限制，全部门店"),
                              ]),
                            ]
                          ),
                          _c(
                            "el-radio",
                            {
                              staticStyle: { "margin-left": "30px" },
                              attrs: { label: 1 },
                              model: {
                                value: _vm.form.hasSelfPaying,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "hasSelfPaying", $$v)
                                },
                                expression: "form.hasSelfPaying",
                              },
                            },
                            [
                              _c("span", { staticStyle: { color: "#333" } }, [
                                _vm._v("有限制，部分门店"),
                              ]),
                            ]
                          ),
                          _c(
                            "p",
                            {
                              staticClass: "orange",
                              staticStyle: {
                                "margin-top": "12px",
                                "font-size": "12px",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.form.hasSelfPaying == 0
                                      ? "（已选择全部门店，适用门店不固定，后续新增的门店将自动适用）"
                                      : "（已选择部分门店，适用门店是固定的，除非重新设置）"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm.form.hasSelfPaying == 1
                        ? _c(
                            "el-button",
                            {
                              staticClass: "btn_insert",
                              attrs: { size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.addStore("selfPaying")
                                },
                              },
                            },
                            [_vm._v("选择门店")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "table-box" },
                    [
                      _vm.form.hasSelfPaying == 1
                        ? _c(
                            "el-table",
                            {
                              attrs: { border: "", data: _vm.form.selfPaying },
                            },
                            [
                              _c("el-table-column", {
                                attrs: { label: "操作", width: "80" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ $index }) {
                                        return [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "text",
                                                size: "small",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteRow(
                                                    _vm.form.selfPaying,
                                                    $index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "iconfont grow-icon_delete3",
                                                staticStyle: {
                                                  color: "#ff9200",
                                                  "font-size": "22px",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3957389157
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  type: "index",
                                  label: "序号",
                                  width: "60",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  width: "120",
                                  prop: "companyId",
                                  label: "车店编号",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "companyName",
                                  label: "车店名称",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  width: "100",
                                  prop: "type",
                                  label: "连锁类型",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("dict")(
                                                scope.row.chainCompanyType,
                                                "chainCompanyType"
                                              )
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3868694802
                                ),
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "flex item-block" },
                    [
                      _c("h6", [_vm._v("包干业务:")]),
                      _c(
                        "div",
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 0 },
                              model: {
                                value: _vm.form.hasContractFor,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "hasContractFor", $$v)
                                },
                                expression: "form.hasContractFor",
                              },
                            },
                            [
                              _c("span", { staticStyle: { color: "#333" } }, [
                                _vm._v("无限制，全部门店"),
                              ]),
                            ]
                          ),
                          _c(
                            "el-radio",
                            {
                              staticStyle: { "margin-left": "30px" },
                              attrs: { label: 1 },
                              model: {
                                value: _vm.form.hasContractFor,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "hasContractFor", $$v)
                                },
                                expression: "form.hasContractFor",
                              },
                            },
                            [
                              _c("span", { staticStyle: { color: "#333" } }, [
                                _vm._v("有限制，部分门店"),
                              ]),
                            ]
                          ),
                          _c(
                            "p",
                            {
                              staticClass: "orange",
                              staticStyle: {
                                "margin-top": "12px",
                                "font-size": "12px",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.form.hasContractFor == 0
                                      ? "（已选择全部门店，适用门店是不固定，后续新增的门店将自动适用）"
                                      : "（已选择部分门店，适用门店是固定的，新增的门店需要重新设置）"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm.form.hasContractFor == 1
                        ? _c(
                            "el-button",
                            {
                              staticClass: "btn_insert",
                              attrs: { size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.addStore("contractFor")
                                },
                              },
                            },
                            [_vm._v("选择门店")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "table-box" },
                    [
                      _vm.form.hasContractFor == 1
                        ? _c(
                            "el-table",
                            {
                              attrs: { border: "", data: _vm.form.contractFor },
                            },
                            [
                              _c("el-table-column", {
                                attrs: { label: "操作", width: "80" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ $index }) {
                                        return [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "text",
                                                size: "small",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteRow(
                                                    _vm.form.contractFor,
                                                    $index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "iconfont grow-icon_delete3",
                                                staticStyle: {
                                                  color: "#ff9200",
                                                  "font-size": "22px",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3739962712
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  type: "index",
                                  label: "序号",
                                  width: "60",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  width: "120",
                                  prop: "companyId",
                                  label: "车店编号",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "companyName",
                                  label: "车店名称",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  width: "100",
                                  prop: "type",
                                  label: "连锁类型",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("dict")(
                                                scope.row.chainCompanyType,
                                                "chainCompanyType"
                                              )
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3868694802
                                ),
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "flex item-block" },
                    [
                      _c("h6", [_vm._v("理赔业务:")]),
                      _c(
                        "div",
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 0 },
                              model: {
                                value: _vm.form.hasClaimSettlement,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "hasClaimSettlement", $$v)
                                },
                                expression: "form.hasClaimSettlement",
                              },
                            },
                            [
                              _c("span", { staticStyle: { color: "#333" } }, [
                                _vm._v("无限制，全部门店"),
                              ]),
                            ]
                          ),
                          _c(
                            "el-radio",
                            {
                              staticStyle: { "margin-left": "30px" },
                              attrs: { label: 1 },
                              model: {
                                value: _vm.form.hasClaimSettlement,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "hasClaimSettlement", $$v)
                                },
                                expression: "form.hasClaimSettlement",
                              },
                            },
                            [
                              _c("span", { staticStyle: { color: "#333" } }, [
                                _vm._v("有限制，部分门店"),
                              ]),
                            ]
                          ),
                          _c(
                            "p",
                            {
                              staticClass: "orange",
                              staticStyle: {
                                "margin-top": "12px",
                                "font-size": "12px",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.form.hasClaimSettlement == 0
                                      ? "（已选择全部门店，适用门店是不固定，后续新增的门店将自动适用）"
                                      : "（已选择部分门店，适用门店是固定的，新增的门店需要重新设置）"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm.form.hasClaimSettlement == 1
                        ? _c(
                            "el-button",
                            {
                              staticClass: "btn_insert",
                              attrs: { size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.addStore("claimSettlement")
                                },
                              },
                            },
                            [_vm._v("选择门店")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "table-box" },
                    [
                      _vm.form.hasClaimSettlement == 1
                        ? _c(
                            "el-table",
                            {
                              attrs: {
                                border: "",
                                data: _vm.form.claimSettlement,
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: { label: "操作", width: "80" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ $index }) {
                                        return [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "text",
                                                size: "small",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteRow(
                                                    _vm.form.contractFor,
                                                    $index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "iconfont grow-icon_delete3",
                                                staticStyle: {
                                                  color: "#ff9200",
                                                  "font-size": "22px",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3739962712
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  type: "index",
                                  label: "序号",
                                  width: "60",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  width: "120",
                                  prop: "companyId",
                                  label: "车店编号",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "companyName",
                                  label: "车店名称",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  width: "100",
                                  prop: "type",
                                  label: "连锁类型",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("dict")(
                                                scope.row.chainCompanyType,
                                                "chainCompanyType"
                                              )
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3868694802
                                ),
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "flex item-block" },
                    [
                      _c("h6", [_vm._v("索赔业务:")]),
                      _c(
                        "div",
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 0 },
                              model: {
                                value: _vm.form.hasClaimForCompensation,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "hasClaimForCompensation",
                                    $$v
                                  )
                                },
                                expression: "form.hasClaimForCompensation",
                              },
                            },
                            [
                              _c("span", { staticStyle: { color: "#333" } }, [
                                _vm._v("无限制，全部门店"),
                              ]),
                            ]
                          ),
                          _c(
                            "el-radio",
                            {
                              staticStyle: { "margin-left": "30px" },
                              attrs: { label: 1 },
                              model: {
                                value: _vm.form.hasClaimForCompensation,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "hasClaimForCompensation",
                                    $$v
                                  )
                                },
                                expression: "form.hasClaimForCompensation",
                              },
                            },
                            [
                              _c("span", { staticStyle: { color: "#333" } }, [
                                _vm._v("有限制，部分门店"),
                              ]),
                            ]
                          ),
                          _c(
                            "p",
                            {
                              staticClass: "orange",
                              staticStyle: {
                                "margin-top": "12px",
                                "font-size": "12px",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.form.hasClaimForCompensation == 0
                                      ? "（已选择全部门店，适用门店是不固定，后续新增的门店将自动适用）"
                                      : "（已选择部分门店，适用门店是固定的，新增的门店需要重新设置）"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm.form.hasClaimForCompensation == 1
                        ? _c(
                            "el-button",
                            {
                              staticClass: "btn_insert",
                              attrs: { size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.addStore("claimForCompensation")
                                },
                              },
                            },
                            [_vm._v("选择门店")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "table-box" },
                    [
                      _vm.form.hasClaimForCompensation == 1
                        ? _c(
                            "el-table",
                            {
                              attrs: {
                                border: "",
                                data: _vm.form.claimForCompensation,
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: { label: "操作", width: "80" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ $index }) {
                                        return [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "text",
                                                size: "small",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteRow(
                                                    _vm.form
                                                      .claimForCompensation,
                                                    $index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "iconfont grow-icon_delete3",
                                                staticStyle: {
                                                  color: "#ff9200",
                                                  "font-size": "22px",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  4234626100
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  type: "index",
                                  label: "序号",
                                  width: "60",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  width: "120",
                                  prop: "companyId",
                                  label: "车店编号",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "companyName",
                                  label: "车店名称",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  width: "100",
                                  prop: "type",
                                  label: "连锁类型",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("dict")(
                                                scope.row.chainCompanyType,
                                                "chainCompanyType"
                                              )
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3868694802
                                ),
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.storeDrawerVisibility
        ? _c("select-store", {
            attrs: {
              storeDrawerVisibility: _vm.storeDrawerVisibility,
              stores: _vm.addStoreData,
            },
            on: {
              "update:storeDrawerVisibility": function ($event) {
                _vm.storeDrawerVisibility = $event
              },
              "update:store-drawer-visibility": function ($event) {
                _vm.storeDrawerVisibility = $event
              },
              select: _vm.handleSelectStore,
            },
          })
        : _vm._e(),
      _vm.showAddCusterDialog
        ? _c("add-customer-dialog", {
            attrs: { showAddCusterDialog: _vm.showAddCusterDialog },
            on: {
              "update:showAddCusterDialog": function ($event) {
                _vm.showAddCusterDialog = $event
              },
              "update:show-add-custer-dialog": function ($event) {
                _vm.showAddCusterDialog = $event
              },
              custerInforma: _vm.custerInforma,
            },
          })
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            visible: _vm.undertakeTypeDialog,
            width: "30%",
            "before-close": _vm.handleCloseDialog,
          },
          on: {
            "update:visible": function ($event) {
              _vm.undertakeTypeDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticStyle: {
                "line-height": "22px",
                "letter-spacing": "2px",
                color: "#242526",
              },
            },
            [
              _vm._v(
                "本次包干方式由“" +
                  _vm._s(_vm.oldUndertakeWord) +
                  "”修改为“" +
                  _vm._s(_vm.newUndertakeWord) +
                  "”。当月历史单据将于晚上零点重新跑批更新包干金额。往月单据如需更改，请前往“财务管理->应收款->包干分摊批次”重跑该客户批次"
              ),
            ]
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.cancelTypeDialog } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.undertakeTypeDialog = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm.serviceImportDialogVisibility
        ? _c("import-dialog", {
            attrs: {
              visibility: _vm.serviceImportDialogVisibility,
              title: "协议价服务导入",
              templateFileUrl:
                "https://growxny-dev-center.oss-cn-guangzhou.aliyuncs.com/template/20231019/%E5%8D%8F%E8%AE%AE%E4%BB%B7-%E6%9C%8D%E5%8A%A1%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx",
              uploadFileApi: _vm.serviceAgreementPriceImportApi,
              getLastResultApi: _vm.serviceAgreementPriceImportResultApi,
              importFailDetail:
                _vm.serviceAgreementPriceImportMessageExportUrlApi,
              downloadResultParams: { id: _vm.id },
              disabled: false,
            },
            on: {
              "update:visibility": function ($event) {
                _vm.serviceImportDialogVisibility = $event
              },
              save: _vm.handleImport,
            },
          })
        : _vm._e(),
      _vm.goodsImportDialogVisibility
        ? _c("import-dialog", {
            attrs: {
              visibility: _vm.goodsImportDialogVisibility,
              title: "协议价商品导入",
              templateFileUrl:
                "https://growxny-dev-center.oss-cn-guangzhou.aliyuncs.com/template/20231019/%E5%8D%8F%E8%AE%AE%E4%BB%B7-%E5%95%86%E5%93%81%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx",
              uploadFileApi: _vm.goodsAgreementPriceImportApi,
              getLastResultApi: _vm.goodsAgreementPriceImportResultApi,
              importFailDetail:
                _vm.goodsAgreementPriceImportMessageExportUrlApi,
              downloadResultParams: { id: _vm.id },
              disabled: false,
            },
            on: {
              "update:visibility": function ($event) {
                _vm.goodsImportDialogVisibility = $event
              },
              save: _vm.handleImport,
            },
          })
        : _vm._e(),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          { staticClass: "flex-x-end foot-btn" },
          [
            _c(
              "el-button",
              {
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.verifyBeforeSave },
              },
              [_vm._v("确定保存")]
            ),
            _c(
              "el-button",
              { attrs: { size: "small" }, on: { click: _vm.handleClose } },
              [_vm._v("取 消")]
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }