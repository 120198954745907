<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <div>
        <el-form :model="form" class="filter-form" label-width="100px" size="small">
          <div class="flex-x-between">
            <div class="flex-warp flex-shrink0">
              <el-form-item label="车牌号">
                <el-input
                  v-model="form.carNumber"
                  clearable
                  placeholder="请输入"
                  @clear="carNumberInputClear()"
                  @keyup.enter.native="doSearch"></el-input>
              </el-form-item>
              <el-form-item>
                <template slot="label">
                  <div>
                    操作门店
                    <el-tooltip
                      content="接车单的操作门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)"
                      effect="light"
                      placement="bottom-start">
                      <i class="iconfont grow-icon_doubt" style="color: #9a9a9a"></i>
                    </el-tooltip>
                  </div>
                </template>
                <operateCompanyCascader
                  v-model="form.operateCompanyId"
                  :operateCompanyUrl="form.operateCompanyUrl"></operateCompanyCascader>
              </el-form-item>
              <el-form-item label="接车进店日期">
                <el-date-picker
                  v-model="form.intoStoreTimes"
                  class="w100"
                  end-placeholder="截止时间"
                  range-separator="至"
                  start-placeholder="起始时间"
                  type="daterange"
                  unlink-panels
                  value-format="yyyy-MM-dd"
                  @input="dateRangeChange()"></el-date-picker>
              </el-form-item>
            </div>
            <div class="flex">
              <div class="el-form-item--small">
                <div class="el-form-item__label pointer" @click="showMore = !showMore">
                  更多筛选
                  <i
                    :class="{
                      primary: true,
                      'el-icon-arrow-right': !showMore,
                      'el-icon-arrow-down': showMore,
                    }"></i>
                </div>
                <div class="flex">
                  <el-button class="btn_search" size="small" type="primary" @click="doSearch">查询</el-button>
                  <el-button class="btn_search" size="small" @click="empty">置空</el-button>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-warp">
            <el-form-item label="接车员">
              <el-select
                v-model="form.pickUpUserId"
                :loading="searchAdminJobListUserLoading"
                :remote-method="searchAdminJobListUser"
                clearable
                filterable
                placeholder="搜索姓名、手机号"
                remote
                @change="selectAdminJobListUser">
                <el-option v-for="item in adminJobListUserList" :key="item.userId" :label="item.realName" :value="item.userId">
                  <span style="float: left; color: #8492a6; font-size: 13px">{{ item.realName }}</span>
                  <span style="float: right">{{ item.phone }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="接车单号">
              <el-input v-model="form.billCode" clearable placeholder="请输入" @keyup.enter.native="doSearch"></el-input>
            </el-form-item>
            <el-form-item label="车单结账日期">
              <el-date-picker
                v-model="form.checkStartTimes"
                class="w100"
                end-placeholder="截止时间"
                range-separator="至"
                start-placeholder="起始时间"
                type="daterange"
                unlink-panels
                value-format="yyyy-MM-dd"
                @input="dateRangeChange()"></el-date-picker>
            </el-form-item>
            <el-form-item label="车架号">
              <el-input v-model="form.uniqueId" clearable placeholder="请输入" @keyup.enter.native="doSearch"></el-input>
            </el-form-item>
            <template v-if="showMore">
              <el-form-item label="签约客户">
                <el-select
                  v-model="form.clientId"
                  :loading="searchClientLoading"
                  :remote-method="searchClient"
                  clearable
                  filterable
                  placeholder="请输入姓名"
                  remote
                  @change="selectClient">
                  <el-option v-for="item in customList" :key="item.id" :label="item.clientName" :value="item.id">
                    <span style="float: left; color: #8492a6; font-size: 13px">{{ item.clientName }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="车型信息">
                <el-select
                  v-model="form.carModel"
                  :loading="searchModelLoading"
                  :remote-method="searchModel"
                  clearable
                  filterable
                  placeholder="搜索车型"
                  remote>
                  <!-- <el-option
                      v-for="item in carModelList"
                      :key="$lodash.get(item, 'series.id')"
                      :value="$lodash.get(item, 'series.name')"
                      @click.native="selectCarModel(item)"
                    >
                      <span v-html="item.markedRedName"></span>
                    </el-option> -->
                  <el-option
                    v-for="item in carModelList"
                    :key="$lodash.get(item, 'series.id')"
                    :value="$lodash.get(item, 'series.name', $lodash.get(item, 'brand.name', $lodash.get(item, 'logo.name')))"
                    @click.native="selectCarModel(item)">
                    <span v-html="item.markedRedName"></span>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="客户提车日期">
                <el-date-picker
                  v-model="form.takeStartTimes"
                  class="w100"
                  end-placeholder="截止时间"
                  range-separator="至"
                  start-placeholder="起始时间"
                  type="daterange"
                  unlink-panels
                  value-format="yyyy-MM-dd"></el-date-picker>
              </el-form-item>
              <el-form-item label="车间完工日期">
                <el-date-picker
                  v-model="form.finishStartTimes"
                  class="w100"
                  end-placeholder="截止时间"
                  range-separator="至"
                  start-placeholder="起始时间"
                  type="daterange"
                  unlink-panels
                  value-format="yyyy-MM-dd"></el-date-picker>
              </el-form-item>
              <el-form-item label="业务来源">
                <el-select
                  v-model="form.billSourceTypeId"
                  :loading="searchBillSourceLoading"
                  :remote-method="searchBillSource"
                  clearable
                  filterable
                  placeholder="搜索业务来源"
                  remote
                  @change="selectBillSource"
                  @clear="deleteBillSource">
                  <el-option
                    v-for="item in billSourceList"
                    :key="item.billSourceTypeId"
                    :label="item.name"
                    :value="item.billSourceTypeId"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="施工班组">
                <el-input v-model="form.filed1" clearable placeholder="请输入" @keyup.enter.native="doSearch"></el-input>
              </el-form-item>
              <el-form-item label="服务分类">
                <el-select
                  v-model="form.serviceFirstCategoryId"
                  clearable
                  filterable
                  placeholder="请选择或搜索分类"
                  @change="selectService">
                  <el-option v-for="item in serviceClassList" :key="item.categoryId" :label="item.name" :value="item.categoryId">
                    <span style="float: left; color: #8492a6; font-size: 13px">{{ item.name }}</span>
                    <span style="float: right">{{ item.categoryId }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="服务项目">
                <div v-if="billServices.length == 0" class="choice-sty service" @click="multipleSelectServicesDrawerVisibility = true">
                  <span class="pointer">点击选择</span>
                </div>
                <el-popover v-else placement="bottom" style="text-align: center" trigger="hover" width="200">
                  <div v-for="(item, index) in billServices" :key="index">
                    <div style="color: #666">服务名：{{ item.name }}&nbsp;&nbsp; 服务编码：{{ item.serviceItemCode }}</div>
                  </div>
                  <div slot="reference" class="choice-sty service pointer" style="position: relative">
                    <span @click="multipleSelectServicesDrawerVisibility = true">已选择({{ billServices.length }})</span>
                    <i class="iconfont grow-a-icon_tabbar_close icon-sty" style="color: #ff9200" @click="billServices = []"></i>
                  </div>
                </el-popover>
              </el-form-item>
              <el-form-item label="商品项目">
                <div v-if="billGoods.length == 0" class="choice-sty goods" @click="multipleSelectGoodsDrawerVisibility = true">
                  <span class="pointer">点击选择</span>
                </div>
                <el-popover v-else placement="bottom" style="text-align: center" trigger="hover" width="200">
                  <div v-for="(item, index) in billGoods" :key="index">
                    <div style="color: #666">商品名：{{ item.goodsName }}&nbsp;&nbsp; 商品编码：{{ item.goodsId }}</div>
                  </div>
                  <div slot="reference" class="choice-sty goods" style="position: relative">
                    <span class="pointer" @click="multipleSelectGoodsDrawerVisibility = true">已选择({{ billGoods.length }})</span>
                    <i class="iconfont grow-a-icon_tabbar_close icon-sty" style="color: #55b78e" @click="billGoods = []"></i>
                  </div>
                </el-popover>
              </el-form-item>
              <el-form-item>
                <template slot="label">
                  <div>
                    消费客户
                    <el-tooltip effect="light" placement="bottom-start">
                      <template slot="content">
                        <p>自费/包干客户：输入客户名称</p>
                        <p>保险公司：输入公司名称、编码</p>
                        <p>三包厂家：输入厂家名称、编码</p>
                      </template>
                      <i class="iconfont grow-icon_doubt" style="color: #9a9a9a"></i>
                    </el-tooltip>
                  </div>
                </template>
                <el-input v-model="form.consumerMsg" clearable placeholder="请输入" @keyup.enter.native="doSearch"></el-input>
              </el-form-item>
              <el-form-item label="索赔单号">
                <el-input v-model="form.claimsNum" clearable placeholder="请输入" @keyup.enter.native="doSearch"></el-input>
              </el-form-item>
              <el-form-item label="事故责任">
                <el-select v-model="form.dutyType" clearable filterable placeholder="搜索事故责任">
                  <el-option v-for="item in dutyTypeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <template slot="label">
                  <div class="flex flex-x-center">
                    开票主体
                    <el-tooltip effect="light" placement="bottom-start">
                      <div slot="content" style="color: #666666; font-size: 13px; line-height: 20px; text-indent: 15px">
                        开票主体，即为协议客户、保险公司和三包厂家的应收账款
                        <br />
                        的回款管理门店(保险公司的开票主体也是定损主体)。保险公司
                        <br />
                        管理的开票主体，可查已成为保险公司开票 主体的全部门店。
                      </div>
                      <i class="iconfont grow-icon_doubt" style="color: #9a9a9a"></i>
                    </el-tooltip>
                  </div>
                </template>
                <el-select
                  v-model="form.invoiceName"
                  :loading="searchInvoiceLoading"
                  :remote-method="searchInvoice"
                  clearable
                  filterable
                  placeholder="搜索开票主体名称"
                  remote
                  @change="selectInvoice"
                  @clear="deleteInvoice">
                  <el-option
                    v-for="item in invoiceList"
                    :key="item.companyId"
                    :label="item.invoicingEntity"
                    :value="item.companyId"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="车单标签">
                <el-select v-model="form.billLabel" clearable multiple placeholder="请选择车单标签">
                  <el-option v-for="item in billLabelOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="报案号">
                <el-input v-model="form.caseNum" clearable placeholder="请输入" @keyup.enter.native="doSearch"></el-input>
              </el-form-item>
              <el-form-item label="预交车时间">
                <el-date-picker
                  v-model="form.acceptingTheCartTime"
                  class="w100"
                  end-placeholder="截止时间"
                  range-separator="至"
                  start-placeholder="起始时间"
                  type="datetimerange"
                  unlink-panels
                  value-format="yyyy-MM-dd HH:mm:ss"
                  @input="dateRangeChange()"></el-date-picker>
              </el-form-item>
              <el-form-item label="取消时间">
                <el-date-picker
                  v-model="form.cancelTimes"
                  class="w100"
                  end-placeholder="截止时间"
                  range-separator="至"
                  start-placeholder="起始时间"
                  type="daterange"
                  unlink-panels
                  value-format="yyyy-MM-dd"
                  @input="dateRangeChange()"></el-date-picker>
              </el-form-item>
              <el-form-item label="单据金额">
                <el-select v-model="form.paid" placeholder="">
                  <el-option :value="2" label="全部"></el-option>
                  <el-option :value="1" label="大于0"></el-option>
                  <el-option :value="0" label="等于0"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="检车状态">
                <el-select v-model="form.detectionState" placeholder="请选择检车状态">
                  <el-option label="全部"></el-option>
                  <el-option :value="0" label="否"></el-option>
                  <el-option :value="1" label="是"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="报价状态">
                <el-select v-model="form.quoteState" placeholder="请选择报价状态">
                  <el-option label="全部"></el-option>
                  <el-option :value="0" label="否"></el-option>
                  <el-option :value="1" label="是"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="报价审核状态">
                <el-select v-model="form.quoteApproveState" placeholder="请选择报价审核状态">
                  <el-option label="全部"></el-option>
                  <el-option :value="0" label="否"></el-option>
                  <el-option :value="1" label="是"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="提交施工状态">
                <el-select v-model="form.dispatchSubmitState" placeholder="请选择提交施工状态">
                  <el-option label="全部"></el-option>
                  <el-option :value="0" label="否"></el-option>
                  <el-option :value="1" label="是"></el-option>
                </el-select>
              </el-form-item>
            </template>
          </div>
        </el-form>
      </div>
      <!-- bug764隐藏 -->
      <!-- <el-form-item label="车单标签">
                  <div
                    v-if="singeCarTagesChecked.length == 0"
                    class="choice-sty"
                    @click="openLabelDialog(0)"
                  >
                    <span class="pointer">点击选择</span>
                  </div>
                  <el-popover
                    placement="bottom"
                    width="200"
                    trigger="hover"
                    v-else
                    style="text-align: center"
                  >
                    <div
                      v-for="(item, index) in singeCarTagesChecked"
                      :key="index"
                    >
                      <div style="color: #666">
                        {{ item.name }}&nbsp;&nbsp;/{{ item.billLabelId }}
                      </div>
                    </div>
                    <div
                      slot="reference"
                      class="choice-sty"
                      style="position: relative"
                    >
                      <span class="pointer" @click="openLabelDialog(0)">
                        已选择({{ singeCarTagesChecked.length }})</span
                      >
                      <i
                        class="iconfont grow-a-icon_tabbar_close icon-sty"
                        style="color: #42bbb8"
                        @click="checkedLabels = []"
                      ></i>
                    </div>
                  </el-popover>
                </el-form-item> -->
      <!-- bug764隐藏 -->
      <!-- <el-form-item label="项目标签">
                  <div
                    v-if="checkedLabels.length == 0"
                    class="choice-sty"
                    @click="openLabelDialog(1)"
                  >
                    <span class="pointer">点击选择</span>
                  </div>
                  <el-popover
                    placement="bottom"
                    width="200"
                    trigger="hover"
                    v-else
                    style="text-align: center"
                  >
                    <div v-for="(item, index) in checkedLabels" :key="index">
                      <div style="color: #666">
                        {{ item.name }}&nbsp;&nbsp;/{{ item.billLabelId }}
                      </div>
                    </div>
                    <div
                      slot="reference"
                      class="choice-sty"
                      style="position: relative"
                    >
                      <span class="pointer" @click="openLabelDialog(1)">
                        已选择({{ checkedLabels.length }})</span
                      >
                      <i
                        class="iconfont grow-a-icon_tabbar_close icon-sty"
                        style="color: #42bbb8"
                        @click="checkedLabels = []"
                      ></i>
                    </div>
                  </el-popover>
                </el-form-item> -->
    </template>
    <!-- 以下是表格 -->
    <template>
      <div class="btns flex-x-between">
        <div>
          <div class="custom-tabs nopadding">
            <div
              :class="{
                'custom-tab-item': true,
                active: status == -1,
              }"
              @click="status = -1">
              <p>全部({{ carBillPaidStatistic.carBillNum }})</p>
              <p>￥{{ carBillPaidStatistic.carBillPaidTotal | toThousands }}</p>
            </div>
            <div
              :class="{
                'custom-tab-item': true,
                active: status == 0,
              }"
              @click="status = 0">
              <p>自费({{ carBillPaidStatistic.selfPayBillNum }})</p>
              <p>￥{{ carBillPaidStatistic.selfPayBillPaidTotal | toThousands }}</p>
            </div>
            <div
              :class="{
                'custom-tab-item': true,
                active: status == 1,
              }"
              @click="status = 1">
              <p>包干({{ carBillPaidStatistic.contractForBillNum }})</p>
              <p>￥{{ carBillPaidStatistic.contractForBillPaidTotal | toThousands }}</p>
            </div>
            <div
              :class="{
                'custom-tab-item': true,
                active: status == 2,
              }"
              @click="status = 2">
              <p>理赔({{ carBillPaidStatistic.claimSettlementBillNum }})</p>
              <p>￥{{ carBillPaidStatistic.claimSettlementBillPaidTotal | toThousands }}</p>
            </div>
            <div
              :class="{
                'custom-tab-item': true,
                active: status == 3,
              }"
              @click="status = 3">
              <p>索赔({{ carBillPaidStatistic.claimForCompensationBillNum }})</p>
              <p>￥{{ carBillPaidStatistic.claimForCompensationBillPaidTotal | toThousands }}</p>
            </div>
          </div>
        </div>
        <div>
          <el-button v-auth="494" class="blue" size="small" type="text" @click="showExportDialog = true">
            导出列表
            <i class="iconfont grow-icon_daochu el-icon--right"></i>
          </el-button>
        </div>
      </div>
      <div class="flex h100" style="flex: 1; overflow: auto">
        <div style="flex: 1; overflow: auto">
          <el-table
            v-fixedScrollBar="$refs.baseContainer"
            :data="carBillList"
            :header-cell-style="headerCellStyle"
            border
            class="custom-table"
            @row-dblclick="goDetail($event.billId)">
            <el-table-column label="序号" type="index" width="60"></el-table-column>
            <el-table-column label="操作门店" prop="operateCompanyName" width="100">
              <template #default="{ row }">
                <el-tooltip :content="row.operateCompanyName">
                  <div class="text-ellipsis-2-row">
                    {{ row.operateCompanyName }}
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label="车牌号" prop="carNumber" width="100"></el-table-column>
            <el-table-column label="车单号/业务类型" width="170">
              <template #default="{ row }">
                <div class="blue pointer" @click="goDetail(row.billId)">
                  {{ row.billCode }}
                </div>
                <div v-if="row.billType !== 0">
                  {{ row.billType | dict("carBillType") }}
                </div>
                <div v-else>
                  {{ row.isOthersSelfPayState | dict("isOthersSelfPayState") }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="消费客户" show-overflow-tooltip width="120">
              <template #default="{ row }">
                <div v-if="row.consumer">
                  <div class="text-ellipsis">
                    {{ row.consumer.name }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="业务来源" prop="source" width="100">
              <template #default="{ row }">
                <el-tooltip :content="row.source">
                  <div class="text-ellipsis-2-row">
                    {{ row.source }}
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label="施工类型" prop="difficultyDesc" width="100"></el-table-column>
            <el-table-column label="进店时间" prop="dateCreated" width="100"></el-table-column>
            <el-table-column label="预交车时间" width="100">
              <template #default="{ row }">
                <!-- <div v-if="row.waitInStore">{{ row.planFinishTime }}</div>
                  <div class="color999">
                    {{ row.waitInStore ? '在店等' : '待定' }}
                  </div> -->
                <div v-if="row.undetermined">待定</div>
                <div v-else>
                  <p>{{ row.planFinishTime }}</p>
                  <p>{{ row.waitInStore ? "在店等" : "" }}</p>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="接车员" prop="creator.userName" width="100"></el-table-column>
            <el-table-column label="进店里程" prop="mileage" width="100"></el-table-column>
            <el-table-column label="车型/车架号" show-overflow-tooltip width="180">
              <template #default="{ row }">
                <div v-if="row.carModel" class="text-ellipsis">
                  {{ row.carModel }}
                </div>
                <div v-if="row.uniqueId" class="text-ellipsis">
                  {{ row.uniqueId }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="paid" width="100">
              <template #header>
                <div class="flex">
                  <el-tooltip content="单据金额">
                    <span class="text-ellipsis">单据金额</span>
                  </el-tooltip>
                  <el-tooltip effect="light" placement="bottom-start">
                    <div slot="content" style="color: #666666; font-size: 13px; line-height: 16px; text-indent: 15px">
                      包干业务车单的包干金额,结账当月显示为，查询时
                      <br />
                      点的预分摊金额,结账次月显示为实际分摊金额
                    </div>
                    <i class="iconfont grow-icon_doubt" style="margin-left: 5px"></i>
                  </el-tooltip>
                </div>
              </template>
              <template #default="{ row }">
                <span v-if="row.billType == 1 && row.billState === 1">
                  <div v-if="!row.takeTime" class="color666">
                    <p>{{ row.paid }}</p>
                    <div>
                      包干:
                      <span v-auth="{ code: 473, handle: 'dataMarking' }">{{ row.contractAmount | toThousands }}</span>
                    </div>
                  </div>
                  <div v-else>
                    <span v-auth="{ code: 473, handle: 'dataMarking' }">{{ row.contractAmount | toThousands }}</span>
                  </div>
                </span>
                <div v-else>{{ row.paid | toThousands }}</div>
              </template>
            </el-table-column>
            <el-table-column label="车单状态" width="140">
              <template #default="{ row }">
                <div>
                  <p>
                    {{ row.billState | dict("carBillState") }}
                    <span v-if="row.billOtherState" class="red">({{ row.billOtherState }})</span>
                  </p>
                </div>
                <div class="flex color626" style="font-size: 12px">
                  <!-- 检车状态字段 -->
                  {{ row.detectionState | dict("detectionState") }}

                  <!-- 报价审核状态字段 -->
                  <!-- bug764隐藏 -->
                  <!-- {{ row.quotationReviewState == 0 ? '/待审核' : '/已审核' }}  注释时间2022年8月1日-->
                  <!-- 施工状态字段 -->
                  <div v-if="row.dispatchState !== undefined">
                    <span>/</span>
                    {{ row.dispatchState | dict("dispatchState") }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column v-if="![0, 1].includes(status)" label="报案号/索赔单号" show-overflow-tooltip width="160">
              <template #default="{ row }">
                <div v-if="row.billType === 2" class="text-ellipsis">
                  {{ row.caseNum }}
                </div>
                <div v-if="row.billType === 3" class="text-ellipsis">
                  {{ row.claimsNum }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="双系统单号" prop="doubleSysNum" show-overflow-tooltip width="160"></el-table-column>
            <el-table-column label="事故责任" width="100">
              <template #header>
                <el-tooltip content="事故责任">
                  <span class="text-ellipsis">事故责任</span>
                </el-tooltip>
              </template>
              <template #default="{ row }">
                {{ row.dutyType | dict("dutyType") }}
              </template>
            </el-table-column>
            <el-table-column label="完工时间" prop="taskBillFinishTime" width="100"></el-table-column>

            <el-table-column label="提车时间" prop="takeTime" width="100"></el-table-column>
            <el-table-column label="最后提交结账时间" prop="submitCheckDate" width="100"></el-table-column>
            <el-table-column label="结账时间" prop="checkDate" width="100"></el-table-column>
            <el-table-column label="结账收银员" prop="lastCheckoutPerson" width="100"></el-table-column>
            <el-table-column label="签约客户" show-overflow-tooltip width="120">
              <template #default="{ row }">
                <div v-if="row.owner">
                  <div class="text-ellipsis">{{ row.owner.name }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="开票主体" prop="invoice" width="100">
              <template #default="{ row }">
                <el-tooltip :content="row.invoice">
                  <div class="text-ellipsis-2-row">
                    {{ row.invoice }}
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>

            <el-table-column label="服务大类" show-overflow-tooltip width="100">
              <template #default="{ row }">
                <div v-if="row.serviceCategoryNames" class="text-ellipsis">
                  {{
                    row.serviceCategoryNames
                      .map(i => {
                        return i;
                      })
                      .join()
                  }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="施工班组" prop="technicianNames" width="100">
              <template #default="{ row }">
                <el-tooltip :content="row.technicianNames">
                  <div class="text-ellipsis-2-row">
                    {{ row.technicianNames }}
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label="车主要求" prop="remark" width="100">
              <template #default="{ row }">
                <el-tooltip :content="row.remark">
                  <div class="text-ellipsis-2-row">
                    {{ row.remark }}
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label="初诊与备注" prop="firstVisitRemark" width="100">
              <template #default="{ row }">
                <el-tooltip :content="row.firstVisitRemark">
                  <div class="text-ellipsis-2-row">
                    {{ row.firstVisitRemark }}
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="companyRemark" width="162">
              <template #header>
                <el-tooltip content="车单标签与备注">
                  <span class="text-ellipsis">车单标签与备注</span>
                </el-tooltip>
              </template>
              <template #default="{ row }">
                <div class="tags">
                  <el-tag v-if="row.isClaimUnderWarranty === 1" size="small">在保</el-tag>
                  <el-tag v-if="row.isClaimUnderWarranty === 0" size="small">不在保</el-tag>
                  <el-tag v-if="row.isClaimActivity === 1" size="small">有活动</el-tag>
                  <el-tag v-if="row.isClaimActivity === 0" size="small">无活动</el-tag>
                  <el-tag v-if="row.isReturnCar" size="small">退车整备</el-tag>
                  <el-tag v-if="row.isRepair" size="small">返修</el-tag>
                  <el-tag v-if="row.isSupplementaryOrder" size="small">补单</el-tag>
                  <el-tag v-if="row.isClaimSettlToSelfPayment" size="small">理赔转自费</el-tag>
                  <el-tag v-if="row.isClaimExternalService" size="small">外服</el-tag>
                  <el-tag v-if="row.isClaimSettlFullLoss" size="small">全损</el-tag>
                  <el-tag v-if="row.doubleSysInputState === 1" size="small">双系统待录单</el-tag>
                  <el-tag v-if="row.doubleSysInputState === 2" size="small">双系统已录单</el-tag>
                </div>
                <el-tooltip :content="row.companyRemark">
                  <div class="text-ellipsis-2-row" style="padding-left: 5px">
                    {{ row.companyRemark }}
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="repairTracingRemark" width="200">
              <template #header>
                <el-tooltip content="返修追溯备注">
                  <span class="text-ellipsis">返修追溯备注</span>
                </el-tooltip>
              </template>
              <template #default="{ row }">
                <el-tooltip :content="row.repairTracingRemark">
                  <div class="text-ellipsis-2-row">
                    {{ row.repairTracingRemark }}
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="carStrandedUpdatedTime" width="100">
              <template slot="header">
                <el-tooltip content="滞留更新时间">
                  <span class="text-ellipsis">滞留更新时间</span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label="滞留报告人" prop="reporterName" width="100"></el-table-column>
            <el-table-column label="滞留|中断原因" prop="strandedReason" width="120">
              <template #default="{ row }">
                <el-tooltip :content="row.strandedReason">
                  <div class="text-ellipsis-2-row">
                    {{ row.strandedReason }}
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column width="110">
              <template #header>
                <div class="flex">
                  <el-tooltip content="卡点解决时间">
                    <span class="text-ellipsis">卡点解决时间</span>
                  </el-tooltip>
                  <el-tooltip effect="light" placement="bottom">
                    <div slot="content" style="color: #666666; font-size: 13px; line-height: 16px">当前阶段预计完成时间</div>
                    <i class="iconfont grow-icon_doubt" style="margin-left: 5px"></i>
                  </el-tooltip>
                </div>
              </template>
              <template #default="{ row }">
                {{ row.planCompletionTime }}
              </template>
            </el-table-column>
            <el-table-column label="是否有免赔" prop="exemptionPay2000Str" width="100"></el-table-column>
            <el-table-column label="免赔金额" prop="exemptionPayMoney" width="100"></el-table-column>
            <el-table-column label="免赔支付方" prop="settlementClientName" width="100"></el-table-column>
            <el-table-column label="取消接待时间" prop="cancelDateTime" width="200"></el-table-column>
            <!-- <el-table-column width="60" label="需要协助">
              <template slot="header">
                <el-tooltip content="需要协助">
                  <span class="text-ellipsis">需要协助</span>
                </el-tooltip>
              </template>
              <template #default="{ row }">
                <p v-if="row.needHelp !== undefined">
                  {{ row.needHelp == 0 ? '否' : '是' }}
                </p>
              </template>
            </el-table-column> -->
          </el-table>
        </div>
        <div class="class-box" style="overflow: auto">
          <p class="title">车单状态与统计（车单数）</p>
          <el-tree
            ref="my-tree"
            :current-node-key="nodeKey"
            :data="treeData"
            :default-expanded-keys="defaultKeys"
            :props="treeProp"
            class="custom-tree"
            highlight-current
            node-key="id"
            @click="setCurrentKey"
            @node-click="onNodeClick">
            <template #default="{ data }">
              <div class="tree-node">
                <span>{{ data.name }}</span>
                <span>({{ data.count }})</span>
              </div>
            </template>
          </el-tree>
        </div>
      </div>

      <export-dialog
        :filterForm="form"
        :pageOption="pageOption"
        :total="total"
        :url="exportUrl"
        :visibility.sync="showExportDialog"></export-dialog>
    </template>

    <template slot="footer">
      <pagination :total="total" @pageChange="changePage"></pagination>
    </template>
    <!-- 选择项目标签弹窗、跟车单标签公用-->
    <el-dialog :visible.sync="labelDialogVisible" width="40%">
      <template slot="title">
        <div class="bill-dialog">
          选择项目标签
          <span>（可双击直接单选，或单击勾选再确定选择）</span>
        </div>
      </template>
      <div class="billLabel">
        <div class="artisan-list">
          <div
            v-for="label in labelList"
            :key="label.id"
            :class="{ label: true, active: label.checked }"
            @click="handleArtisanChecked(label)"
            @dblclick="confirmLabel(label)">
            {{ label.name }}
          </div>
        </div>
      </div>
      <div>
        <span>已选择：</span>
        <span v-if="labelType == 1" class="primary">{{ checkedLabels.map(item => item.name).join("、") }}</span>
        <span v-else class="primary">{{ singeCarTagesChecked.map(item => item.name).join("、") }}</span>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="labelDialogVisible = false">取 消</el-button>
        <el-button size="small" style="margin-right: 60px" type="primary" @click="confirmLabel()">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 选择商品标签 -->
    <select-goods-car-bill
      v-if="multipleSelectGoodsDrawerVisibility"
      :oldGoodsData="billGoods || []"
      :visibility.sync="multipleSelectGoodsDrawerVisibility"
      @select="selectBillList"></select-goods-car-bill>
    <!-- 选择服务标签 -->
    <select-services-car-bill
      v-if="multipleSelectServicesDrawerVisibility"
      :oldServicesData="billServices || []"
      :visibility.sync="multipleSelectServicesDrawerVisibility"
      @select="selectServicesList"></select-services-car-bill>
  </base-container>
</template>
<script>
// 分页组件
import pagination from "@/components/Pagination.vue";
import ExportDialog from "@/components/ExportDialog";
//模糊搜索接口
import { searchCarModel } from "@/api/customer/car";
// 业务来源接口
import { getListForBill } from "@/api/billSource";

//接口
import { exportCarBillListUrl, getCarBillList, getCarBillPaidStatistic, getCarBillStatistic } from "@/api/financial/pickSingle";
import { searchInvoice } from "@/api/storeManagement/index";

import { getLabelList as getLabelListApi } from "@/api/label";
import { getServicesClasses } from "@/api/carBill";
import { searchClient } from "@/api/customer/customer";
import { getUserList } from "@/api/employee";

//组件
import SelectGoodsCarBill from "@/views/carBill/selectGoodsCarBill.vue";
import SelectServicesCarBill from "@/views/carBill/selectServicesCarBill.vue";
import operateCompanyCascader from "@/components/businessComponent/operateCompanyCascader";

// 工具
import { mapState } from "vuex";

export default {
  name: "carBillList",
  components: {
    pagination,
    SelectGoodsCarBill,
    SelectServicesCarBill,
    ExportDialog,
    operateCompanyCascader,
  },
  data() {
    return {
      form: {
        paid: 2,
        intoStoreTimes: [],
        consumerMsg: "",
      },
      // 展示更多筛选
      showMore: false,
      //导出的页面设置
      exportUrl: exportCarBillListUrl,
      //分页组件
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },
      showExportDialog: false,
      status: -1,
      // 车单列表数据
      carBillList: [],
      //搜索车型信息列表字段
      searchModelLoading: false,
      carModelList: [],
      //搜索业务来源字段
      searchBillSourceLoading: false,
      billSourceList: [],
      //搜索开票主体门店字段
      searchInvoiceLoading: false,
      invoiceList: [],
      //搜索接车员列表字段
      searchAdminJobListUserLoading: false,
      adminJobListUserList: [],
      //车logo
      carLogoName: "",
      //车品牌
      carBrandName: "",
      //车系
      carSeriesName: "",
      // 项目标签列表
      labelList: [],
      labelPageSize: 10,
      labelPageCurrent: 1,
      labelHasMore: true,
      //项目标签弹窗可见性：
      labelDialogVisible: false,
      //选中项目
      checkedLabels: [],
      //车单选中项目
      singeCarTagesChecked: [],
      labelType: "",
      // 批量添加商品抽屉可见性
      multipleSelectGoodsDrawerVisibility: false,
      billGoods: [],
      //批量添加服务抽屉可见性
      multipleSelectServicesDrawerVisibility: false,
      billServices: [],
      //事故责任字段
      dutyTypeList: [
        {
          value: 0,
          label: "全责",
        },
        {
          value: 1,
          label: "无责",
        },
        {
          value: 2,
          label: "主责",
        },
        {
          value: 3,
          label: "次责",
        },
        {
          value: 4,
          label: "同责",
        },
      ],
      //服务分类
      serviceClassList: [],
      //签约客户
      customList: [],
      searchClientLoading: false,

      treeData: [
        {
          id: "",
          name: "全部",
          count: 0,
        },
        {
          id: 0,
          name: "服务中",
          count: 0,
          children: [
            // {
            //   id: 50,
            //   name: '今日预交',
            //   count: 0,
            // },
            // {
            //   id: 51,
            //   name: '明日预交',
            //   count: 0,
            // },
            // {
            //   id: 7,
            //   name: '其他',
            //   count: 0,
            // },
            // {
            //   id: 9,
            //   name: '未施工',
            //   count: 0,
            // },
            {
              id: 19,
              name: "待派工",
              count: 0,
            },
            {
              id: 20,
              name: "待施工",
              count: 0,
            },
            {
              id: 17,
              name: "施工中",
              count: 0,
            },
            // {
            //   id: 16,
            //   name: '质检中',
            //   count: 0,
            // },
            {
              id: 15,
              name: "已完工",
              count: 0,
            },
            {
              id: 18,
              name: "免施工",
              count: 0,
            },
          ],
        },
        {
          id: 4,
          name: "挂单中",
          count: 0,
          children: [
            {
              id: 6,
              name: "未提车",
              count: 0,
            },
            {
              id: 7,
              name: "已提车",
              count: 0,
            },
          ],
        },
        {
          id: 2,
          name: "待结账",
          count: 0,
          children: [
            {
              id: 8,
              name: "未提车",
              count: 0,
            },
            {
              id: 9,
              name: "已提车",
              count: 0,
            },
          ],
        },
        {
          id: 1,
          name: "已结账",
          count: 0,
          children: [
            {
              id: 10,
              name: "未提车",
              count: 0,
            },
            {
              id: 11,
              name: "已提车",
              count: 0,
            },
          ],
        },
        {
          id: 3,
          name: "已取消",
          count: 0,
        },
      ],
      treeProp: {
        children: "children",
        label: "name",
      },
      billState: 0, //车单状态
      dispatchState: "", // 施工单状态
      nodeKey: 0, //设置默认选中的车单状态
      defaultKeys: [],
      carBillPaid: {},
      carBillPaidStatistic: {},

      // 车单标签选项
      billLabelOptions: [
        { label: "退车整备", value: 1 },
        { label: "返修", value: 2 },
        { label: "补单", value: 3 },
        { label: "在保", value: 4 },
        { label: "不在保", value: 5 },
        { label: "有活动", value: 6 },
        { label: "无活动", value: 7 },
        { label: "理赔转自费", value: 8 },
        { label: "外服", value: 9 },
        { label: "全损", value: 10 },
        { label: "双系统待录单", value: 11 },
        { label: "双系统已录单", value: 12 },
        { label: "（空白）", value: 0 },
      ],
    };
  },
  watch: {
    status() {
      this.getList();
      this.getCarStatistic();
      this.getCarBillPaidStatistic();
    },
    nodeKey(id) {
      //设置选中的车单状态
      this.$refs["my-tree"].setCurrentKey(id);
    },
  },
  computed: {
    ...mapState({
      companyId: state => state.user.userInfo.companyId,
    }),
  },
  activated() {},
  created() {
    const _this = this;
    _this.init();
  },
  mounted() {},
  methods: {
    init() {
      this.searchInvoice();
      this.searchService();
      this.initFromOtherParam();
    },
    initFromOtherParam() {
      const _this = this;
      // 从其他页面跳转过来时附带了查询条件
      if (this.$route.params.forms) {
        this.empty();
        this.form.operateCompanyId = this.$route.params.forms.operateCompanyId;
        this.form.operateCompanyUrl = this.$route.params.forms.operateCompanyUrl;
        this.form.consumerMsg = this.$route.params.forms.consumerMsg;
        this.status = this.$route.params.forms.status || "-1";
        if ([22].includes(this.$route.params.forms.billState)) {
          //操作门店+ 进店日期为今日+车单状态  (开单)
          this.form.intoStoreTimes = this.$route.params.forms.intoStoreTimes;
          this.billState = "";
          this.nodeKey = "";
          this.defaultKeys = [];
          this.form.acceptingTheCartTime = [];
          this.form.checkStartTimes = [];
          this.form.takeStartTimes = [];
          this.dispatchState = "";
        } else if ([0, 2, 4].includes(this.$route.params.forms.billState)) {
          //操作门店+车单状态  (报价 + 提交施工 + 挂单中)
          this.form.intoStoreTimes = [];
          this.form.checkStartTimes = [];
          this.form.acceptingTheCartTime = [];
          this.form.takeStartTimes = [];
          this.billState = this.$route.params.forms.billState;
          this.nodeKey = this.$route.params.forms.nodeKey;
          this.defaultKeys = [this.$route.params.forms.nodeKey];
          this.dispatchState = "";
        } else if ([""].includes(this.$route.params.forms.billState)) {
          //操作门店+车单状态全部 + 今日预交车时间  (今日预交 )
          this.form.intoStoreTimes = [];
          this.form.checkStartTimes = [];
          this.form.acceptingTheCartTime = [];
          this.form.takeStartTimes = [new Date().Format("yyyy-MM-dd 00:00:00"), new Date().Format("yyyy-MM-dd 23:59:59")];
          this.form.takeStartTimes = [];
          this.billState = "";
          this.nodeKey = "";
          this.defaultKeys = [];
          this.dispatchState = "";
        } else if ([5].includes(this.$route.params.forms.billState)) {
          //操作门店+车单状态已结账 +结账起止日期当天（提车）
          this.form.intoStoreTimes = [];
          this.form.acceptingTheCartTime = [];
          this.form.checkStartTimes = [new Date().Format("yyyy-MM-dd 00:00:00"), new Date().Format("yyyy-MM-dd 23:59:59")];
          this.billState = this.$route.params.forms.billState;
          this.nodeKey = this.$route.params.forms.nodeKey;
          this.defaultKeys = [this.$route.params.forms.nodeKey];
          this.dispatchState = "";
        } else if ([1].includes(this.$route.params.forms.billState)) {
          //操作门店 + 结账日期为今日  (今日已结账)
          this.form.checkStartTimes = this.$route.params.forms.checkStartTimes;
          this.billState = 1;
          this.nodeKey = 1;
          this.defaultKeys = [1];
          this.dispatchState = "";
          this.form.intoStoreTimes = [];
          this.form.acceptingTheCartTime = [];
          this.form.takeStartTimes = [];
        } else if ([33].includes(this.$route.params.forms.billState)) {
          //  操作门店+预交车时间+车单状态服务中  (已逾交车)
          // this.form.acceptingTheCartTime = [
          //   new Date(date.getTime() - 864e5 * 1).Format('yyyy-MM-dd  23:59:59'),
          //   new Date(date.getTime() - 864e5 * 1).Format('yyyy-MM-dd  23:59:59'),
          // ];
          this.billState = 0;
          this.nodeKey = 0;
          this.defaultKeys = [0];
          this.dispatchState = "";
          this.form.intoStoreTimes = [];
          this.form.checkStartTimes = [];
          this.form.acceptingTheCartTime = ["2020-01-01 00:00:00", new Date().Format("yyyy-MM-dd 00:00:00")];
          this.form.takeStartTimes = [];
        } else if ([-1].includes(this.$route.params.forms.billState)) {
          this.billState = "";
          this.nodeKey = 9;
          this.defaultKeys = [9];
          this.dispatchState = 7;
          this.form.intoStoreTimes = [];
          this.form.checkStartTimes = [];
          this.form.acceptingTheCartTime = [];
          this.form.takeStartTimes = [];
        } else if ([44].includes(this.$route.params.forms.billState)) {
          this.billState = "";
          this.nodeKey = "";
          this.defaultKeys = [];
          this.dispatchState = "";
          this.form.intoStoreTimes = [];
          this.form.checkStartTimes = [];
          this.form.acceptingTheCartTime = [new Date().Format("yyyy-MM-dd 00:00:00"), new Date().Format("yyyy-MM-dd 23:59:59")];
          this.form.takeStartTimes = [];
        } else if ([45].includes(this.$route.params.forms.billState)) {
          this.form.intoStoreTimes = this.$route.params.forms.intoStoreTimes;
          this.form.carNumber = this.$route.params.forms.carNumber;
          this.form.operateCompanyId = [];
          this.billState = "";
          this.nodeKey = "";
          this.defaultKeys = [];
          this.dispatchState = "";
          this.form.checkStartTimes = [];
          this.form.takeStartTimes = [];
        }
        this.getList();
        this.getCarStatistic();
        this.getCarBillPaidStatistic();
      } else {
        if (["46", 46].includes(_this.$route.query.billState)) {
          // overview 已完工未结账
          this.form.operateCompanyId = [_this.companyId];
          this.billState = "";
          this.nodeKey = 15;
          this.defaultKeys = [0];
          this.dispatchState = 1;
          this.form.checkStartTimes = [];
          this.form.takeStartTimes = [];
          this.getList();
          this.getCarStatistic();
          this.getCarBillPaidStatistic();
        } else if (["47", 47].includes(_this.$route.query.billState)) {
          // overview 已结账未提车
          this.form.operateCompanyId = [_this.companyId];
          this.billState = 10;
          this.nodeKey = 10;
          this.defaultKeys = [1];
          this.dispatchState = "";
          this.form.checkStartTimes = [];
          this.form.takeStartTimes = [];
          this.getList();
          this.getCarStatistic();
          this.getCarBillPaidStatistic();
        } else if (["48", 48].includes(_this.$route.query.billState)) {
          // overview 未检车
          this.form.operateCompanyId = [_this.companyId];
          this.billState = "0";
          this.nodeKey = "0";
          this.defaultKeys = [0];
          this.dispatchState = "";
          this.form.detectionState = 0;
          this.form.checkStartTimes = [];
          this.form.takeStartTimes = [];
          this.getList();
          this.getCarStatistic();
          this.getCarBillPaidStatistic();
        } else if (["49", 49].includes(_this.$route.query.billState)) {
          // overview 未报价
          this.form.operateCompanyId = [_this.companyId];
          this.billState = "0";
          this.nodeKey = 15;
          this.defaultKeys = [];
          this.dispatchState = "";
          this.form.quoteState = 0;
          this.form.checkStartTimes = [];
          this.form.takeStartTimes = [];
          this.getList();
          this.getCarStatistic();
          this.getCarBillPaidStatistic();
        } else if (["50", 50].includes(_this.$route.query.billState)) {
          // overview 未审核
          this.form.operateCompanyId = [_this.companyId];
          this.billState = "0";
          this.nodeKey = 15;
          this.defaultKeys = [];
          this.dispatchState = "";
          this.form.quoteApproveState = 0;
          this.form.checkStartTimes = [];
          this.form.takeStartTimes = [];
          this.getList();
          this.getCarStatistic();
          this.getCarBillPaidStatistic();
        } else if (["51", 51].includes(_this.$route.query.billState)) {
          // overview 未提交施工
          this.form.operateCompanyId = [_this.companyId];
          this.billState = "0";
          this.nodeKey = 15;
          this.defaultKeys = [];
          this.dispatchState = "";
          this.form.dispatchSubmitState = 0;
          this.form.checkStartTimes = [];
          this.form.takeStartTimes = [];
          this.getList();
          this.getCarStatistic();
          this.getCarBillPaidStatistic();
        } else {
          this.form.operateCompanyId = [this.companyId];
          this.getList();
          this.getCarStatistic();
          this.getCarBillPaidStatistic();
        }
      }
    },
    setCurrentKey(key) {},
    //下面是解决设置日期默认值时重新选择无法刷新日期的方法
    dateRangeChange() {
      let _this = this;
      _this.$nextTick(() => {
        _this.$forceUpdate();
      });
    },
    carNumberInputClear() {
      this.form.carNumber = "";
      this.$forceUpdate();
    },
    // 置空输入框数据
    empty() {
      this.form = { paid: 2, intoStoreTimes: [], consumerMsg: "" };
      this.checkedLabels = [];
      this.checkedLabels = [];
      this.billGoods = [];
      this.billServices = [];
    },
    //更改页码
    changePage(e) {
      this.pageOption = e;
      this.getList(false);
    },
    headerCellStyle({ column: { label } }) {
      if (label == "当前滞留情况") {
        return "text-align:center";
      }
    },
    //远程搜索车型信息列表
    searchModel(key = "") {
      this.searchModelLoading = true;
      searchCarModel(key)
        .then(res => {
          this.carModelList = res.records || [];
        })
        .finally(() => {
          this.searchModelLoading = false;
        });
    },
    //远程搜索业务来源列表
    searchBillSource(keyWord = "") {
      this.searchBillSourceLoading = true;
      getListForBill({
        // ...this.pagination,
        name: keyWord,
      })
        .then(res => {
          this.billSourceList = res.records;
        })
        .finally(() => {
          this.searchBillSourceLoading = false;
        });
      // getBillSourceTypeList({ name: keyWord })
      //   .then((res) => {
      //     this.billSourceList = res.records;
      //   })
      //   .finally(() => {
      //     this.searchBillSourceLoading = false;
      //   });
    },
    // 选择业务来源
    selectBillSource(billSourceId) {},
    //删除业务来源
    deleteBillSource() {},
    //远程搜索开票主体门店列表
    searchInvoice(keyWord = "") {
      this.searchInvoiceLoading = true;
      searchInvoice(keyWord, this.companyId)
        .then(res => {
          this.invoiceList = res;
        })
        .finally(() => {
          this.searchInvoiceLoading = false;
        });
    },
    // 选择开票主体门店
    selectInvoice(invoiceId) {},
    //删除开票主体门店
    deleteInvoice() {},
    //远程搜索接车员列表
    adminJobUsrKeyWordChange(keyWord) {
      if (keyWord) {
        this.searchAdminJobListUser(keyWord);
      }
    },
    searchAdminJobListUser(keyWord = "") {
      this.searchAdminJobListUserLoading = true;
      getUserList(keyWord)
        .then(res => {
          this.adminJobListUserList = res.records || [];
        })
        .finally(() => {
          this.searchAdminJobListUserLoading = false;
        });
    },
    //选中接车员
    selectAdminJobListUser() {},
    /**
     * 远程搜索车主客户列表
     */
    clientKeyWordChange(keyword) {
      if (keyword) {
        this.searchClient(keyword);
      }
    },
    searchClient(keyWord = "") {
      this.searchClientLoading = true;
      searchClient(keyWord)
        .then(res => {
          this.customList = res.records;
        })
        .finally(() => {
          this.searchClientLoading = false;
        });
    },
    /**
     * 选择车主客户
     */
    selectClient(clientId) {},

    /**
     * 删除车主客户
     */
    deleteClient() {},
    /**
     * 点击查询
     */
    async doSearch() {
      try {
        if (["", undefined].includes(this.nodeKey)) {
          await this.verifyFilterCondition(1);
        } else if (this.nodeKey == 1) {
          await this.verifyFilterCondition(2);
        }
        this.getCarStatistic();
        this.getList();
        this.getCarBillPaidStatistic();
      } catch (e) {
        console.log(e);
      }
    },
    //获取列表数据
    getList(reset = true) {
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      var times = {
        intoStoreStartTime: this.form.intoStoreTimes?.[0] ? this.form.intoStoreTimes?.[0] : "",
        intoStoreEndTime: this.form.intoStoreTimes?.[1] ? this.form.intoStoreTimes?.[1] : "",
        finishStartTime: this.form.finishStartTimes?.[0] ? this.form.finishStartTimes?.[0] : "",
        finishEndTime: this.form.finishStartTimes?.[1] ? this.form.finishStartTimes?.[1] : "",
        takeStartTime: this.form.takeStartTimes?.[0] ? this.form.takeStartTimes?.[0] : "",
        takeEndTime: this.form.takeStartTimes?.[1] ? this.form.takeStartTimes?.[1] : "",
        checkStartTime: this.form.checkStartTimes?.[0] ? this.form.checkStartTimes?.[0] : "",
        checkEndTime: this.form.checkStartTimes?.[1] ? this.form.checkStartTimes?.[1] : "",
        planFinishStartTime: this.form.acceptingTheCartTime?.[0] ? this.form.acceptingTheCartTime?.[0] : "",
        planFinishEndTime: this.form.acceptingTheCartTime?.[1] ? this.form.acceptingTheCartTime?.[1] : "",
        cancelBeginTime: this.form.cancelTimes?.[0] ? this.form.cancelTimes?.[0] + " 00:00:00" : "",
        cancelEndTime: this.form.cancelTimes?.[1] ? this.form.cancelTimes?.[1] + " 23:59:59" : "",
        billType: this.status == -1 ? "" : this.status,
        // carLogoName: this.carLogoName,
        // carBrandName: this.carBrandName,
        // carSeriesName: this.carSeriesName,
        itemsLabelIds: this.checkedLabels.map(item => item.billLabelId),
        goodsItemsIds: this.billGoods.map(item => item.goodsId),
        serviceItemsIds: this.billServices.map(item => item.serviceItemId),

        billState: this.billState, //车单状态
        dispatchState: this.dispatchState, // 施工单状态
      };
      var data = Object.assign(this.form, this.pageOption, times);
      this.carBillPaid = data;
      getCarBillList(data).then(res => {
        this.total = res.data.total;
        this.carBillList = res.data.records;
      });
    },
    //新增需求-增加查询结果数据的单据金额合计
    getCarBillPaidStatistic() {
      getCarBillPaidStatistic(this.carBillPaid).then(res => {
        this.carBillPaidStatistic = res;
      });
    },
    /**
     * 跳转详情页
     */
    goDetail(id) {
      this.$router.push({
        name: "carBillDetail",
        query: {
          id,
        },
      });
    },
    selectCarModel(val) {
      this.carLogoName = val.logo.name;
      this.carBrandName = val.brand.name;
      this.carSeriesName = val.series.name;
    },
    /**
     * 获取标签列表
     */
    getLabelList() {
      getLabelListApi({
        size: this.labelPageSize,
        current: this.labelPageCurrent,
        labelType: this.labelType, //标签类型(0-车单标签;1-项目标签)
        // isShare: true, //总店用，是否共享到全部分店
        isActive: true, //是否启用
      }).then(res => {
        // this.labelList.push(...res.records);
        this.labelList = res.records;
        this.labelHasMore = this.labelPageCurrent < res.pages;

        //已选有的设置选中状态
        if (this.checkedLabels?.length) {
          var ids = this.checkedLabels.map(item => item.billLabelId);
          var label = this.labelList.filter(item => ids.includes(item.billLabelId));
          label.forEach(item => {
            this.$set(item, "checked", true);
          });
        }
      });
    },
    //标签弹窗选择操作
    handleArtisanChecked(label) {
      this.$set(label, "checked", !label.checked);
      if (label.checked) {
        if (this.labelType == 0) {
          this.singeCarTagesChecked.push(label);
        } else {
          this.checkedLabels.push(label);
        }
        // this.checkedLabels.push(label);
      } else {
        if (this.labelType == 0) {
          const index = this.singeCarTagesChecked.findIndex(item => item.id == label.id);
          if (index !== undefined) {
            this.singeCarTagesChecked.splice(index, 1);
          }
        } else {
          const index = this.checkedLabels.findIndex(item => item.id == label.id);
          if (index !== undefined) {
            this.checkedLabels.splice(index, 1);
          }
        }
        // const index = this.checkedLabels.findIndex(
        //   (item) => item.id == label.id
        // );
        // if (index !== undefined) {
        //   this.checkedLabels.splice(index, 1);
        // }
      }
    },
    confirmLabel(label) {
      if (label) {
        this.$set(label, "checked", true);
        this.checkedLabels.push(label);
      }
      this.labelDialogVisible = false;
    },
    openLabelDialog(value) {
      this.labelType = value;
      this.getLabelList();
      this.labelDialogVisible = true;
    },
    selectBillList(value) {
      this.billGoods = value;
      this.form.goodsItemsIds = this.billServices.map(item => item.serviceItemId);
    },
    selectServicesList(value) {
      this.billServices = value;
      this.form.serviceItemsIds = this.billServices.map(item => item.serviceItemId);
    },
    //选中服务
    selectService(selectedData) {},
    //获取服务分类
    searchService() {
      getServicesClasses(this.companyId, { noShowWaiting: true }).then(res => {
        this.serviceClassList = res || [];
      });
    },
    //获取列表状态与统计
    getCarStatistic() {
      var times = {
        intoStoreStartTime: this.form.intoStoreTimes?.[0] ? this.form.intoStoreTimes?.[0] : "",
        intoStoreEndTime: this.form.intoStoreTimes?.[1] ? this.form.intoStoreTimes?.[1] : "",
        finishStartTime: this.form.finishStartTimes?.[0] ? this.form.finishStartTimes?.[0] : "",
        finishEndTime: this.form.finishStartTimes?.[1] ? this.form.finishStartTimes?.[1] : "",
        takeStartTime: this.form.takeStartTimes?.[0] ? this.form.takeStartTimes?.[0] : "",
        takeEndTime: this.form.takeStartTimes?.[1] ? this.form.takeStartTimes?.[1] : "",
        checkStartTime: this.form.checkStartTimes?.[0] ? this.form.checkStartTimes?.[0] : "",
        checkEndTime: this.form.checkStartTimes?.[1] ? this.form.checkStartTimes?.[1] : "",
        planFinishStartTime: this.form.acceptingTheCartTime?.[0] ? this.form.acceptingTheCartTime?.[0] : "",
        planFinishEndTime: this.form.acceptingTheCartTime?.[1] ? this.form.acceptingTheCartTime?.[1] : "",
        cancelBeginTime: this.form.cancelTimes?.[0] ? this.form.cancelTimes?.[0] + " 00:00:00" : "",
        cancelEndTime: this.form.cancelTimes?.[1] ? this.form.cancelTimes?.[1] + " 23:59:59" : "",
        companyId: this.companyId,
        billType: this.status == -1 ? "" : this.status,
        carLogoName: this.carLogoName,
        carBrandName: this.carBrandName,
        carSeriesName: this.carSeriesName,
        itemsLabelIds: this.checkedLabels.map(item => item.billLabelId),
        goodsItemsIds: this.billGoods.map(item => item.goodsId),
        serviceItemsIds: this.billServices.map(item => item.serviceItemId),
        current: "",
        size: "",
      };
      var data = Object.assign(this.form, times);
      getCarBillStatistic(data).then(res => {
        this.treeData[0].count = res.total;
        this.treeData[1].count = res.serviceSum;
        //服务中下的状态
        // this.treeData[1].children[0].count = res?.noConstructionSum || 0;
        this.treeData[1].children[0].count = res?.awaitConstructionSum || 0;
        this.treeData[1].children[1].count = res?.inConstructionSum || 0;
        this.treeData[1].children[2].count = res?.qualitySum || 0;
        this.treeData[1].children[3].count = res?.shopFinishedSum || 0;
        this.treeData[1].children[4].count = res?.freeConstructionSum || 0;
        // this.treeData[1].children[5].count = res?.freeConstructionSum || 0;

        this.treeData[2].count = res.pendingOrderSum;
        this.treeData[2].children[0].count = res?.pendingOrderNotPickCarSum || 0;
        this.treeData[2].children[1].count = res?.pendingOrderPickCarSum || 0;
        this.treeData[3].count = res.toPaySum;
        this.treeData[3].children[0].count = res?.toPayNotPickCarSum || 0;
        this.treeData[3].children[1].count = res?.toPayPickCarSum || 0;
        this.treeData[4].count = res.finishedSum;
        this.treeData[4].children[0].count = res?.finishedNotPickCarSum || 0;
        this.treeData[4].children[1].count = res?.finishedPickCarSum || 0;
        this.treeData[5].count = res.canceledSum;
      });
    },
    /**
     * 点击节点时
     */
    async onNodeClick(node) {
      this.nodeKey = node.id;
      this.defaultKeys = [node.id];
      // if (node.name == '今日预交') {
      //   this.planFinishTime = new Date().Format('yyyy-MM-dd');
      //   this.billState = '';
      // } else if (node.name == '明日预交') {
      //   var day = new Date();
      //   day.setTime(day.getTime() + 24 * 60 * 60 * 1000);
      //   var tomorrow =
      //     day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate();
      //   this.planFinishTime = tomorrow;
      //   this.billState = '';
      // } else {
      //   this.billState = node.id;
      //   this.planFinishTime = '';
      // }
      if (node.name == "全部") {
        await this.verifyFilterCondition(1);
      } else if (node.name == "已结账") {
        await this.verifyFilterCondition(2);
      }
      if (
        // ['未施工', '待派工', '施工中', '质检中', '车间完工', '免施工'].includes(
        //   node.name
        // )
        ["待派工", "待施工", "施工中", "已完工", "免施工"].includes(node.name)
      ) {
        this.billState = "";
        if (node.name == "待派工") {
          this.dispatchState = 4;
        } else if (node.name == "待施工") {
          this.dispatchState = 0;
        } else if (node.name == "施工中") {
          this.dispatchState = 5;
        } else if (node.name == "已完工") {
          this.dispatchState = 1;
        } else if (node.name == "免施工") {
          this.dispatchState = 6;
        }
        //  else {
        //   this.dispatchState = node.id;
        //   this.billState = '';
        // }
      } else {
        this.billState = node.id;
        this.dispatchState = "";
      }
      this.getCarStatistic();
      this.getList();
      this.getCarBillPaidStatistic();
    },
    /**
     * 校验筛选条件是否可以进行搜索
     * @param type{number} 状态统计是否是已结账 1:非已结账  2: 已结账
     * @return {Promise}
     */
    verifyFilterCondition(type) {
      return new Promise((resolve, reject) => {
        //设置当筛选条件中除了操作门店之前其他所有的条件为空时点击全部、已结账就弹出提示
        let hasTime =
          !this.form.intoStoreTimes?.length != 0 &&
          !this.form.finishStartTimes?.length != 0 &&
          !this.form.takeStartTimes?.length != 0 &&
          !this.form.checkStartTimes?.length != 0 &&
          !this.form.acceptingTheCartTime?.length != 0;
        let res = [
          "carNumber",
          "billCode",
          "consumerMsg",
          "uniqueId",
          "billSourceTypeId",
          "clientId",
          "invoiceName",
          "pickUpUserId",
          "claimsNum",
          "caseNum",
          "dutyType",
          "carModel",
          "serviceFirstCategoryId",
          "filed1",
        ].every(item => {
          return !this.form[item];
        });
        if (hasTime && res && this.billServices.length === 0 && this.billGoods.length === 0) {
          reject();
          if (type == 1) {
            return this.$alert("查询全部状态单据数据量较大，将为你筛选本月进店的单据，如需查询更多请手动选择时间范围", "提示", {
              confirmButtonText: "确定",
              callback: action => {
                this.form.intoStoreTimes = [new Date(new Date().setDate(1)).Format("yyyy-MM-dd"), new Date().Format("yyyy-MM-dd")];
                this.billState = "";
                // this.nodeKey = 0;
                // this.$refs['my-tree'].setCurrentKey(0);
                this.getCarStatistic();
                this.getList();
                this.getCarBillPaidStatistic();
              },
            });
          } else if (type == 2) {
            this.billState = 1;
            return this.$alert("查询已结账状态单据数据量较大，将为你筛选本月进店的单据，如需查询更多请手动选择时间范围", "提示", {
              confirmButtonText: "确定",
              callback: action => {
                this.form.intoStoreTimes = [new Date(new Date().setDate(1)).Format("yyyy-MM-dd"), new Date().Format("yyyy-MM-dd")];
                // this.billState = 0;
                // this.nodeKey = 0;
                // this.$refs['my-tree'].setCurrentKey(0);
                this.getCarStatistic();
                this.getList();
                this.getCarBillPaidStatistic();
              },
            });
          }
        }
        resolve();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
//取消内容部分的内边距30px
// ::v-deep.base-container .main {
//   padding: 0px !important;
// }
//修改选择时间框长度
.el-form {
  width: 100%;

  .el-form-item__content > div {
    width: 100%;
  }
}

.choice-sty {
  width: 80px !important;
  height: 31px;
  background: rgba(66, 187, 184, 0.1);
  border-radius: 4px;
  font-size: 12px;
  color: #42bbb8;
  text-align: center;
}

.goods {
  background: rgba(85, 183, 142, 0.11);
  color: #55b78e;
}

.service {
  background: rgba(255, 146, 0, 0.1);
  color: #ff9200;
}

.custom-tabs {
  display: flex;

  .custom-tab-item {
    padding: 10px 16px;
    border-radius: 4px 4px 0 0;
    border: 1px solid #eee;
    border-bottom: none;
    text-align: center;
    cursor: pointer;

    &.active,
    &:hover {
      color: $primary;
      background: $bg;
    }

    p:nth-child(2) {
      font-size: 14px;
      margin-top: 3px;
    }
  }

  &.nopadding .custom-tab-item {
    margin-right: 10px;
  }
}

.bill-dialog {
  color: #333;
  font-size: 20px;
  span {
    font-size: 14px;
    color: #999;
  }
}

.billLabel {
  height: 440px;
  overflow-y: scroll;
}

.dialog-footer {
  box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.1);
  height: 109px;
  margin-top: 40px;
  line-height: 109px;

  button {
    height: 30px;
  }
}

.artisan-list {
  margin-left: 5px;
  display: flex;
  flex-wrap: wrap;

  .label {
    margin-top: 10px;
    margin-right: 15px;
    padding: 10px 15px;
    border-radius: 4px;
    border: 1px solid #eee;
    cursor: pointer;

    &.active {
      background: $bg;
      color: $primary;
    }
  }
}

.icon-sty {
  position: absolute;
  top: -8px;
  right: 0px;
}

//状态与统计的样式
.class-box {
  width: 180px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  box-sizing: border-box;
  border: 1px solid #e4e4e4;
  box-sizing: border-box;

  .title {
    flex-shrink: 0;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-bottom: 1px solid #e4e4e4;
    background: #fafafa;
    color: #888;
    font-size: 14px;
  }
}

.tree-node {
  color: #888;
  font-size: 14px;
}

.tags {
  .el-tag {
    margin-left: 5px;
    margin-top: 5px;
  }
}
</style>
