import http from "@/service/http";

const defaultListOption = {
  current: 1,
  size: 10,
};

// 车辆档案数据导入
export function carDataImport(file) {
  let formData = new FormData();
  formData.append("file", file);

  return http.request({
    method: "post",
    url: "/admin/file/car/import",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
    timeout: 18e5,
  });
}

// 车辆档案数据导入详情  导出接口
export const carImportFailDetail = "/admin/carImportFailMessage/export";

//车辆信息导入结果
export function carImportCarResult() {
  return http.post("/admin/file/car/importCarResult");
}

//客户指令数据导入
export function clientInstructImport(file) {
  let formData = new FormData();
  formData.append("file", file);

  return http.request({
    method: "post",
    url: "/admin/file/client/clientInstructImport",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
    timeout: 18e5,
  });
}

// 客户指令导入详情  导出接口
export const clientInstructImportDetail = "/admin/clientInstructImportMessage/export";

//客户指令导入结果
export function clientInstructImportResult() {
  return http.post("/admin/file/client/clientInstructImportResult");
}

// 问诊模型数据导入
export function wzModelMiddImport(file) {
  let formData = new FormData();
  formData.append("file", file);

  return http.request({
    method: "post",
    url: "/admin/file/wzModelMidd/import",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
    timeout: 18e5,
  });
}

// 问诊模型数据导入详情  导出接口
export const importFailMessage = "/admin/wzModelMidd/importFailMessage/export";

//问诊模型数据导入结果
export function wzModelMiddImportCarResult() {
  return http.post("/admin/file/wzModelMidd/importResult");
}

// 客户协议 包干车辆范围数据导入
export function carScopeImport(file, clientId, agreementId, opType) {
  let formData = new FormData();
  formData.append("file", file);
  formData.append("clientId", clientId);
  formData.append("agreementId", agreementId);
  formData.append("opType", opType);

  return http.request({
    method: "post",
    url: "/admin/file/contractForCar/import",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
    timeout: 18e5,
  });
}

// 客户协议 包干车辆范围数据导入结果
export function contractForCarImportResult(data) {
  return http.post("/admin/file/contractForCar/importResult", data);
}

// 客户协议 包干车辆范围数据 结果 导出
export const contractForCarImportMessageExportUrl = "/admin/contractForCarImportMessage/export";

// 客户协议 包干车辆清单导出
export const contractForCarListExportUrl = "/admin/car/contract/export";

// 商品数据导入
export function goodsImport(file) {
  let formData = new FormData();
  formData.append("file", file);

  return http.request({
    method: "post",
    url: "/admin/file/goods/import",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
    timeout: 18e5,
  });
}

// 商品数据导入结果
export function goodsImportResult() {
  return http.post("/admin/file/goods/importResult");
}

// 商品导入失败的列表导出
export const goodsImportMessageExportUrl = "/admin/goods/importFailMessage/export";

// 常用报价导入
export function usualQuoteImport(file) {
  let formData = new FormData();
  formData.append("file", file);

  return http.request({
    method: "post",
    url: "/admin/file/usualQuote/import",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
    timeout: 18e5,
  });
}

// 常用报价导入结果
export function usualQuoteImportResult() {
  return http.post("/admin/file/usualQuote/importResult");
}

// 常用报价导入失败的列表导出
export const usualQuoteImportMessageExportUrl = "/admin/usualQuoteImportMessage/export";

// 协议服务价导入
export function serviceAgreementPriceImport(file, id) {
  let formData = new FormData();
  formData.append("file", file);
  formData.append("agreeId", id);

  return http.request({
    method: "post",
    url: "/admin/file/agreement/service/price/import",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
    timeout: 18e5,
  });
}

// 协议服务价导入结果
export function serviceAgreementPriceImportResult(id) {
  return http.post("/admin/file/agreement/service/price/importResult", { id });
}

// 协议服务价导入失败的列表导出
export const serviceAgreementPriceImportMessageExportUrl = "/admin/agreement/service/price/importFailMessage/export";

// 协议商品价导入
export function goodsAgreementPriceImport(file, id) {
  let formData = new FormData();
  formData.append("file", file);
  formData.append("agreeId", id);

  return http.request({
    method: "post",
    url: "/admin/file/agreement/goods/price/import",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
    timeout: 18e5,
  });
}

// 协议商品价导入结果
export function goodsAgreementPriceImportResult(id) {
  return http.post("/admin/file/agreement/goods/price/importResult", { id });
}

// 协议商品价导入失败的列表导出
export const goodsAgreementPriceImportMessageExportUrl = "/admin/agreement/goods/price/importFailMessage/export";

// 库存预警数据导入
export function inventoryWarningImport(file) {
  let formData = new FormData();
  formData.append("file", file);

  return http.request({
    method: "post",
    url: "/admin/file/inventory/warn/import",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
    timeout: 18e5,
  });
}

// 库存预警数据导入结果
export function inventoryWarningImportResult() {
  return http.post("/admin/file/inventory/warn/importResult");
}

//  库存预警失败的列表导出
export const inventoryWarningImportMessageExportUrlApi = "/admin/data/inventory/warningList/importFailMessage/export";

/**
 * 数据导入批次查询条件加载
 */
export function dataImportRecordPreList(data) {
  return http.post("/internal/data/import/preList", data);
}

/**
 * 数据导入批次查询
 */
export function dataImportRecordList(data) {
  return http.post("/internal/data/import/list", data);
}

/**
 * 数据导入批次导出
 */
export const dataImportRecordListExportUrl = "/internal/data/import/list/export";

/**
 * 数据导入批次详细列表查询
 */
export function dataImportRecordBatchDetailList(data) {
  return http.post("/internal/data/import/batchList", data);
}

/**
 * 数据导入批次详细列表导出
 */
export const dataImportRecordBatchDetailExportUrl = "/internal/data/import/batchList/export";

/** 车辆信息过户导入 **/
export function carInfoDataImport(file) {
  let formData = new FormData();
  formData.append("file", file);

  return http.request({
    method: "post",
    url: "/admin/car/transfer/import",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
    timeout: 18e5,
  });
}

/** 车辆信息过户导入结果 **/
export function carInfoImportCarResult() {
  return http.post("/admin/car/transfer/importResult");
}

/** 车辆信息过户导入详情  导出接口 **/
export const carInfoImportFailDetail = "/admin/car/transfer/importResultFailed/export";